import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, IconButton } from '@material-ui/core';
import clsx from 'clsx';
import { LogoutModal } from '../logout-modal';
import { useLogout } from '../../hooks';
import { LogoutIcon } from '../icons/Icons';

const useStyles = makeStyles(() => ({
  button: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    transition: 'all 0.2s',
    fontSize: '12px',
    textTransform: 'uppercase',
    padding: 0,
    letterSpacing: '1.2px',
    cursor: 'pointer',
    background: 'transparent',
    border: 'none',
  },
}));

export const LogoutButton = ({ showOnlyIcon = false, className = '' }) => {
  const classes = useStyles({ showOnlyIcon });
  const [isOpen, setOpening] = useState(false);
  const { loading, removeSession } = useLogout();

  const handleOpen = () => {
    setOpening(true);
  };

  const handleClose = () => {
    setOpening(false);
  };

  const handleLogout = async () => {
    await removeSession();
    setOpening(false);
  };

  const logOutTextButton = (
    <button onClick={handleOpen} className={clsx(classes.button, className)} type="button">
      <span>Log out</span>
    </button>
  );

  const logOutIconButton = (
    <IconButton onClick={handleOpen} className={clsx(classes.button, className)} type="button">
      <LogoutIcon />
    </IconButton>
  );

  return (
    <Box>
      {showOnlyIcon ? logOutIconButton : logOutTextButton}
      <LogoutModal
        loading={loading}
        isOpen={isOpen}
        handleCancel={handleClose}
        handleLogout={handleLogout}
      />
    </Box>
  );
};
