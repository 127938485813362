import React from 'react';
import { Grid, Hidden, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  displayCentsAsDollars,
  getAvailableQuantity,
  getAvailabilityControl,
  getItemsLabel,
  setPlaceholderImageOnError,
} from '../../utils';
import { ProductCartAmount } from '../product-cart-amount';

const useStyles = makeStyles((theme) => ({
  cartRow: {
    borderBottom: '1px solid var(--light-gray)',
    display: 'grid',
    gridTemplateColumns: '80px 45px 1fr 1fr',
    gridTemplateAreas: `
                    'image name name name'
                     'image price quantity totalPrice'
                `,
    [theme.breakpoints.up('sm')]: {
      gridTemplateAreas: 'unset',
      gridTemplateColumns: '80px 1fr 80px 90px 90px',
    },
    gridGap: '20px',
    position: 'relative',
    alignItems: 'center',
    padding: theme.spacing(4, 0),
  },
  cartHeader: {
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 80px 90px 90px',
    },
    gridGap: '0',
    paddingBottom: '7px',
    gridColumnGap: '20px',
    '& > span': {
      textAlign: 'center',
      fontSize: '10px',
      lineHeight: '22px',
      textTransform: 'uppercase',
    },
  },
  image: {
    width: '80px',
    height: '80px',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      gridArea: 'image',
    },
  },
  name: {
    fontSize: '15px',
    lineHeight: '18px',
    color: 'var(--dark-gray)',
    textTransform: 'uppercase',
    margin: 0,
    [theme.breakpoints.down('xs')]: {
      maxWidth: '90%',
    },
  },
  names: {
    [theme.breakpoints.down('xs')]: {
      gridArea: 'name',
    },
  },
  totalPrice: {
    color: theme.palette.primary.main,
    fontSize: '15px',
    fontWeight: 700,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      gridArea: 'totalPrice',
    },
  },
  category: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    margin: 0,
  },
  price: {
    fontSize: '15px',
    fontWeight: 700,
    color: 'var(--dark-gray)',
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      gridArea: 'price',
    },
    [theme.breakpoints.up('md')]: {
      textAlign: 'center',
    },
  },
  quantity: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      gridArea: 'quantity',
    },
  },
  deleteBtn: {
    position: 'absolute',
    top: '10px',
    right: '0',
    padding: 0,
    '& svg': {
      height: '20px',
      width: '20px',
    },
    [theme.breakpoints.up('xs')]: {
      '&:hover': {
        background: 'none',
        opacity: 0.5,
      },
    },
  },
  total: {
    fontWeight: 600,
    fontSize: '13px',
    textAlign: 'right',
    '& > span': {
      display: 'inline-block',
    },
    '& > span:last-child': {
      color: theme.palette.primary.main,
      fontSize: '20px',
      fontWeight: 700,
      minWidth: '90px',
      textAlign: 'center',
      marginLeft: '20px',
      marginTop: '20px',
    },
  },
  headerPrice: {
    textAlign: 'left!important',
    [theme.breakpoints.up('md')]: {
      textAlign: 'center!important',
    },
  },
}));

export const ShoppingCartItems = ({
  cartItems,
  incrementAmount,
  decrementAmount,
  removeFromCart,
  totalAmount,
  totalSum,
}) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={12} md={8}>
      <div className={`${classes.cartRow} ${classes.cartHeader}`}>
        <span />
        <span className={classes.headerPrice}>Price</span>
        <span>Qty:</span>
        <span>Sum</span>
      </div>

      {cartItems?.length ? (
        cartItems.map((item) => (
          <div key={item.uid} className={classes.cartRow}>
            <div className={classes.image}>
              <img src={item.image} alt={item.name} onError={setPlaceholderImageOnError} />
            </div>
            <div className={classes.names}>
              <h3 className={classes.name}>{item.name}</h3>
              <h5 className={classes.category}>{item.category.name}</h5>
            </div>
            <span className={classes.price}>{displayCentsAsDollars(item.price)}</span>
            <div align="center" className={classes.quantity}>
              <ProductCartAmount
                small
                value={item.amount}
                minValue={1}
                increment={() => incrementAmount(item.uid)}
                decrement={() => decrementAmount(item.uid)}
                maxQuantity={getAvailableQuantity(item)}
                availabilityControl={getAvailabilityControl(item)}
              />
            </div>
            <div className={classes.totalPrice}>
              {displayCentsAsDollars(item.amount * item.price)}
            </div>
            {/* remove-from-cart and remove-from-cart-icon class need to Google Analytics, task CEP-2317 and CEP-2393 */}
            <IconButton
              className={clsx(classes.deleteBtn, 'remove-from-cart')}
              onClick={() => removeFromCart(item.uid)}
            >
              <CloseIcon className="remove-from-cart-icon" />
            </IconButton>
          </div>
        ))
      ) : (
        <></>
      )}

      <Hidden smDown>
        <div className={classes.total}>
          <span>{`Subtotal (${totalAmount} ${getItemsLabel(totalAmount)}):`}</span>
          <span>{displayCentsAsDollars(totalSum)}</span>
        </div>
      </Hidden>
    </Grid>
  );
};
