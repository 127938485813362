import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CheckMarkIcon } from '../icons/Icons';
import { OtpForm } from '../otp-form';
import { AuthFormFooter } from '../auth-form-footer';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
  },
  desc: {
    margin: '0 0 40px',
    color: 'var(--dark-gray)',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 'normal',
  },
  info: {
    color: 'var(--dark-gray)',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 600,
  },
  email: {
    padding: '0 4px',
    color: 'var(--dark-gray)',
    fontWeight: 'bold',
    wordBreak: 'break-word',
  },
  iconWrapper: {
    margin: '25px 0',
    color: theme.palette.primary.main,
    '& svg': {
      width: '35px',
      height: '35px',
    },
  },
  button: {
    marginBottom: '15px',
  },
}));

export const SignInResend = ({ email, handleLoginAccount }) => {
  const classes = useStyles();

  return (
    <Grid container justifyContent="center">
      <Grid item align="center" className={classes.container}>
        <div className={classes.iconWrapper}>
          <CheckMarkIcon />
        </div>
        <p className={classes.desc}>
          We&apos;ve just sent a letter with the Passcode to
          <span className={classes.email}>{email}</span>email if it exists in our system. Please
          check your inbox and follow instructions to continue.
        </p>
        <p className={classes.info}>Please enter a 6-digit code from the Email</p>

        <OtpForm email={email} />

        <AuthFormFooter
          description="Didn’t get a link or a code? "
          labelButton="Click to Resend"
          onChangeState={() => handleLoginAccount({ email })}
        />
      </Grid>
    </Grid>
  );
};
