import React from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { displayCentsAsDollars } from '../../utils';

const useStyles = makeStyles((theme) => ({
  container: {},
  label: {
    fontSize: '13px',
    fontWeight: '600',
    color: theme.palette.gray.main,
  },
  value: {
    fontSize: '15px',
    fontWeight: 'bold',
    color: theme.palette.gray.dark,
  },
  totalLabel: {
    fontSize: '15px',
    fontWeight: 'bold',
    color: theme.palette.gray.dark,
    textTransform: 'uppercase',
  },
  totalValue: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  item: {
    padding: theme.spacing(1, 3),
  },
  divider: {
    height: '1px',
    background: theme.palette.gray.light,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

export const OrderSubtotalDetails = ({ shipping, tax, total, subTotal, totalDiscount }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Grid container className={classes.item}>
        <Grid align="left" item xs={9} sm={9} md={9}>
          <Box className={classes.label}>Subtotal:</Box>
        </Grid>
        <Grid align="right" item xs={3} sm={3} md={3}>
          <Box className={classes.value}>{displayCentsAsDollars(subTotal)}</Box>
        </Grid>
      </Grid>

      <Grid container className={classes.item}>
        <Grid align="left" item xs={9} sm={9} md={9}>
          <Box className={classes.label}>Shipping Fee:</Box>
        </Grid>
        <Grid align="right" item xs={3} sm={3} md={3}>
          <Box className={classes.value}>{displayCentsAsDollars(shipping)}</Box>
        </Grid>
      </Grid>

      <Grid container className={classes.item}>
        <Grid align="left" item xs={9} sm={9} md={9}>
          <Box className={classes.label}>Sales Tax:</Box>
        </Grid>
        <Grid align="right" item xs={3} sm={3} md={3}>
          <Box className={classes.value}>{displayCentsAsDollars(tax)}</Box>
        </Grid>
      </Grid>

      <Grid container className={classes.item}>
        <Grid align="left" item xs={9} sm={9} md={9}>
          <Box className={classes.label}>Discount:</Box>
        </Grid>
        <Grid align="right" item xs={3} sm={3} md={3}>
          <Box className={classes.value}>-{displayCentsAsDollars(totalDiscount)}</Box>
        </Grid>
      </Grid>

      <Box className={classes.divider} />

      <Grid container className={classes.item}>
        <Grid align="left" item xs={9} sm={9} md={9}>
          <Box className={classes.totalLabel}>Total:</Box>
        </Grid>
        <Grid align="right" item xs={3} sm={3} md={3}>
          <Box className={classes.totalValue}>{displayCentsAsDollars(total)}</Box>
        </Grid>
      </Grid>
    </Box>
  );
};
