import React from 'react';
import clsx from 'clsx';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';

import { CloseIcon } from '../icons/Icons';

const maxWidthLabelsOptions = {
  general: 'general',
  big: 'big',
};

const maxWidthValuesOptions = {
  [maxWidthLabelsOptions.general]: '450px',
  [maxWidthLabelsOptions.big]: '580px',
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  backdrop: {
    backdropFilter: 'blur(2px)',
    '-webkitBackdropFilter': 'blur(2px)',
  },
  paper: {
    position: 'absolute',
    maxWidth: ({ maxWidth }) => maxWidthValuesOptions[maxWidth],
    width: '100%',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 6),
    outline: 'none',
    background: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      padding: theme.spacing(4, 3),
    },
  },
  closeIcon: {
    position: 'absolute',
    top: '15px',
    right: '15px',
    padding: 0,
    border: 'none',
    background: 'transparent',
    cursor: 'pointer',
  },
}));

export const MainModal = ({
  isOpen,
  onClose,
  children,
  className = '',
  closeIcon = true,
  disablePortal = true,
  maxWidth = maxWidthLabelsOptions.general,
}) => {
  const classes = useStyles({ maxWidth });

  return (
    <Modal
      open={isOpen}
      onClose={() => onClose?.()}
      disablePortal={disablePortal}
      className={clsx(className, classes.wrapper)}
      BackdropProps={{
        className: classes.backdrop,
      }}
    >
      <div className={classes.paper}>
        {closeIcon && (
          <button type="button" className={classes.closeIcon} onClick={() => onClose?.()}>
            <CloseIcon />
          </button>
        )}
        {children}
      </div>
    </Modal>
  );
};
