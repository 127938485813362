import React from 'react';
import { Box, Grid, Paper } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { Button } from '../button';
import { StatusLabel } from '../status-label';
import { OrderSubtotalDetails } from '../order-subtotal-details';
import { convertSnakeToCamel } from '../../utils';

const useStyles = makeStyles((theme) => ({
  paper: {
    textAlign: 'center',
    padding: '35px 40px 32px',
    maxWidth: '330px',
    width: '100%',
    display: 'inline-block',
    boxShadow: '0px 2px 20px #1B1B1D26',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  title: {
    fontSize: '20px',
    fontWeight: 700,
    color: theme.palette.gray.dark,
    marginBottom: theme.spacing(2),
  },
  subTotal: {
    marginTop: theme.spacing(4),
  },
  button: {
    marginTop: theme.spacing(4),
  },
}));

export const OrderCartSubtotal = ({ order, onClick }) => {
  const classes = useStyles();

  return (
    <Grid align="right" item xs={12} sm={12} md={12}>
      <Paper className={classes.paper}>
        <Box className={classes.title}>Order #{order?.number ?? ''}</Box>
        <Box>
          <StatusLabel status={convertSnakeToCamel(order?.status)} />
        </Box>

        <Box className={classes.subTotal}>
          <OrderSubtotalDetails
            total={order?.orderTotals?.total}
            subTotal={order?.orderTotals?.subtotal}
            shipping={order?.orderTotals?.totalShipping}
            tax={order?.orderTotals?.totalTax}
            totalDiscount={order?.orderTotals?.totalDiscount}
          />
        </Box>

        <Button
          className={classes.button}
          variant="contained"
          onClick={() => onClick?.(order?.orderItems)}
        >
          Reorder
        </Button>
      </Paper>
    </Grid>
  );
};
