import { GA_EVENTS, GA_PAGE_TYPES, GA_LOGIN_STATUSES } from '../../constants';

export const getBrandPageForGoogleAnalytics = (window, brandName, isLoggedIn = false) => {
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: GA_EVENTS.page_view,
    page_type: GA_PAGE_TYPES.brand_page,
    page_name: brandName,
    login_status: isLoggedIn ? GA_LOGIN_STATUSES.loggedIn : GA_LOGIN_STATUSES.guest,
  });
};
