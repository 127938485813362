import { useHistory } from 'react-router-dom';
import { gql } from '@apollo/client/core';
import { useMutation } from '@apollo/client';
import { emailService, sessionCacheStorage } from '../services';
import { ROUTING_CONFIG } from '../constants';
import { useErrors } from './useErrors';
import { getLocalStoreFrontSlug } from '../utils';

const CREATE_SESSION_WITH_PASS_CODE = gql`
  mutation createSessionWithOneTimeCode($identifier: String!, $password: String!) {
    createSessionWithOneTimeCode(identifier: $identifier, password: $password) {
      token
    }
  }
`;

export const useCreateSessionWithOneTimeCode = () => {
  const [createSessionMutation, { data, loading }] = useMutation(CREATE_SESSION_WITH_PASS_CODE);
  const { replace } = useHistory();
  const { errorsHandler } = useErrors();
  const storeFrontSlug = getLocalStoreFrontSlug();

  const createSession = async (identifier, password) => {
    try {
      const response = await createSessionMutation({
        variables: { identifier, password },
      });

      const newToken = response?.data?.createSessionWithOneTimeCode?.token;

      if (newToken) {
        sessionCacheStorage.setToken(newToken);
        emailService.remove();

        replace(ROUTING_CONFIG.home.replace(':storeFrontSlug', storeFrontSlug));
        window.location.reload();
        return newToken;
      }
    } catch (error) {
      errorsHandler(error);
      sessionCacheStorage.removeToken();
      replace(ROUTING_CONFIG.home.replace(':storeFrontSlug', storeFrontSlug));
    }
  };

  return {
    createSession,
    isLoading: loading,
    ...data,
  };
};
