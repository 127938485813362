export const debounce = (func, wait = 500) => {
  let timeoutId = null;

  return (...args) => {
    const later = () => {
      clearTimeout(timeoutId);
      func(...args);
    };

    clearTimeout(timeoutId);
    timeoutId = setTimeout(later, wait);
  };
};
