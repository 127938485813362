import { CURRENCY, GA_EVENTS } from '../../constants';
import { displayCentsAsDollars } from '../display-cents-as-dollars';
import { getShoppingCartSubTotal } from '../get-shopping-cart-sub-total';
import { getShoppingCartItemList } from '../get-shopping-cart-item-list';

export const handlePurchaseForGoogleAnalytics = (window, shoppingCart) => {
  const cartItems = getShoppingCartItemList(shoppingCart);
  const subTotalSum = getShoppingCartSubTotal(shoppingCart);

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });

  window.dataLayer.push({
    event: GA_EVENTS.purchase,
    ecommerce: {
      currency: CURRENCY,
      items: cartItems,
      value: displayCentsAsDollars(subTotalSum, { withSign: false }),
    },
  });
};
