export const ACTIONS_TYPES = {
  ADD_TO_CART: 'ADD_TO_CART',
  REMOVE_FROM_CART: 'REMOVE_FROM_CART',
  INCREMENT_AMOUNT: 'INCREMENT_AMOUNT',
  DECREMENT_AMOUNT: 'DECREMENT_AMOUNT',
  SET_LOCAL_CART: 'SET_LOCAL_CART',
  CLEAR_LOCAL_CART: 'CLEAR_LOCAL_CART',
  UPDATE_LOCAL_CART: 'UPDATE_LOCAL_CART',
  UPDATE_ACCOUNT: 'UPDATE_ACCOUNT',
  CLEAR_ACCOUNT: 'CLEAR_ACCOUNT',
  UPDATE_ZIP_CODE: 'UPDATE_ZIP_CODE',
  INITIALIZED: 'INITIALIZED',
};
