import React from 'react';
import { makeStyles } from '@material-ui/core';

import { StatusLabel } from '../status-label';
import { Seller } from '../seller';
import { OrderTotal } from '../order-total';
import { OrderNumber } from '../order-number';
import { OrderDate } from '../order-date';
import { ORDER_STATUSES } from '../../constants';
import { convertSnakeToCamel } from '../../utils';

const handleRowClick = ({ data, history, baseUrl }) => {
  if (data?.status?.toLowerCase?.() !== ORDER_STATUSES.pending) {
    history.push(baseUrl.replace(':orderId', data?.uid));
  }
};

const useStyles = makeStyles((theme) => ({
  textLeft: {
    textAlign: 'left',
  },
  orderNumberHeader: {
    textAlign: 'left',
    paddingLeft: theme.spacing(3),
  },
  orderNumberBody: {
    textAlign: 'left',
    paddingLeft: theme.spacing(3),
  },
  dateHeader: {
    textAlign: 'left',
  },
  dateBody: {
    textAlign: 'left',
  },
  sellerHeader: {
    textAlign: 'left',
  },
  sellerBody: {
    textAlign: 'left',
    maxWidth: 'max(8rem, 13vw)',
  },
  statusHeader: {
    textAlign: 'center',
  },
  statusBody: {
    textAlign: 'center',
  },
  amountHeader: {
    textAlign: 'center',
  },
  amountBody: {
    textAlign: 'center',
    maxWidth: 'max(8rem, 13vw)',
  },
}));

export const useTableConfig = () => {
  const classes = useStyles();

  return {
    onRowClick: handleRowClick,
    columns: [
      {
        headerTitle: 'Order #',
        className: classes.orderNumberBody,
        headerClass: classes.orderNumberHeader,
        render: ({ number }) => <OrderNumber orderNumber={number} />,
      },
      {
        headerTitle: 'Created',
        className: classes.dateBody,
        headerClass: classes.dateHeader,
        render: ({ createdAt }) => <OrderDate date={createdAt} />,
      },
      {
        headerTitle: 'Storefront Owner',
        className: classes.sellerBody,
        headerClass: classes.sellerHeader,
        render: ({ seller }) => <Seller seller={seller} />,
      },
      {
        headerTitle: 'Status',
        className: classes.statusBody,
        headerClass: classes.statusHeader,
        render: ({ status }) => <StatusLabel status={convertSnakeToCamel(status)} />,
      },
      {
        headerTitle: 'Amount',
        className: classes.amountBody,
        headerClass: classes.amountHeader,
        render: (order) => <OrderTotal order={order} />,
      },
    ],
  };
};
