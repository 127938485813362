import React from 'react';
import { Grid } from '@material-ui/core';
import ReactPlayer from 'react-player';
import { makeStyles } from '@material-ui/core/styles';
import { EmptyTab } from '../empty-tab';

const useStyles = makeStyles((theme) => ({
  videoContainer: {
    position: 'relative',
    paddingTop: '56.25%', // 16:9 aspect ratio (9 / 16 * 100)
    width: '100%',
    height: 0,
    cursor: 'pointer',
  },
  playerWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  modal: {
    width: '60vw',
    height: '70vw',
    margin: 'auto',
    maxHeight: '90%',
    border: '1px solid #000',
    borderRadius: '8px',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    padding: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      width: '95vw',
      padding: theme.spacing(0),
    },
  },
}));

export const ProductVideosTab = ({ videoLinks }) => {
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={2}>
        {!videoLinks?.length ? (
          <EmptyTab />
        ) : (
          videoLinks.map((link, index) => (
            <Grid item xs={12} md={6} key={index}>
              <div className={classes.videoContainer}>
                <div className={classes.playerWrapper}>
                  <ReactPlayer
                    className="react-player"
                    controls
                    url={link}
                    width="100%"
                    height="100%"
                  />
                </div>
              </div>
            </Grid>
          ))
        )}
      </Grid>
    </>
  );
};
