import { usePaginatedList } from './usePaginatedList';
import { RESPONSE_TYPES } from '../constants';
import { ITEM_RECOMMENDATIONS } from '../queries/itemRecommendationsQuery';

export const useItemRecommendations = () => {
  const { data, hasMore, loading, error, page, fetchListMore, fetchList } = usePaginatedList(
    ITEM_RECOMMENDATIONS,
    {
      typeData: RESPONSE_TYPES.itemRecommendations,
    },
  );

  return {
    page,
    fetchListMore,
    fetchList,
    itemRecommendations: data?.list,
    loading,
    error,
    hasMore,
  };
};
