import { slugService } from '../services';
import { ROUTING_CONFIG } from '../constants';

export const getStoreFrontSlug = () => {
  const { pathname } = window.location;
  const splittedPath = pathname.split('/').filter((item) => item);

  if (splittedPath?.length && !pathname.includes(ROUTING_CONFIG.notFound)) {
    const [slug] = splittedPath;

    slugService.slug = slug;

    return slug;
  }

  slugService.remove();

  return '';
};
