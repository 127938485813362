import React, { useContext, useEffect } from 'react';
import { Context } from '../store';
import { useDealsProducts } from '../hooks';
import { sessionCacheStorage } from '../services';
import { InfiniteScrollGrid, ProductsItem } from '../components';
import { getDealsPageForGoogleAnalytics, getStateByZipCode } from '../utils';

export const Deals = () => {
  const { state } = useContext(Context);
  const zipCode = state?.zipCode ?? '';
  const stateCode = getStateByZipCode(zipCode);
  const { products, isLoading, isError, fetchListMore, hasMore } = useDealsProducts({
    filter: {
      priceTypeEq: 'discounted',
      ...(zipCode
        ? {
            uidOmitsItemRestrictionsIn: [zipCode, stateCode?.shortName],
            brandUidOmitsBrandRestrictionsIn: [zipCode, stateCode?.shortName],
          }
        : {}),
    },
  });

  useEffect(() => {
    if (!isLoading && !isError) {
      getDealsPageForGoogleAnalytics(window, sessionCacheStorage.hasToken);
    }
  }, [isLoading]);

  return (
    <InfiniteScrollGrid
      itemComponent={ProductsItem}
      title="Deals"
      data={products}
      isLoading={isLoading}
      isError={isError}
      fetchListMore={fetchListMore}
      hasMore={hasMore}
    />
  );
};
