import { useCallback } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

export const formSchema = Yup.object().shape({
  zipCode: Yup.string()
    .required('Required')
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(5, 'Must be exactly 5 digits')
    .max(5, 'Must be exactly 5 digits'),
});

export const useUpdateLocationForm = ({ onSubmit, formRef }) => {
  const handleSubmit = useCallback(async (data) => {
    onSubmit(data);
  }, []);

  const formik = useFormik({
    initialValues: {
      zipCode: '',
    },
    validationSchema: formSchema,
    onSubmit: handleSubmit,
    innerRef: formRef,
  });

  return {
    formik,
  };
};
