import React, { useMemo } from 'react';
import Select, { components } from 'react-select';
import { useTheme } from '@material-ui/styles';
import { CheckIcon, DropdownIcon } from '../icons/Icons';

const getColorStyles = () => ({
  menu: (styles, { theme }) => ({
    ...styles,
    marginTop: 0,
    marginLeft: 0,
    marginRight: 0,
    boxShadow: 'none',
    border: `1px solid ${theme.colors.gray.light}`,
    borderTopColor: 'transparent',
    borderBottomLeftRadius: '18px',
    borderBottomRightRadius: '18px',
    padding: '0px 20px',
    backgroundColor: theme.colors.white.main,
    width: '100%',
    position: 'absolute',
  }),
  menuList: (styles, { theme }) => ({
    ...styles,
    paddingTop: '6px',
    paddingBottom: '10px',
    ':before': {
      content: '""',
      position: 'absolute',
      width: 'calc(100% - 40px)',
      height: '1px',
      left: '50%',
      top: 0,
      padding: '0 20px',
      backgroundColor: theme.colors.gray.light,
      transform: 'translateX(-50%)',
    },
  }),
  control: (styles, { selectProps, theme }) => ({
    ...styles,
    minHeight: 'unset',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    padding: '3px 20px',
    border: `1px solid ${theme.colors.gray.light}`,
    borderRadius: '18px',
    borderBottomLeftRadius: selectProps?.menuIsOpen ? 0 : '18px',
    borderBottomRightRadius: selectProps?.menuIsOpen ? 0 : '18px',
    borderBottomColor: selectProps?.menuIsOpen ? 'transparent' : theme.colors.gray.light,
    ':hover': {
      ...styles[':hover'],
      borderColor: theme.colors.gray.light,
      borderBottomColor: selectProps?.menuIsOpen ? 'transparent' : theme.colors.gray.light,
    },
  }),
  option: (styles, { theme }) => ({
    ...styles,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '3px 0',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 400,
    color: theme.colors.gray.main,
    textAlign: 'left',
    ':hover': {
      ...styles[':hover'],
      backgroundColor: theme.colors.gray.hover,
    },
    'span:last-of-type': {
      display: 'inline-flex',
      alignItems: 'center',
      color: theme.colors.primary.main,
    },
    svg: {
      width: '11px',
      height: '9.54px',
    },
  }),
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({ ...styles }),
  singleValue: (styles, { theme }) => ({
    ...styles,
    color: theme.colors.primary.main,
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: 'bold',
    paddingLeft: 0,
    textTransform: 'capitalize',
  }),
  valueContainer: (styles, { theme }) => ({
    ...styles,
    paddingLeft: 0,
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: '13px',
    fontWeight: 600,
    lineHeight: '24px',
    color: theme.colors.gray.main,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (styles, { selectProps, theme }) => ({
    cursor: 'pointer',
    svg: {
      position: 'absolute',
      color: theme.colors.gray.main,
      top: '50%',
      right: '20px',
      transform: selectProps?.menuIsOpen
        ? 'translateY(-50%) rotate(180deg)'
        : 'translateY(-50%) rotate(0deg)',
      width: '10px',
      height: '5.37px',
    },
  }),
});

const DropdownIndicator = ({ ...rest }) => (
  <components.DropdownIndicator {...rest}>
    <DropdownIcon />
  </components.DropdownIndicator>
);

// options can be grouped - so let's get them from all levels
const getFlatValues = (options = []) =>
  options?.flatMap((option) => {
    if (option.options) return getFlatValues(option.options);

    return option;
  });

const getDefaultValue = (options = [], value) => {
  const allOptions = getFlatValues(options);

  return allOptions.find((option) => option.value === value) ?? allOptions[0];
};

export const getDropdown = ({ renderLabel, customComponents }) => {
  const Option = ({ ...rest }) => (
    <components.Option {...rest}>
      <>
        <span>{renderLabel(rest)}</span>
        <span>{rest.isSelected ? <CheckIcon /> : null}</span>
      </>
    </components.Option>
  );

  const SingleValue = ({ getValue, data, ...rest }) => {
    const option = getValue()[0];

    return renderLabel({ ...rest, ...data, value: option?.value });
  };

  const ChangeDropdown = ({
    value,
    onChange,
    options,
    isSearchable = false,
    hasDefaultValue = false,
    placeholder = 'Select',
    ...rest
  }) => {
    const theme = useTheme();
    const colorStyles = useMemo(() => getColorStyles(), []);

    const defaultValue = getDefaultValue(options, value);

    return (
      <Select
        {...rest}
        {...(hasDefaultValue ? { defaultValue } : {})}
        options={options}
        styles={colorStyles}
        isSearchable={isSearchable}
        menuPosition="absolute"
        menuPlacement="auto"
        onChange={onChange}
        placeholder={placeholder}
        components={{
          DropdownIndicator,
          Option,
          SingleValue,
          ...customComponents,
        }}
        theme={(selectTheme) => ({
          ...selectTheme,
          colors: {
            ...selectTheme.colors,
            ...theme.palette,
          },
        })}
      />
    );
  };

  return ChangeDropdown;
};
