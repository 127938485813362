export const REQUEST_RETURN_ORDER_REASON_KEYS = {
  productNotOrdered: 'product_not_ordered',
  productNotReceived: 'product_not_received',
  incompleteOrder: 'incomplete_order',
  productDamaged: 'product_damaged',
  dissatisfiedWithProduct: 'dissatisfied_with_product',
  changedMyMind: 'changed_my_mind',
};

export const REQUEST_RETURN_ORDER_REASON_LABELS = {
  [REQUEST_RETURN_ORDER_REASON_KEYS.productNotOrdered]: 'Product not Ordered',
  [REQUEST_RETURN_ORDER_REASON_KEYS.productNotReceived]: 'Product not Received',
  [REQUEST_RETURN_ORDER_REASON_KEYS.incompleteOrder]: 'Incomplete Order',
  [REQUEST_RETURN_ORDER_REASON_KEYS.productDamaged]: 'Product Damaged',
  [REQUEST_RETURN_ORDER_REASON_KEYS.dissatisfiedWithProduct]: 'Dissatisfied with Product',
  [REQUEST_RETURN_ORDER_REASON_KEYS.changedMyMind]: 'Changed my Mind',
};

export const REQUEST_RETURN_ORDER_REASONS_OPTIONS = [
  {
    value: REQUEST_RETURN_ORDER_REASON_KEYS.productNotOrdered,
    label: REQUEST_RETURN_ORDER_REASON_LABELS[REQUEST_RETURN_ORDER_REASON_KEYS.productNotOrdered],
  },
  {
    value: REQUEST_RETURN_ORDER_REASON_KEYS.productNotReceived,
    label: REQUEST_RETURN_ORDER_REASON_LABELS[REQUEST_RETURN_ORDER_REASON_KEYS.productNotReceived],
  },
  {
    value: REQUEST_RETURN_ORDER_REASON_KEYS.incompleteOrder,
    label: REQUEST_RETURN_ORDER_REASON_LABELS[REQUEST_RETURN_ORDER_REASON_KEYS.incompleteOrder],
  },
  {
    value: REQUEST_RETURN_ORDER_REASON_KEYS.productDamaged,
    label: REQUEST_RETURN_ORDER_REASON_LABELS[REQUEST_RETURN_ORDER_REASON_KEYS.productDamaged],
  },
  {
    value: REQUEST_RETURN_ORDER_REASON_KEYS.dissatisfiedWithProduct,
    label:
      REQUEST_RETURN_ORDER_REASON_LABELS[REQUEST_RETURN_ORDER_REASON_KEYS.dissatisfiedWithProduct],
  },
  {
    value: REQUEST_RETURN_ORDER_REASON_KEYS.changedMyMind,
    label: REQUEST_RETURN_ORDER_REASON_LABELS[REQUEST_RETURN_ORDER_REASON_KEYS.changedMyMind],
  },
];
