import { useQuery } from '@apollo/client';
import { ITEM_BY_SLUG } from '../queries/productQueries';

export const useProductQuery = (itemSlug) => {
  const {
    data: { items: { nodes = [] } = {} } = {},
    loading,
    error,
  } = useQuery(ITEM_BY_SLUG, {
    variables: { itemSlug },
  });

  if (error) {
    console.error(error);
  }

  return {
    product: nodes.length ? nodes[0] : {},
    isLoading: loading,
    isError: error,
  };
};
