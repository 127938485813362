import { useOrderItems } from './use-order-items';

export const useRequestReturnItems = (items = []) => {
  const { orderItems } = useOrderItems(items);

  return {
    orderItems: orderItems
      .map((orderItem) => ({ amount: 0, ...orderItem }))
      .filter((orderItem) => !!orderItem?.canBeReturned),
  };
};
