import { usePaginatedList } from './usePaginatedList';
import { SEARCH_PRODUCTS_QUERIES } from '../queries';

export const useSearchProducts = ({ query, filter }) => {
  const { data, hasMore, loading, error, page, fetchListMore, fetchList } = usePaginatedList(
    SEARCH_PRODUCTS_QUERIES,
    {
      typeData: 'items',
      defaultSort: 'nameAsc',
      filter,
      isSearch: true,
    },
    {
      variables: {
        query: query ?? '',
      },
    },
  );

  return {
    page,
    fetchListMore,
    fetchList,
    products: data?.list,
    isLoading: loading,
    isError: error,
    hasMore,
  };
};
