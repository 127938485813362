import { gql } from '@apollo/client';
import { FRAGMENTS_QUERIES } from '../constants';

export const ORDERS_DETAILS = gql`
  query listOrders(
    $orderId: String!
  ) {
    orders(filter: { uidEq: $orderId }) {
      nodes {
        uid
        status
        number
        createdAt
        buyer {
          uid
          ${FRAGMENTS_QUERIES.contact}
          ${FRAGMENTS_QUERIES.address}
        }
        seller {
          uid
          ${FRAGMENTS_QUERIES.address}
          ${FRAGMENTS_QUERIES.contact}
        }
        orderItems {
          uid
          qty
          price
          parentUid
          trackingNumber
          carrierName
          shippingStatus
          canBeReturned
          item {
            active
            availability {
              availabilityControl
              availableQty
            }
            brand {
              name
              slug
              uid
              coverImage {
                url
              }
            }
            categories {
              name
              slug
              uid
              coverImage {
                url
              }
            }
            name
            description
            images {
              url
            }
            primaryImage {
              url
              mediumUrl
              thumbUrl
            }
            prices {
              currencyCode
              currencySign
              type
              value
            }
            sku
            slug
            uid
          }
          config {
            returnDateThreshold
          }
          rejectMetadata {
            qty
            reason
          }
        }
        orderTotals {
          subtotal
          total
          totalShipping
          totalTax
          totalDiscount
        }
      }
      ${FRAGMENTS_QUERIES.pagination}
    }
    orderPayments(orderUid: $orderId) {
          paymentDetails {
            uid
            type
            createdAt
            paymentTotals {
              total
              subtotal
              totalTax
              totalShipping
              totalDiscount
              paymentItems {
                item {
                  uid
                  name
                  primaryImage {
                    thumbUrl
                    url
                  }
                }
                qty
                price
              }
            }
          }
          orderTotals {
            total
            subtotal
            totalTax
            totalShipping
            totalDiscount
            paymentItems {
              item {
                uid
                name
                primaryImage {
                  thumbUrl
                  url
                }
              }
              qty
              price
            }
          }
        }
  }
`;
