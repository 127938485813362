import { gql } from '@apollo/client';

export const CREATE_ORDER = gql`
  mutation createOrder(
    $orderedItems: [OrderItemInput!]!
    $buyerUid: String!
    $shippingAddressUid: String!
    $couponCodeUid: String
  ) {
    createOrder(
      orderedItems: $orderedItems
      buyerUid: $buyerUid
      shippingAddressUid: $shippingAddressUid
      couponCodeUid: $couponCodeUid
    ) {
      uid
    }
  }
`;
