export const IMAGE_SIZE_TYPES = {
  origin: 'origin',
  medium: 'medium',
  small: 'small',
};

export const IMAGE_SIZE_VALUES = {
  [IMAGE_SIZE_TYPES.origin]: 'url',
  [IMAGE_SIZE_TYPES.medium]: 'mediumUrl',
  [IMAGE_SIZE_TYPES.small]: 'thumbUrl',
};
