const defaultOptions = {
  inStock: 0,
  outOfStock: 0,
};

export const getStockThresholds = ({ availability = {} }) => {
  const {
    availabilityControl = false,
    availableQty = 0,
    outOfStockThreshold = 0,
    lowInStockThreshold = 0,
  } = availability;

  if (availabilityControl) {
    if (outOfStockThreshold > 0 && availableQty <= outOfStockThreshold) {
      return defaultOptions;
    }

    if (lowInStockThreshold > 0 && availableQty <= lowInStockThreshold) {
      return {
        inStock: availableQty,
        outOfStock: availableQty,
      };
    }

    return {
      inStock: 0,
      outOfStock: availableQty,
    };
  }

  return defaultOptions;
};
