import { gql } from '@apollo/client';

export const ITEM_BY_SLUG = gql`
  query getItemBySlug($itemSlug: String) {
    items(filter: { slugEq: $itemSlug }) {
      nodes {
        brand {
          name
          slug
          uid
        }
        categories {
          name
          slug
          uid
        }
        description
        primaryImage {
          url
          mediumUrl
          thumbUrl
        }
        images {
          url
          mediumUrl
          thumbUrl
        }
        name
        prices {
          currencyCode
          currencySign
          type
          value
        }
        slug
        uid
        sku
        availability {
          availableQty
          availabilityControl
          outOfStockThreshold
          lowInStockThreshold
        }
        videoLinks
        itemOverviews {
          uid
          title
          body
        }
        frequentQuestions {
          uid
          question
          answer
        }
      }
    }
  }
`;
