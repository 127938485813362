import { useMutation } from '@apollo/client';
import { CREATE_SUBSCRIPTION_LINK } from '../mutations/createSchedulerAuthLink';

export const useCreateSubscriptionAuthLink = () => {
  const [createSubscriptionAuthMutation] = useMutation(CREATE_SUBSCRIPTION_LINK);

  const handleCreateSubscriptionAuthLink = async (input) => {
    try {
      const response = await createSubscriptionAuthMutation({
        headers: {
          accept: 'application/json',
        },
        variables: input,
      });

      return response;
    } catch (error) {
      console.error(error.graphQLErrors);
      return null;
    }
  };

  return handleCreateSubscriptionAuthLink;
};
