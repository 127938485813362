import { useQuery } from '@apollo/client';
import { LIST_CATEGORIES } from '../queries';

export const useCategoriesQuery = () => {
  const {
    data: { categories: { nodes = [] } = {} } = {},
    loading,
    error,
  } = useQuery(LIST_CATEGORIES, {
    variables: { filter: { displayOnHomeInMarketplaceEq: true } },
  });

  if (error) {
    console.error(error);
  }

  return {
    categories: nodes,
    isLoading: loading,
    isError: error,
  };
};
