export const BUTTON_COLORS_TYPES = {
  primary: 'primary',
  default: 'default',
  white: 'white',
};

export const BUTTON_SIZES_TYPES = {
  medium: 'medium',
  action: 'action',
};
