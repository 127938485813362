export const ORDER_STATUSES = {
  all: 'all',
  active: 'active',
  closed: 'closed',
  pending: 'pending',
};

export const ORDER_ITEMS_STATUS = {
  cart: 'cart',
  communicated: 'communicated',
  shipped: 'shipped',
  completed: 'completed',
  paid: 'paid',
  shortShipped: 'shortShipped',
  returned: 'returned',
};
