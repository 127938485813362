import { ORDER_ITEMS_STATUS } from '../constants';

const initialValues = {
  [ORDER_ITEMS_STATUS.paid]: [],
  [ORDER_ITEMS_STATUS.communicated]: [],
  [ORDER_ITEMS_STATUS.shipped]: [],
  [ORDER_ITEMS_STATUS.completed]: [],
  [ORDER_ITEMS_STATUS.shortShipped]: [],
  [ORDER_ITEMS_STATUS.returned]: [],
};

export const getOrderItemsByStatus = (items = []) => {
  if (!items?.length) {
    return initialValues;
  }

  return items.reduce((acc, orderItem) => {
    if (ORDER_ITEMS_STATUS[orderItem?.shippingStatus]) {
      return {
        ...acc,
        [ORDER_ITEMS_STATUS[orderItem?.shippingStatus]]: [
          ...acc[orderItem?.shippingStatus],
          {
            ...orderItem,
          },
        ],
      };
    }

    return acc;
  }, initialValues);
};
