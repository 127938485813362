import { groupBy } from './group-by';
import { ORDER_ITEMS_STATUS } from '../constants';

const initialValues = {
  [ORDER_ITEMS_STATUS.paid]: null,
  [ORDER_ITEMS_STATUS.communicated]: null,
  [ORDER_ITEMS_STATUS.shipped]: null,
  [ORDER_ITEMS_STATUS.completed]: null,
  [ORDER_ITEMS_STATUS.shortShipped]: null,
  [ORDER_ITEMS_STATUS.returned]: null,
};

export const getOrderItemsByTrackingNumber = (items = []) => {
  if (!Object.keys(items)?.length) {
    return initialValues;
  }

  return Object.keys(items).reduce((acc, key) => {
    if (items[key]?.length) {
      const groupedOrderItems = groupBy(items[key], 'trackingNumber');

      return {
        ...acc,
        [key]: groupedOrderItems,
      };
    }

    return acc;
  }, initialValues);
};
