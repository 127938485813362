import moment from 'moment';
import { FORMATS_DATES } from '../constants';

export const useMoment = () => {
  const getMomentDate = (date) => {
    if (!date) {
      return '';
    }

    try {
      return moment(date);
    } catch (ex) {
      console.error(`Can't parse date "${date}"`, ex);

      return moment().set('year', 1970);
    }
  };

  const getDate = (date, format = FORMATS_DATES.simpleDate) => getMomentDate(date)?.format(format);

  const getNumberInDays = (startDate, endDate) => {
    if (!startDate || !endDate) {
      return '';
    }

    const startDateMoment = moment(startDate);
    const endDateMoment = moment(endDate);

    return endDateMoment.diff(startDateMoment, 'days');
  };

  return {
    getDate,
    getMomentDate,
    getNumberInDays,
  };
};
