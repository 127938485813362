import React from 'react';
import { FinancesItem } from './order-finances-item';

export const OrderFinances = ({ paymentDetails, orderTotals }) => (
  <>
    {paymentDetails?.map((paymentDetail) => (
      <FinancesItem
        key={paymentDetail.uid}
        uid={paymentDetail.uid}
        type={paymentDetail.type}
        paymentTotals={paymentDetail.paymentTotals}
        createdAt={paymentDetail.createdAt}
      />
    ))}
    {paymentDetails?.length > 1 && <FinancesItem paymentTotals={orderTotals} />}
  </>
);
