import { gql } from '@apollo/client';

export const LIST_ITEMS = gql`
  query listItems($filter: ItemsFilterInput) {
    items(filter: $filter) {
      nodes {
        brand {
          name
          slug
          uid
        }
        categories {
          name
          slug
          uid
        }
        description
        primaryImage {
          url
          mediumUrl
          thumbUrl
        }
        name
        prices {
          currencyCode
          currencySign
          type
          value
        }
        slug
        uid
        sku
        availability {
          availableQty
          availabilityControl
        }
      }
    }
  }
`;

export const AVAILABILITY_ITEMS = gql`
  query getItemsAvailability($filter: ItemsFilterInput) {
    items(filter: $filter) {
      nodes {
        uid
        availability {
          availableQty
          availabilityControl
        }
      }
    }
  }
`;
