import React from 'react';
import { Breadcrumbs, ShoppingCartShipping } from '../components';

export const CheckoutPage = () => (
  <>
    <Breadcrumbs catalogName="checkout" links={[{ link: '/cart', linkName: 'Shopping cart' }]} />

    <h1 className="page-title" style={{ marginBottom: '30px' }}>
      Checkout
    </h1>

    <ShoppingCartShipping />
  </>
);
