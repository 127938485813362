import React from 'react';
import { Fab } from '@material-ui/core';
import clsx from 'clsx';
import { BasketIcon } from '../icons/Icons';

export const AvailabilityStockForProductItem = ({ outOfStock, classes, addToCart }) => {
  if (outOfStock) {
    return null;
  }

  return (
    // add-to-cart and add-to-cart-icon class need to Google Analytics, task CEP-2317 and CEP-2393
    <Fab onClick={addToCart} className={clsx('add-to-cart', classes.cartBtn)}>
      <BasketIcon className="add-to-cart-icon" />
    </Fab>
  );
};
