import { GA_EVENTS, GA_PAGE_TYPES, GA_PAGE_NAMES } from '../../constants';

export const getOrderCanceledPageForGoogleAnalytics = (window) => {
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: GA_EVENTS.page_view,
    page_type: GA_PAGE_TYPES.order_canceled_page,
    page_name: GA_PAGE_NAMES.order_canceled_page,
    login_status: true,
  });
};
