import { LOCAL_STORAGE_KEYS } from '../constants';

class SlugService {
  set slug(value) {
    sessionStorage.setItem(LOCAL_STORAGE_KEYS.storeSlug, value);
  }

  get slug() {
    return sessionStorage.getItem(LOCAL_STORAGE_KEYS.storeSlug);
  }

  remove() {
    sessionStorage.removeItem(LOCAL_STORAGE_KEYS.storeSlug);
  }
}

export const slugService = new SlugService();
