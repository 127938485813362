import React from 'react';
import { makeStyles } from '@material-ui/core';

import { ORDER_STATUSES } from '../../constants';
import { convertCamelToSentence } from '../../utils';

const statusClass = (theme) => ({
  [ORDER_STATUSES.active]: {
    background: theme.palette.blue.light,
    text: theme.palette.blue.main,
  },
  [ORDER_STATUSES.closed]: {
    background: theme.palette.green.light,
    text: theme.palette.green.main,
  },
  [ORDER_STATUSES.pending]: {
    background: theme.palette.gray.light,
    text: theme.palette.gray.dark,
  },
});

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'inline-flex',
    alignItems: 'center',
    padding: theme.spacing(0.3, 4),
    background: ({ status }) => statusClass(theme)[status]?.background,
    borderRadius: '18px',
  },
  label: {
    fontSize: '13px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '21px',
    color: ({ status }) => statusClass(theme)[status]?.text,
  },
}));

export const StatusLabel = ({ status }) => {
  const classes = useStyles({ status });

  return (
    <div className={classes.container}>
      <span className={classes.label}>{convertCamelToSentence(status)}</span>
    </div>
  );
};
