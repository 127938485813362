import { gql } from '@apollo/client';
import { FRAGMENTS_QUERIES } from '../constants';

export const FILTERED_PRODUCTS_QUERIES = gql`
  query filteredProductsQueries($filter: ItemsFilterInput!, $pagination: PaginationInput) {
    items(filter: $filter, pagination: $pagination) {
      nodes {
        brand {
          name
          slug
          uid
        }
        categories {
          name
          slug
          uid
        }
        description
        primaryImage {
          url
          mediumUrl
          thumbUrl
        }
        name
        prices {
          currencyCode
          currencySign
          type
          value
        }
        slug
        uid
        sku
        availability {
          availableQty
          availabilityControl
          outOfStockThreshold
          lowInStockThreshold
        }
      }
      ${FRAGMENTS_QUERIES.pagination}
    }
  }
`;
