import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { getMaxQuantityForSelecting } from '../../utils';

const useStyles = makeStyles(() => ({
  quantity: (props) => ({
    display: 'inline-flex',
    alignItems: 'center',
    '& > span': {
      display: 'block',
      fontSize: props.fontSize,
      textAlign: 'center',
      fontWeight: 700,
      minWidth: props.size,
      padding: '0 5px',
      color: 'var(--dark-gray)',
    },
    '& > button': {
      padding: 0,
      background: 'none',
      color: 'var(--gray)',
      fontWeight: 700,
      border: '1px solid var(--light-gray)',
      width: props.size,
      height: props.size,
      borderRadius: '50%',
      '& svg': {
        width: props.svgSize,
        height: props.svgSize,
      },
      '&:hover, &.Mui-disabled': {
        background: 'none',
        opacity: 0.5,
      },
    },
  }),
}));

export const ProductCartAmount = ({
  value,
  increment,
  decrement,
  minValue,
  small,
  maxQuantity,
  availabilityControl,
}) => {
  const props = {
    size: small ? '24px' : '34px',
    svgSize: small ? '20px' : '26px',
    fontSize: small ? '15px' : '20px',
  };
  const classes = useStyles(props);
  const maxValue = getMaxQuantityForSelecting(maxQuantity, availabilityControl);

  return (
    <div className={classes.quantity}>
      {/* remove-from-cart and remove-from-cart-icon class need to Google Analytics, task CEP-2317 and CEP-2393 */}
      <IconButton
        onClick={decrement}
        disabled={(minValue && value <= minValue) || value === 0}
        className="remove-from-cart"
      >
        <RemoveIcon className="remove-from-cart-icon" />
      </IconButton>
      <span>{value || 0}</span>
      {/* add-to-cart and add-to-cart-icon class need to Google Analytics, task CEP-2317 and CEP-2393 */}
      <IconButton onClick={increment} disabled={value >= maxValue} className="add-to-cart">
        <AddIcon className="add-to-cart-icon" />
      </IconButton>
    </div>
  );
};
