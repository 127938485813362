import { useMutation } from '@apollo/client';
import { useErrors } from './useErrors';
import { CREATE_ORDER } from '../mutations/createOrderMutation';

export const useCreateOrder = () => {
  const [createOrderMutation] = useMutation(CREATE_ORDER);
  const { errorsHandler } = useErrors();

  const createOrder = async ({ buyerUid, orderedItems, shippingAddressUid, couponCodeUid }) => {
    try {
      const response = await createOrderMutation({
        variables: {
          buyerUid,
          orderedItems,
          shippingAddressUid,
          couponCodeUid,
        },
      });

      return response?.data?.createOrder ?? null;
    } catch (error) {
      errorsHandler(error);
      return null;
    }
  };

  return {
    createOrder,
  };
};
