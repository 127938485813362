import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useErrors } from './useErrors';
import { LOGIN_ACCOUNT } from '../mutations/loginAccountMutation';
import { slugService } from '../services';

export const useLoginAccount = () => {
  const [loginAccountMutation] = useMutation(LOGIN_ACCOUNT);
  const { errorsHandler } = useErrors();
  const [loading, setLoading] = useState(false);

  const sendLoginLink = async ({ email }) => {
    try {
      setLoading(true);
      const response = await loginAccountMutation({
        variables: {
          email,
          landingUrl: `${window.location.origin}/${slugService.slug}/session`,
        },
      });

      setLoading(false);

      return response?.data?.sendLoginLinkViaEmail ?? null;
    } catch (error) {
      errorsHandler(error);
      setLoading(false);
      return null;
    }
  };

  return {
    loading,
    sendLoginLink,
  };
};
