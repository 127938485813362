import React from 'react';
import Box from '@material-ui/core/Box';
import { Container } from './container';
import { MobileListItem } from '../mobile-list-item';
import { InfiniteList } from '../infinite-list';

export const MobileList = ({
  data,
  listItem = MobileListItem,
  hasMore,
  fetchListMore,
  loading,
  loader = <h1>Loading...</h1>,
  columns,
  onRowClick,
  ...props
}) => (
  <Box mt={!data?.length ? 4 : 0}>
    <InfiniteList
      {...props}
      data={data}
      listItem={listItem}
      listContainer={Container}
      hasMore={hasMore}
      fetchListMore={fetchListMore}
      loading={loading}
      loader={loader}
      columns={columns}
      onRowClick={onRowClick}
    />
  </Box>
);
