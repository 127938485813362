import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Breadcrumbs as BreadcrumbsMaterial } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    marginBottom: theme.spacing(10),
    fontSize: '12px',
    fontWeight: 600,
    textTransform: 'uppercase',
    color: 'var(--gray)',
    letterSpacing: '1.2px',
    '& li': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    '& li > a:hover': {
      opacity: 0.5,
    },
    '& li > span': {
      opacity: 0.5,
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(5),
    },
  },
}));

export const Breadcrumbs = ({ homePageUrl = '/', links, catalogName = '' }) => {
  const classes = useStyles();
  const { storeFrontSlug } = useParams();
  const url = homePageUrl + storeFrontSlug;

  const getLinks = (allLinks) => {
    if (!allLinks?.length) {
      return null;
    }

    return allLinks.map(({ link, linkName }) => (
      <Link key={`${linkName}`} to={url + link}>
        {linkName}
      </Link>
    ));
  };

  return (
    <BreadcrumbsMaterial className={classes.breadcrumbs} separator="|" aria-label="breadcrumb">
      <Link to={url}>Home</Link>
      {getLinks(links)}
      <span>{catalogName}</span>
    </BreadcrumbsMaterial>
  );
};
