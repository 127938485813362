import { useState } from 'react';
import { useQuery } from '@apollo/client';

const defaultListData = {
  list: [],
  pagination: {},
};

export const usePaginatedQuery = (query, settings = {}, querySettings = {}) => {
  const { typeData, perPage, isSearch = false } = settings ?? {};

  const { error, refetch } = useQuery(query, {
    ...querySettings,
    skip: true,
  });
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(defaultListData);

  const fetchList = async (page, sort, filter) => {
    try {
      setLoading(true);

      const response = await refetch({
        ...(querySettings?.variables ?? {}),
        filter,
        pagination: {
          page,
          perPage,
        },
        sort,
      });

      const responseData = isSearch ? response?.data?.search[typeData] : response?.data[typeData];

      if (responseData?.nodes) {
        setData({
          list: responseData?.nodes,
          pagination: responseData?.pagination,
        });
      } else {
        setData(defaultListData);
      }

      setLoading(false);
    } catch (error) {
      console.error(`Fetch ${typeData} list failed:`, error);
      setLoading(false);
    }
  };

  const fetchListMore = async (page, sort, filter) => {
    try {
      const response = await refetch({
        ...(querySettings?.variables ?? {}),
        filter,
        pagination: {
          page,
          perPage,
        },
        sort,
      });

      const responseData = isSearch ? response?.data?.search[typeData] : response?.data[typeData];

      if (responseData?.nodes) {
        setData((prevState) => ({
          list: [...prevState.list, ...responseData?.nodes],
          pagination: {
            ...prevState.pagination,
            ...responseData?.pagination,
          },
        }));
      }
    } catch (error) {
      console.error(`Fetch ${typeData} list more failed: `, error);
    }
  };

  return {
    error,
    data,
    loading,
    fetchListMore,
    fetchList,
  };
};
