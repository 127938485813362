import React, { useContext, useCallback } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Card, CardMedia, CardContent } from '@material-ui/core';
import { Context } from '../../store';
import { ACTIONS_TYPES } from '../../constants';
import {
  getAvailableQuantity,
  getAvailabilityControl,
  getProductPrices,
  getCategoryName,
  getProductImage,
  getLocalStoreFrontSlug,
  getStockThresholds,
} from '../../utils';
import { AvailabilityStockForProductItem } from '../availability-stock-for-product-item';
import { ProductPrices } from '../product-prices';
import { ProductCartAmount } from '../product-cart-amount';
import { LabelOfStock } from '../label-of-stock';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: '0px 2px 10px #1B1B1D26',
    borderRadius: '5px',
    transition: 'transform .15s',
    [theme.breakpoints.up('md')]: {
      '&:hover': {
        transform: 'scale(1.02)',
      },
      '&:hover > *': {
        transform: 'scale(1.025)',
      },
      '& > *': {
        transition: 'transform .15s',
      },
    },
  },
  mediaBox: {
    position: 'relative',
    padding: '10px 10px 0 10px',
  },
  media: {
    height: '121px',
    backgroundSize: 'contain',
    [theme.breakpoints.up('sm')]: {
      height: '250px',
    },
  },
  label: {
    position: 'absolute',
    top: '12px',
    left: '12px',
  },
  info: {
    padding: '10px 12px !important',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      padding: '10px 12px 18px !important',
    },
  },
  title: {
    fontSize: '13px',
    lineHeight: '18px',
    color: 'var(--dark-gray)',
    textTransform: 'uppercase',
    fontWeight: 700,
    margin: '0px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '15px',
    },
  },
  category: {
    maxWidth: '70% !important',
    fontSize: '12px',
    lineHeight: '21px',
    height: '21px',
    fontWeight: 400,
    color: 'var(--gray)',
    textTransform: 'capitalize',
    margin: '0px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '14px',
      maxWidth: '75% !important',
    },
  },
  price: {
    marginTop: '15px',
  },
  cartActions: {
    position: 'absolute',
    bottom: '15px',
    right: '12px',
    lineHeight: 0,
    [theme.breakpoints.up('md')]: {
      right: '20px',
    },
  },
  qtyWrapper: {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '-4px',
    [theme.breakpoints.up('md')]: {
      marginBottom: '4px',
    },
  },
  qtyLabel: {
    fontSize: '8px',
    fontWeight: 400,
    paddingTop: '2px',
    color: 'var(--gray)',
    [theme.breakpoints.up('md')]: {
      paddingTop: '5px',
    },
  },
  truncate: {
    maxWidth: '266px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  cartBtn: {
    background: theme.palette.primary.main,
    color: 'var(--white)',
    boxShadow: '0px 0px 25px #1B1B1D3D',
    height: '40px',
    width: '40px',
    marginBottom: '-5px',
    '& svg': {
      height: '23px',
      width: '23px',
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: '0',
      height: '54px',
      width: '54px',
      '& svg': {
        height: '30px',
        width: '30px',
      },
    },
    '&:hover': {
      background: 'var(--white)',
      color: theme.palette.primary.main,
    },
  },
  availability: {
    lineHeight: '13px',
    [theme.breakpoints.up('sm')]: {
      lineHeight: '21px',
    },
  },
}));

export const ProductsItem = ({ data, className, ...rest }) => {
  const classes = useStyles();
  const { state, dispatch } = useContext(Context);
  const storeFrontSlug = getLocalStoreFrontSlug();

  const cartIndex = state.cart.findIndex((item) => item.uid === data.uid);

  const addToCart = () => {
    dispatch({ type: ACTIONS_TYPES.ADD_TO_CART, payload: data });
  };

  const incrementAmount = useCallback(() => {
    dispatch({ type: ACTIONS_TYPES.INCREMENT_AMOUNT, payload: data.uid });
  }, [dispatch, data]);

  const decrementAmount = useCallback(() => {
    dispatch({ type: ACTIONS_TYPES.DECREMENT_AMOUNT, payload: data.uid });
  }, [dispatch, data.uid]);

  const productPrices = getProductPrices(data);
  const categoryName = getCategoryName(data);
  const productImage = getProductImage(data);
  const maxQuantity = getAvailableQuantity(data);
  const availabilityControl = getAvailabilityControl(data);
  const { inStock, outOfStock } = getStockThresholds(data);
  const isOutOfStock = availabilityControl && outOfStock <= 0;
  const isInStock = availabilityControl && inStock > 0;

  return (
    <Card
      className={clsx(classes.root, 'product-icon', { [className]: Boolean(className) })} // product-icon class need to Google Analytics, task CEP-2317
      {...rest}
    >
      <Link to={`/${storeFrontSlug}/products/${data.slug}`}>
        <div className={classes.mediaBox}>
          {isOutOfStock && <LabelOfStock className={classes.label} />}
          {isInStock && <LabelOfStock inStock={inStock} className={classes.label} />}
          <CardMedia className={classes.media} image={productImage} title={data.name} />
        </div>
      </Link>
      <CardContent className={classes.info}>
        <Link to={`/${storeFrontSlug}/products/${data.slug}`} tabIndex={-1}>
          <h3 className={`${classes.title} ${classes.truncate}`} tabIndex={-1}>
            {data.name}
          </h3>
        </Link>
        <h5 className={`${classes.category} ${classes.truncate}`} tabIndex={-1}>
          {categoryName}
        </h5>
        <div className={classes.price}>
          <ProductPrices small regular={productPrices.regular} discount={productPrices.discount} />
        </div>
        <div className={classes.cartActions}>
          {state.cart[cartIndex] ? (
            <div className={classes.qtyWrapper}>
              <ProductCartAmount
                small
                value={state.cart[cartIndex]?.amount}
                increment={incrementAmount}
                decrement={decrementAmount}
                maxQuantity={maxQuantity}
                availabilityControl={availabilityControl}
              />
              <div className={classes.qtyLabel}>IN CART</div>
            </div>
          ) : (
            <AvailabilityStockForProductItem
              classes={classes}
              addToCart={addToCart}
              outOfStock={isOutOfStock}
            />
          )}
        </div>
      </CardContent>
    </Card>
  );
};
