import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { MainModal } from '../modal';
import { AttentionIcon } from '../icons/Icons';
import { Button } from '../button';
import { Spinner } from '../spinner';

const useStyles = makeStyles((theme) => ({
  container: {
    justifyContent: 'center',
  },
  formTitle: {
    margin: '0 0 14px',
    color: 'var(--dark-gray)',
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: 'bold',
  },
  formSubTitle: {
    margin: '14px 0 30px',
    color: 'var(--gray)',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 'normal',
  },
  info: {
    margin: '0 0 20px',
    color: 'var(--dark-gray)',
    fontSize: '12px',
    lineHeight: '21px',
    fontWeight: 600,
  },
  email: {
    padding: '0 4px',
    color: 'var(--dark-gray)',
    fontWeight: 'bold',
  },
  iconWrapper: {
    margin: '25px 0',
    color: theme.palette.primary.main,
    '& svg': {
      width: '35px',
      height: '35px',
    },
  },
  button: {
    marginBottom: '15px',
  },
}));

export const LogoutModal = ({ isOpen, handleCancel, handleLogout, loading }) => {
  const classes = useStyles();

  return (
    <MainModal isOpen={isOpen} onClose={handleCancel} disablePortal={false}>
      {loading && <Spinner />}

      <Grid container className={classes.container}>
        <Grid item align="center">
          <div className={classes.iconWrapper}>
            <AttentionIcon />
          </div>
          <p className={classes.formTitle}>Log out</p>

          <p className={classes.formSubTitle}>Are you sure you want to log out?</p>

          <Button className={classes.button} variant="contained" onClick={handleLogout}>
            Log out
          </Button>

          <Button
            color="default"
            className={classes.button}
            variant="contained"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </MainModal>
  );
};
