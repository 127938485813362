import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { displayCentsAsDollars, getItemsLabel } from '../../utils';
import { Spinner } from '../spinner';
import { Button } from '../button';

const useStyles = makeStyles((theme) => ({
  checkout: {
    textAlign: 'center',
    padding: '35px 40px 50px',
    color: 'var(--dark-gray)',
    fontSize: '20px',
    fontWeight: 700,
    maxWidth: 'calc(100% - 80px)',
    width: '100%',
    display: 'inline-block',
    boxShadow: '0px 2px 20px #1B1B1D26',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '250px',
      padding: '35px 20px 45px',
      textAlign: 'center',
    },
    [theme.breakpoints.up('md')]: {
      padding: '35px 40px 50px',
    },
    '& > div:nth-child(2)': {
      fontSize: '13px',
      fontWeight: 600,
      color: 'var(--gray)',
    },
    '& > div:nth-child(3)': {
      fontSize: '44px',
      lineHeight: '48px',
      margin: '25px 0 30px 0',
    },
  },
  spinner: {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    '& > div.MuiCircularProgress-root': {
      width: '22px !important',
      height: '22px !important',
    },
  },
}));

export const ShoppingCartSubtotal = ({
  totalAmount,
  totalSum,
  isPrivacyPolicyAccepted,
  areErrorsPresent,
  isLoading,
  onClick,
  buttonText,
  idButton,
}) => {
  const classes = useStyles();

  const privacyAccepted = isPrivacyPolicyAccepted ?? true;

  return (
    <Grid align="right" item xs={12} sm={12} md={4} lg={4}>
      <Paper className={classes.checkout}>
        <div>Subtotal</div>
        <div>{`${totalAmount} ${getItemsLabel(totalAmount)}`}</div>
        <div>{displayCentsAsDollars(totalSum)}</div>
        <Box position="relative">
          {totalAmount > 0 && (
            <Button
              id={idButton}
              variant="contained"
              disabled={!totalAmount || !privacyAccepted || areErrorsPresent}
              onClick={() => !isLoading && onClick()}
            >
              {buttonText}
            </Button>
          )}
          {isLoading && <Spinner className={classes.spinner} />}
        </Box>
      </Paper>
    </Grid>
  );
};
