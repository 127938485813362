import React from 'react';
import { Link } from '@material-ui/core';

const consoleLinks = {
  dev: 'https://console.dev.salonhq.co',
  uat: 'https://console.uat.salonhq.co',
  prod: 'https://console.salonhq.co',
};

const apiLinksEnvs = {
  'https://client.dev.salonhq.co/api/b2c': 'dev',
  'https://client.uat.salonhq.co/api/b2c': 'uat',
  'https://client.salonhq.co/api/b2c': 'prod',
};

const getConsoleLink = () => {
  const apiLink = process.env.REACT_APP_BASE_API;
  if (!apiLink) return null;

  const directMatchEnv = apiLinksEnvs[apiLink];

  const directMatchConsoleLink = consoleLinks[directMatchEnv];
  if (directMatchConsoleLink) return directMatchConsoleLink;

  if (apiLink.includes('dev')) return consoleLinks.dev;
  if (apiLink.includes('uat')) return consoleLinks.uat;

  return consoleLinks.prod;
};

export const ConsoleLink = ({ className, description = 'Manage Your Storefront' }) => {
  const consoleUrl = getConsoleLink();
  if (!consoleUrl) return null;

  return (
    <Link target="__blank" href={consoleUrl} underline="always" className={className}>
      {description}
    </Link>
  );
};
