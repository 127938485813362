import { getAvailableQuantity, getAvailabilityControl, getMaxQuantityForSelecting } from '../utils';

export const useComparingItemsByAvailability = () => {
  const comparingItemsByAvailability = async (newCart, oldCart) => {
    let isError = false;

    if (newCart?.length !== oldCart?.length) {
      isError = true;
    }

    const cart = newCart.reduce((acc, { uid, availability }) => {
      const maxAvailableQuantity = getAvailableQuantity({ availability });
      const availabilityControl = getAvailabilityControl({ availability });
      const maxQuantity = getMaxQuantityForSelecting(maxAvailableQuantity, availabilityControl);
      const foundItem = oldCart.find((oldItem) => oldItem.uid === uid);

      if (foundItem) {
        if (foundItem?.amount > maxQuantity) {
          if (!isError) {
            isError = true;
          }

          if (maxQuantity <= 0) {
            return acc;
          }

          return [
            ...acc,
            {
              ...foundItem,
              amount: maxQuantity,
              data: {
                ...foundItem.data,
                availability,
              },
            },
          ];
        }

        return [...acc, foundItem];
      }

      return acc;
    }, []);

    return {
      isError,
      newCart: cart,
    };
  };

  return {
    comparingItemsByAvailability,
  };
};
