import React, { useContext, useCallback, useEffect } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { Grid, makeStyles } from '@material-ui/core';
import { Context } from '../store';
import { ACTIONS_TYPES, LOCAL_STORAGE_KEYS } from '../constants';
import { useCartItems, useItemRecommendations, useShowToast } from '../hooks';
import { sessionCacheStorage, storageService } from '../services';
import {
  Breadcrumbs,
  InfiniteScroll,
  ProductsItem,
  ShoppingCartItems,
  ShoppingCartSubtotal,
} from '../components';
import { getLocalStoreFrontSlug, getShoppingCartPageForGoogleAnalytics } from '../utils';

const useStyles = makeStyles((theme) => ({
  titleSection: {
    margin: '100px 0 30px',
    [theme.breakpoints.down('md')]: {
      margin: '50px 0 20px',
    },
  },
}));

const RecommendationsWidget = ({ classes }) => {
  const { itemRecommendations, fetchListMore, hasMore } = useItemRecommendations();

  return itemRecommendations?.length ? (
    <Grid item>
      <h1 className={clsx(classes.titleSection, 'page-title')}>Recommendations</h1>
      <InfiniteScroll
        itemComponent={ProductsItem}
        data={itemRecommendations}
        fetchListMore={fetchListMore}
        hasMore={hasMore}
      />
    </Grid>
  ) : (
    <></>
  );
};

export const CartPage = () => {
  const classes = useStyles();
  const { state, dispatch } = useContext(Context);
  const { cartItems, totalAmount, totalSum } = useCartItems(state.cart);
  const { push } = useHistory();
  const { showError } = useShowToast();
  const storeFrontSlug = getLocalStoreFrontSlug();

  const removeFromCart = (uid) => {
    dispatch({ type: ACTIONS_TYPES.REMOVE_FROM_CART, payload: uid });
  };

  const incrementAmount = useCallback(
    (uid) => {
      dispatch({ type: ACTIONS_TYPES.INCREMENT_AMOUNT, payload: uid });
    },
    [dispatch],
  );

  const decrementAmount = useCallback(
    (uid) => {
      dispatch({ type: ACTIONS_TYPES.DECREMENT_AMOUNT, payload: uid });
    },
    [dispatch],
  );

  const checkErrorAvailability = () => {
    const isError = storageService.getItem(LOCAL_STORAGE_KEYS.isError);

    if (isError) {
      showError(
        "Some of the products you selected are no longer available in the specified quantity. That's why we've edited your Shopping Cart so you can create a new order",
      );
      storageService.removeItem(LOCAL_STORAGE_KEYS.isError);
    }
  };

  useEffect(() => {
    checkErrorAvailability();
    getShoppingCartPageForGoogleAnalytics(window);
  }, []);

  return (
    <>
      <Breadcrumbs catalogName="Shopping cart" />

      <h1 className="page-title" style={{ marginBottom: '20px' }}>
        Shopping cart
      </h1>

      <Grid container spacing={4}>
        <ShoppingCartItems
          cartItems={cartItems}
          incrementAmount={incrementAmount}
          decrementAmount={decrementAmount}
          removeFromCart={removeFromCart}
          totalAmount={totalAmount}
          totalSum={totalSum}
        />

        <ShoppingCartSubtotal
          onClick={() => push(`/${storeFrontSlug}/cart/checkout`)}
          totalAmount={totalAmount}
          totalSum={totalSum}
          buttonText="Proceed to checkout"
          idButton="proceed-to-checkout"
        />
      </Grid>

      {sessionCacheStorage.hasToken && <RecommendationsWidget classes={classes} />}
    </>
  );
};
