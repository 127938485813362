import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { makeStyles } from '@material-ui/core';

import { ListContainer } from './list-container';
import { ContentContainer } from './content-container';
import { Spinner } from '../spinner';
import { EmptyState } from '../empty-state';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflowY: 'hidden',
  },
}));

const NoListItem = () => 'Set ListItem Component';

export const InfiniteList = ({
  data,
  listItem: ListItemComponent = NoListItem,
  listContainer: ListContainerComponent = ListContainer,
  hasMore,
  fetchListMore,
  loading,
  loader,
  onRowClick,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <section className={classes.container}>
      {loading && (
        <div>
          <Spinner isBlock />
        </div>
      )}

      {!loading && !data?.length ? (
        <div>
          <EmptyState />
        </div>
      ) : (
        <ContentContainer>
          {data && data.length > 0 && (
            <InfiniteScroll
              dataLength={data.length}
              next={fetchListMore}
              hasMore={hasMore}
              loader={loader}
              scrollThreshold={0.6}
            >
              <ListContainerComponent {...rest}>
                {data.map((item) => (
                  <ListItemComponent
                    key={`item__${item.uid}}`}
                    data={item}
                    {...rest}
                    onRowClick={onRowClick}
                  />
                ))}
              </ListContainerComponent>
            </InfiniteScroll>
          )}
        </ContentContainer>
      )}
    </section>
  );
};
