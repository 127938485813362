import { useFormik } from 'formik';
import { useEffect, useMemo } from 'react';
import { useValidationShippingSchema } from './useValidationShippingSchema';
import { nameSplitter } from '../utils';

export const useShippingForm = ({ onSubmit, formRef, checkError, account }) => {
  const validationSchema = useValidationShippingSchema();

  const handleSubmit = async (data) => {
    onSubmit(data);
  };

  const defaultAddress = useMemo(() => {
    if (account?.addresses?.length) {
      return {
        addressLine1: account?.addresses?.[0].streetLine1,
        addressLine2: account?.addresses?.[0].streetLine2,
        city: account?.addresses?.[0].city,
        zipCode: account?.addresses?.[0].postalCode,
        state: account?.addresses?.[0].stateCode,
        country: process.env.REACT_APP_FILTER_BY_COUNTRY,
      };
    }
  }, [account?.addresses]);

  const [firstName, lastName] = nameSplitter(account?.contact?.formalName);

  const formik = useFormik({
    initialValues: {
      firstName,
      lastName,
      email: account?.contact?.email ?? '',
      phone: account?.contact?.phone ?? '',
      addressLine1: defaultAddress?.addressLine1 ?? '',
      addressLine2: defaultAddress?.addressLine2 ?? '',
      city: defaultAddress?.city ?? '',
      zipCode: defaultAddress?.zipCode ?? '',
      state: defaultAddress?.state ?? '',
      country: defaultAddress?.country ?? 'US',
    },
    validationSchema,
    onSubmit: handleSubmit,
    innerRef: formRef,
  });

  useEffect(() => {
    checkError(formik?.isValid);
  }, [formik?.errors]);

  return {
    formik,
  };
};
