/* eslint-disable camelcase */
import React, { useContext, useImperativeHandle, useState } from 'react';
import { FormikProvider } from 'formik';
import { Grid, List, ListItem } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import PlacesAutocomplete from 'react-places-autocomplete';
import InputMask from 'react-input-mask';
import { InputTextField } from '../input-text-field';
import { useGeocodeByAddress, useShippingForm } from '../../hooks';
import { getAddressData } from '../../utils';
import { Context } from '../../store';

const useStyles = makeStyles((theme) => ({
  wrapperField: {
    position: 'relative',
  },
  wrapperList: {
    top: '100%',
    position: 'absolute',
    zIndex: '10',
    width: '100%',
    background: theme.palette.white.main,
  },
  list: {
    border: `1px solid ${theme.palette.gray.main}`,
    borderRadius: '18px',
    '& .MuiListItem-root': {
      paddingTop: '0',
      paddingBottom: '0',
      cursor: 'pointer',
      '&:hover': {
        opacity: '0.6',
      },
    },
  },
  formTitle: {
    color: theme.palette.gray.dark,
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: 'bold',
    margin: '0 0 10px',
  },
  input: {
    WebkitBoxShadow: '0 0 0 1000px white inset !important',
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px white inset !important',
    },
    '&:disabled': {
      WebkitBoxShadow: '0 0 0 1000px white inset !important',
    },
  },
  asterisk: {
    color: 'red',
  },
}));

export const ShippingForm = ({ onSubmit, formRef = null, checkError, account }) => {
  const { state } = useContext(Context);
  const classes = useStyles();
  const { formik } = useShippingForm({
    formRef,
    onSubmit,
    checkError,
    account,
  });
  const isError = (field) => formik.touched[field] && !!formik.errors[field];
  const { getGeocodeByAddress } = useGeocodeByAddress();
  const [isFocusAutocomplete, setFocusAutocomplete] = useState(false);

  useImperativeHandle(formRef, () => ({
    ...formik,
  }));

  const getShortAddress = (shippingAddress) => {
    if (shippingAddress?.street_number && shippingAddress?.route) {
      return `${shippingAddress.street_number.long_name} ${shippingAddress.route.long_name}`;
    }
    if (shippingAddress?.route) {
      return shippingAddress?.route.long_name;
    }
    return '';
  };

  const onFocusAutocomplete = () => {
    setFocusAutocomplete(true);
  };

  const onBlurAutocomplete = (e, handlerBlur) => {
    handlerBlur(e);
    setFocusAutocomplete(false);
  };

  const handleSelect = async (address, fieldName, setFieldValue) => {
    const data = await getGeocodeByAddress(address);

    const shippingAddress = getAddressData(data?.addressComponents);
    const shortAddress = getShortAddress(shippingAddress);
    setFieldValue(fieldName, shortAddress);

    const { locality, postal_code, administrative_area_level_1, administrative_area_level_2 } =
      shippingAddress;

    if (locality) {
      setFieldValue('city', locality.long_name);
    } else {
      setFieldValue('city', '');
    }

    if (administrative_area_level_1 || administrative_area_level_2) {
      setFieldValue(
        'state',
        administrative_area_level_1?.short_name || administrative_area_level_2?.short_name,
      );
    } else {
      setFieldValue('state', '');
    }

    if (postal_code) {
      setFieldValue('zipCode', postal_code.long_name);
    } else {
      setFieldValue('zipCode', '');
    }
  };

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={12}>
                <p className={classes.formTitle}>Contact</p>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <InputTextField
                  name="firstName"
                  fullWidth
                  variant="outlined"
                  error={isError('firstName')}
                  helperText={isError('firstName') && formik?.errors?.firstName}
                  label="First Name"
                  InputLabelProps={{
                    required: true,
                    classes: {
                      asterisk: classes.asterisk,
                    },
                  }}
                  inputProps={{ className: classes.input }}
                  value={formik?.values?.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <InputTextField
                  name="lastName"
                  fullWidth
                  variant="outlined"
                  error={isError('lastName')}
                  helperText={isError('lastName') && formik?.errors?.lastName}
                  label="Last Name"
                  InputLabelProps={{
                    required: true,
                    classes: {
                      asterisk: classes.asterisk,
                    },
                  }}
                  inputProps={{ className: classes.input }}
                  value={formik?.values?.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <InputTextField
                  name="email"
                  fullWidth
                  variant="outlined"
                  error={isError('email')}
                  helperText={isError('email') && formik?.errors?.email}
                  label="Email"
                  InputLabelProps={{
                    required: true,
                    classes: {
                      asterisk: classes.asterisk,
                    },
                  }}
                  inputProps={{
                    className: `${classes.input} ${!!account ? 'Mui-disabled' : ''}`,
                    readOnly: !!account,
                  }}
                  value={formik?.values?.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <InputMask
                  alwaysShowMask
                  name="phone"
                  mask="+1 (999) 999 9999"
                  maskChar="_"
                  value={formik?.values?.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  {(inputProps) => (
                    <InputTextField
                      {...inputProps}
                      fullWidth
                      variant="outlined"
                      error={isError('phone')}
                      helperText={isError('phone') && formik?.errors?.phone}
                      label="Mobile Phone Number"
                      InputLabelProps={{
                        classes: {
                          asterisk: classes.asterisk,
                        },
                      }}
                      inputProps={{
                        className: classes.input,
                      }}
                    />
                  )}
                </InputMask>
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={12}>
                <p className={classes.formTitle}>Address</p>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <PlacesAutocomplete
                  name="addressLine1"
                  value={formik?.values?.addressLine1}
                  searchOptions={{
                    componentRestrictions: {
                      country: state?.config?.checkout?.allowedCountries ?? ['US'],
                    },
                    types: ['address'],
                  }}
                  onChange={(value) => formik.setFieldValue('addressLine1', value)}
                  onSelect={(value) => handleSelect(value, 'addressLine1', formik.setFieldValue)}
                  shouldFetchSuggestions={isFocusAutocomplete}
                >
                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div className={classes.wrapperField}>
                      <InputTextField
                        name="addressLine1"
                        fullWidth
                        variant="outlined"
                        error={isError('addressLine1')}
                        helperText={isError('addressLine1') && formik?.errors?.addressLine1}
                        label="Address Line 1"
                        InputLabelProps={{
                          required: true,
                          classes: {
                            asterisk: classes.asterisk,
                          },
                        }}
                        inputProps={{
                          className: classes.input,
                          onBlur: (e) => onBlurAutocomplete(e, formik.handleBlur),
                          onFocus: () => onFocusAutocomplete(),
                        }}
                        value={formik?.values?.addressLine1}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        {...getInputProps()}
                      />
                      <div className={classes.wrapperList}>
                        {loading && <div>Loading...</div>}
                        {suggestions.length ? (
                          <List className={classes.list}>
                            {suggestions.map((suggestion) => (
                              <ListItem
                                key={suggestion.index}
                                {...getSuggestionItemProps(suggestion)}
                              >
                                <ListItemText primary={suggestion.description} />
                              </ListItem>
                            ))}
                          </List>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <InputTextField
                  name="addressLine2"
                  fullWidth
                  variant="outlined"
                  error={isError('addressLine2')}
                  helperText={isError('addressLine2') && formik?.errors?.addressLine2}
                  label="Address Line 2"
                  inputProps={{
                    classes: { input: classes.input },
                  }}
                  value={formik?.values?.addressLine2}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <InputTextField
                  name="city"
                  fullWidth
                  variant="outlined"
                  error={isError('city')}
                  helperText={isError('city') && formik?.errors?.city}
                  label="City"
                  InputLabelProps={{
                    required: true,
                    classes: {
                      asterisk: classes.asterisk,
                    },
                  }}
                  InputProps={{
                    classes: { input: classes.input },
                  }}
                  value={formik?.values?.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <InputTextField
                  name="zipCode"
                  fullWidth
                  variant="outlined"
                  error={isError('zipCode')}
                  helperText={isError('zipCode') && formik?.errors?.zipCode}
                  label="Zip code"
                  InputLabelProps={{
                    required: true,
                    classes: {
                      asterisk: classes.asterisk,
                    },
                  }}
                  inputProps={{
                    classes: { input: classes.input },
                    maxLength: 5,
                  }}
                  value={formik?.values?.zipCode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <InputTextField
                  name="state"
                  fullWidth
                  variant="outlined"
                  error={isError('state')}
                  helperText={isError('state') && formik?.errors?.state}
                  label="State Code"
                  InputLabelProps={{
                    required: true,
                    classes: {
                      asterisk: classes.asterisk,
                    },
                  }}
                  inputProps={{
                    classes: { input: classes.input },
                    maxLength: 2,
                  }}
                  value={formik?.values?.state}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <InputTextField
                  name="country"
                  fullWidth
                  variant="outlined"
                  error={isError('country')}
                  helperText={isError('country') && formik?.errors?.country}
                  label="Country Code"
                  InputLabelProps={{
                    required: true,
                    classes: {
                      asterisk: classes.asterisk,
                    },
                  }}
                  InputProps={{
                    classes: { input: `${classes.input} Mui-disabled` },
                    readOnly: true,
                  }}
                  value={formik?.values?.country}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormikProvider>
  );
};
