import React from 'react';
import { Grid } from '@material-ui/core';
import InfiniteScrollComponent from 'react-infinite-scroll-component';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  // keep this to compensate for grid spacing negative margin
  infiniteScroll: ({ itemSpacing }) => ({
    padding: theme.spacing(Math.ceil(itemSpacing / 2)),
  }),
}));

export const InfiniteScroll = ({
  itemComponent: ItemComponent,
  data = [],
  fetchListMore,
  hasMore,
  loader = <h1>Loading...</h1>,
  itemSpacing = 4,
  gridConfig = {
    xs: 6,
    sm: 4,
    md: 3,
  },
}) => {
  const classes = useStyles({ itemSpacing });

  return (
    <InfiniteScrollComponent
      className={classes.infiniteScroll}
      dataLength={data?.length}
      scrollThreshold={0.6}
      next={fetchListMore}
      hasMore={hasMore}
      loader={loader}
    >
      <Grid className={classes.container} container spacing={itemSpacing}>
        {data?.map((item) => (
          <Grid key={item.uid + item?.slug} item {...gridConfig}>
            <ItemComponent data={item} />
          </Grid>
        ))}
      </Grid>
    </InfiniteScrollComponent>
  );
};
