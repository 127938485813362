import React from 'react';
import { CartEntity } from '../cart-entity';

export const SingleBrand = ({ data }) => (
  <CartEntity
    className="brand-icon" // brand-icon class need to Google Analytics, task CEP-2317
    type="brands"
    data={data}
  />
);
