import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { EmptyTab } from '../empty-tab';

const useStyles = makeStyles(() => ({
  container: {
    color: 'var(--dark-gray)',
  },
}));

export const ProductOverviewTab = ({ description, overviews }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container} spacing={2}>
      {!overviews?.length && !description.length ? (
        <EmptyTab />
      ) : (
        <>
          <Grid
            xs={12}
            item
            className={classes.description}
            /* eslint-disable-next-line react/no-danger */
          >
            <h3>Description</h3>
            {/* eslint-disable-next-line react/no-danger */}
            <p dangerouslySetInnerHTML={{ __html: description }} />
          </Grid>
          {overviews.map((item) => (
            <Grid item key={item.uid} xs={12}>
              {/* eslint-disable-next-line react/no-danger */}
              <h3 dangerouslySetInnerHTML={{ __html: item.title }} />
              {/* eslint-disable-next-line react/no-danger */}
              <p dangerouslySetInnerHTML={{ __html: item.body }} />
            </Grid>
          ))}
        </>
      )}
    </Grid>
  );
};
