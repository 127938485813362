import React from 'react';

import { Container } from './container';
import { ListItem } from '../list-item';
import { InfiniteList } from '../infinite-list';

export const TableList = ({
  data,
  listItem = ListItem,
  hasMore,
  fetchListMore,
  loading,
  loader = <h1>Loading...</h1>,
  columns,
  onRowClick,
  ...props
}) => (
  <InfiniteList
    {...props}
    data={data}
    listItem={listItem}
    listContainer={Container}
    hasMore={hasMore}
    fetchListMore={fetchListMore}
    loading={loading}
    loader={loader}
    columns={columns}
    onRowClick={onRowClick}
  />
);
