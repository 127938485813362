import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { BRAND_TOP_SELLING_PRODUCTS } from '../queries';

export const useBrandTopSellingProducts = (slug) => {
  const { loading, error, data } = useQuery(BRAND_TOP_SELLING_PRODUCTS, {
    variables: { filter: { brandSlugEq: slug } },
  });

  const topSellingBrandProducts = useMemo(() => {
    if (data?.itemsTopSelling?.nodes?.length) {
      return data?.itemsTopSelling?.nodes;
    }

    return null;
  }, [data]);

  return {
    loading,
    error,
    topSellingBrandProducts,
  };
};
