export const convertCentsToDollars = (value, toFixed = 2) => {
  if (!value) {
    return 0;
  }

  if (toFixed) {
    return parseFloat((parseInt(value, 10) / 100).toFixed(toFixed));
  }

  return parseInt(value, 10) / 100;
};
