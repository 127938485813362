import { useLayoutEffect } from 'react';

const trySetupIcon = (elementId, icon) => {
  const faviconElement = document.getElementById(elementId);

  if (faviconElement) {
    faviconElement.href = icon;
  }
};

const handleFaviconUpdate = (favicon) => {
  trySetupIcon('favicon', favicon);
  trySetupIcon('apple-favicon', favicon);
};

export const FaviconSetup = ({ favicon }) => {
  useLayoutEffect(() => {
    if (favicon) handleFaviconUpdate(favicon);
  }, [favicon]);

  return null;
};
