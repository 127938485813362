import { PRICE_TYPES } from '../constants';
import { getPriceByType } from './get-price-by-type';

export const getProductPrices = (productData) => {
  const regular = getPriceByType(productData?.prices, PRICE_TYPES.regular);
  const discount = getPriceByType(productData?.prices, PRICE_TYPES.discounted);

  return {
    regular: regular?.value ?? 0,
    discount: discount?.value ?? null,
  };
};
