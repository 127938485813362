import React, { useCallback, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import { ORDER_ITEMS_STATUS, OUTER_LINKS } from '../../constants';
import {
  ClockIcon,
  DeliveryTruckIcon,
  CompleteIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  PaidIcon,
  CancelledIcon,
  ReturnedIcon,
} from '../icons/Icons';
import {
  getItemsLabel,
  getOrderItemsByStatus,
  convertCamelToSentence,
  getOrderItemsByTrackingNumber,
} from '../../utils';
import { OrderItems } from '../order-items';
import { OrderItemStatusLabel } from '../order-item-status-label';

const useStyles = makeStyles((theme) => ({
  timeline: {
    position: 'relative',
    margin: '0 auto',
    paddingLeft: '20px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '27px',
      paddingLeft: '16px',
    },

    '&::after': {
      content: '""',
      position: 'absolute',
      width: '2px',
      top: 0,
      bottom: 0,
      marginLeft: '-1px',
      backgroundColor: theme.palette.gray.dark,
    },
  },
  container: {
    marginTop: '20px',
    padding: '10px 0 0 44px',
    position: 'relative',
    backgroundColor: 'inherit',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '36px',
    },

    '&:first-of-type': {
      marginTop: 0,
    },
  },
  right: {
    '&::after': {
      left: '-12px',
    },
  },
  label: {
    marginBottom: '32px',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '16px',
    color: theme.palette.gray.main,
    textTransform: 'capitalize',
    opacity: '0.15',

    '&.active': {
      color: theme.palette.gray.dark,
      opacity: 1,
    },
  },
  icon: {
    position: 'absolute',
    left: '-20px',
    top: 0,
    zIndex: 1,
    width: '40px',
    height: '40px',
    color: '#fff',
    [theme.breakpoints.down('sm')]: {
      width: '32px',
      height: '32px',
      left: '-16px',
    },

    '& svg': {
      height: '100%',
      width: '100%',
    },

    '&::after': {
      content: '""',
      position: 'absolute',
      display: 'block',
      borderRadius: '50%',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: -1,
      backgroundColor: theme.palette.gray.main,
      opacity: 0.15,
    },

    '&::before': {
      content: '""',
      position: 'absolute',
      display: 'block',
      borderRadius: '50%',
      top: '-2px',
      left: '-2px',
      width: 'calc(100% + 4px)',
      height: 'calc(100% + 4px)',
      backgroundColor: theme.palette.white.main,
      zIndex: -1,
    },

    '&.active': {
      '&::after': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
      },

      '&::before': {
        backgroundColor: theme.palette.white.main,
      },
      '&.canceled': {
        '&::after': {
          backgroundColor: theme.palette.gray.dark,
        },
      },
    },
  },
  content: {
    position: 'relative',
  },
  contentBlock: {
    marginTop: '16px',
    border: `1px solid ${theme.palette.gray.light}`,
    borderBottom: ({ displayContent }) =>
      displayContent ? `1px solid ${theme.palette.gray.light}` : '1px solid transparent',
    borderRadius: '4px',
  },
  contentBlockHeader: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '15px 58px 15px 32px',
    borderBottom: `1px solid ${theme.palette.gray.light}`,
    backgroundColor: theme.palette.gray.hover,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '15px',
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      display: 'block',
      top: '50%',
      left: '-49px',
      transform: 'translateY(-50%)',
      width: '8px',
      height: '8px',
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.main,
      zIndex: 2,
      [theme.breakpoints.down('sm')]: {
        left: '-41px',
      },
    },

    '&::after': {
      content: '""',
      position: 'absolute',
      display: 'block',
      top: '50%',
      left: '-51px',
      transform: 'translateY(-50%)',
      width: '12px',
      height: '12px',
      borderRadius: '50%',
      backgroundColor: theme.palette.white.main,
      zIndex: 1,
      [theme.breakpoints.down('sm')]: {
        left: '-43px',
      },
    },
  },
  contentBlockTracking: {
    fontSize: '16px',
    lineHeight: '28px',
    color: theme.palette.gray.dark,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      width: '100%',
      paddingRight: '10px',
      fontSize: '10px',
      lineHeight: '21px',
      whiteSpace: 'nowrap',
    },
    '& span': {
      fontWeight: 'bold',
      paddingRight: '12px',
      [theme.breakpoints.down('sm')]: {
        paddingRight: '5px',
        fontSize: '10px',
      },
    },
    '& a': {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '21px',
      color: theme.palette.primary.main,
      textDecoration: 'underline',
      [theme.breakpoints.down('sm')]: {
        fontSize: '10px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
    },
  },
  contentBlockStatus: {
    display: 'inline-flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      marginTop: '5px',
    },

    '& > div:first-of-type': {
      marginRight: '20px',
      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
        padding: '0 15px',
        '& span': {
          fontSize: '10px',
        },
      },
    },

    '& > div:last-of-type': {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '21px',
      [theme.breakpoints.down('sm')]: {
        marginRight: '25px',
        fontSize: '12px',
      },
    },
  },
  contentBlockButton: {
    position: 'absolute',
    right: '12px',
    cursor: 'pointer',
    border: 'none',
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      bottom: '10px',
      right: '12px',
      padding: 0,
    },

    '& svg': {
      width: '18px',
    },
  },
  contentBlockBody: {
    backgroundColor: '#fff',
    display: 'none',
    '&.show': {
      padding: '8px 0 5px',
      display: 'block',
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
  },
}));

const trackingNumberLink = (trackingNumber) => (
  <a
    href={OUTER_LINKS.tracking_number_url + trackingNumber}
    target="_blank"
    rel="noopener noreferrer"
  >
    {trackingNumber}
  </a>
);

const trackingNumberPanel = (trackingNumber, carrierName) => {
  if (trackingNumber === '') {
    return <span>No tracking Information</span>;
  }

  return (
    <span>
      {carrierName} tr.№ {trackingNumberLink(trackingNumber)}
    </span>
  );
};

const OrderItemsContent = ({ shippingStatus, orderItems, trackingNumber, carrierName }) => {
  const [displayContent, setDisplayContent] = useState(true);
  const classes = useStyles({ displayContent });
  const counter = useMemo(() => orderItems.reduce((acc, item) => acc + item?.qty, 0), [orderItems]);

  const toggleContent = useCallback(() => {
    setDisplayContent(!displayContent);
  }, [displayContent]);

  return (
    <div className={classes.contentBlock}>
      <div className={classes.contentBlockHeader}>
        <div className={classes.contentBlockTracking}>
          {trackingNumberPanel(trackingNumber, carrierName)}
        </div>

        <div className={classes.contentBlockStatus}>
          <OrderItemStatusLabel status={shippingStatus} />

          <div>
            {counter} {getItemsLabel(counter)}
          </div>
        </div>

        <button type="button" onClick={toggleContent} className={classes.contentBlockButton}>
          {displayContent ? <ArrowDownIcon /> : <ArrowUpIcon />}
        </button>
      </div>

      <div className={clsx(classes.contentBlockBody, displayContent ? 'show' : '')}>
        <OrderItems orderItems={orderItems} />
      </div>
    </div>
  );
};

export const Timeline = ({ orderItems }) => {
  const classes = useStyles();

  const {
    paid: paidItems,
    communicated: communicatedItems,
    shipped: shippedItems,
    completed: completedItems,
    shortShipped: shortShippedItems,
    returned: returnedItems,
  } = useMemo(() => {
    const orderItemsByStatus = getOrderItemsByStatus(orderItems);

    return getOrderItemsByTrackingNumber(orderItemsByStatus);
  }, [orderItems]);

  return (
    <div className={classes.timeline}>
      <div className={clsx(classes.container, classes.right)}>
        <div className={clsx(classes.icon, paidItems ? 'active' : '')}>
          <PaidIcon />
        </div>

        <div className={classes.content}>
          <div className={clsx(classes.label, paidItems ? 'active' : '')}>
            {ORDER_ITEMS_STATUS.paid}
          </div>

          {paidItems &&
            Object?.keys(paidItems)?.map((trackingNumber) => (
              <OrderItemsContent
                key={trackingNumber}
                trackingNumber={trackingNumber}
                carrierName={paidItems?.[trackingNumber]?.[0]?.carrierName}
                orderItems={paidItems?.[trackingNumber]}
                shippingStatus={ORDER_ITEMS_STATUS.paid}
              />
            ))}
        </div>
      </div>

      <div className={clsx(classes.container, classes.right)}>
        <div className={clsx(classes.icon, communicatedItems ? 'active' : '')}>
          <ClockIcon />
        </div>

        <div className={classes.content}>
          <div className={clsx(classes.label, communicatedItems ? 'active' : '')}>
            {ORDER_ITEMS_STATUS.communicated}
          </div>

          {communicatedItems &&
            Object?.keys(communicatedItems)?.map((trackingNumber) => (
              <OrderItemsContent
                key={trackingNumber}
                trackingNumber={trackingNumber}
                carrierName={communicatedItems?.[trackingNumber]?.[0]?.carrierName}
                orderItems={communicatedItems?.[trackingNumber]}
                shippingStatus={ORDER_ITEMS_STATUS.communicated}
              />
            ))}
        </div>
      </div>

      <div className={clsx(classes.container, classes.right)}>
        <div className={clsx(classes.icon, shippedItems ? 'active' : '')}>
          <DeliveryTruckIcon />
        </div>

        <div className={classes.content}>
          <div className={clsx(classes.label, shippedItems ? 'active' : '')}>
            {ORDER_ITEMS_STATUS.shipped}
          </div>

          {shippedItems &&
            Object?.keys(shippedItems)?.map((trackingNumber) => (
              <OrderItemsContent
                key={trackingNumber}
                trackingNumber={trackingNumber}
                carrierName={shippedItems?.[trackingNumber]?.[0]?.carrierName}
                orderItems={shippedItems?.[trackingNumber]}
                shippingStatus={ORDER_ITEMS_STATUS.shipped}
              />
            ))}
        </div>
      </div>

      <div className={clsx(classes.container, classes.right)}>
        <div className={clsx(classes.icon, completedItems ? 'active' : '')}>
          <CompleteIcon />
        </div>

        <div className={classes.content}>
          <div className={clsx(classes.label, completedItems ? 'active' : '')}>
            {ORDER_ITEMS_STATUS.completed}
          </div>

          {completedItems &&
            Object?.keys(completedItems)?.map((trackingNumber) => (
              <OrderItemsContent
                key={trackingNumber}
                trackingNumber={trackingNumber}
                carrierName={completedItems?.[trackingNumber]?.[0]?.carrierName}
                orderItems={completedItems?.[trackingNumber]}
                shippingStatus={ORDER_ITEMS_STATUS.completed}
              />
            ))}
        </div>
      </div>

      <div className={clsx(classes.container, classes.right)}>
        <div className={clsx(classes.icon, shortShippedItems ? 'active canceled' : '')}>
          <CancelledIcon />
        </div>

        <div className={classes.content}>
          <div className={clsx(classes.label, shortShippedItems ? 'active' : '')}>
            {convertCamelToSentence(ORDER_ITEMS_STATUS.shortShipped)}
          </div>

          {shortShippedItems &&
            Object?.keys(shortShippedItems)?.map((trackingNumber) => (
              <OrderItemsContent
                key={trackingNumber}
                trackingNumber={trackingNumber}
                carrierName={shortShippedItems?.[trackingNumber]?.[0]?.carrierName}
                orderItems={shortShippedItems?.[trackingNumber]}
                shippingStatus={ORDER_ITEMS_STATUS.shortShipped}
                headerClassName={classes.canceled}
              />
            ))}
        </div>
      </div>

      <div className={clsx(classes.container, classes.right)}>
        <div className={clsx(classes.icon, returnedItems ? 'active canceled' : '')}>
          <ReturnedIcon />
        </div>

        <div className={classes.content}>
          <div className={clsx(classes.label, returnedItems ? 'active' : '')}>
            {ORDER_ITEMS_STATUS.returned}
          </div>

          {returnedItems &&
            Object?.keys(returnedItems)?.map((trackingNumber) => (
              <OrderItemsContent
                key={trackingNumber}
                trackingNumber={trackingNumber}
                carrierName={returnedItems?.[trackingNumber]?.[0]?.carrierName}
                orderItems={returnedItems?.[trackingNumber]}
                shippingStatus={ORDER_ITEMS_STATUS.returned}
                headerClassName={classes.canceled}
              />
            ))}
        </div>
      </div>
    </div>
  );
};
