import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import NotFoundImage from '../../assets/404.svg';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    [theme.breakpoints.down('md')]: {
      height: '100%',
      padding: '20px 0',
      flexGrow: '1',
    },
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      padding: '0 20px',
    },
  },
  title: {
    margin: '56px 0 0',
    fontSize: '24px',
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#585858',
    lineHeight: '1',
  },
  text: {
    margin: '24px 0',
    fontSize: '14px',
    textAlign: 'center',
    fontWeight: '500',
    color: '#585858',
  },
}));

export const NotFoundPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.block}>
        <div>
          <img src={NotFoundImage} alt="404" />
        </div>
        <p className={classes.title}> Oops, something went wrong!</p>
        <p className={classes.text}>
          The page requested couldn’t be found. <br />
          This could be a spelling error in the URL or a removed page.
        </p>
      </div>
    </div>
  );
};
