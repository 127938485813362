import { CURRENCY, GA_EVENTS, GA_EVENT_LOCATIONS } from '../../constants';
import { getShoppingCartItem } from '../get-shopping-cart-item-list';

export const handleAddToCartForGoogleAnalytics = (window, product) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });

  window.dataLayer.push({
    event: GA_EVENTS.add_to_cart,
    event_location: GA_EVENT_LOCATIONS.content,
    ecommerce: {
      currency: CURRENCY,
      items: [getShoppingCartItem(product)],
    },
  });
};
