import { useFormik } from 'formik';
import { useCallback } from 'react';

import { useValidationCouponCodeSchema } from './use-validation-coupon-code-schema';

export const useCouponCodeForm = ({ onSubmit }) => {
  const validationSchema = useValidationCouponCodeSchema();

  const handleSubmit = useCallback(async (data) => {
    onSubmit(data);
  }, []);

  const formik = useFormik({
    initialValues: {
      code: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  return {
    formik,
  };
};
