import React, { useEffect } from 'react';
import { OrderList } from '../components/orders-list';
import { useOrderList } from '../hooks';
import { getAccountOrderHistoryPageForGoogleAnalytics } from '../utils';

export const OrdersHistoryListPage = () => {
  const { isLoading, isError, orders, fetchListMore, hasMoreOrders } = useOrderList();

  useEffect(() => {
    if (!isLoading && !isError) {
      getAccountOrderHistoryPageForGoogleAnalytics(window);
    }
  }, [isLoading]);

  return (
    <OrderList
      orders={orders}
      fetchListMore={fetchListMore}
      hasMore={hasMoreOrders}
      isLoading={isLoading}
      isError={isError}
    />
  );
};
