import { gql } from '@apollo/client';

export const UPDATE_ACCOUNT = gql`
  mutation updateAccount($input: UpdateBuyerInput!) {
    updateBuyer(input: $input) {
      addresses {
        city
        countryCode
        postalCode
        stateCode
        streetLine1
        streetLine2
        type
        uid
      }
      contact {
        email
        formalName
        phone
      }
      identifier
    }
  }
`;
