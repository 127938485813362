import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  name: {
    fontSize: '15px',
    fontWeight: 'bold',
    color: theme.palette.gray.dark,
    textTransform: 'uppercase',
  },
  email: {
    fontSize: '14px',
    fontWeight: 'regular',
    color: theme.palette.gray.main,
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
}));

export const Seller = ({ seller }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.name}>{seller?.contact?.formalName ?? ''}</div>
      <div className={classes.email}>{seller?.contact?.email ?? ''}</div>
    </div>
  );
};
