export const ROUTING_CONFIG = {
  home: '/:storeFrontSlug',
  deals: '/:storeFrontSlug/deals',
  aboutUs: '/:storeFrontSlug/about-us',
  blog: '/:storeFrontSlug/blog',
  brands: '/:storeFrontSlug/brands',
  brand: '/:storeFrontSlug/brands/:brandSlug',
  notFound: '/page/not-found',
  categories: '/:storeFrontSlug/categories',
  category: '/:storeFrontSlug/categories/:categorySlug',
  productDetails: '/:storeFrontSlug/products/:productSlug',
  cart: '/:storeFrontSlug/cart',
  checkout: '/:storeFrontSlug/cart/checkout',
  orderSuccessful: '/:storeFrontSlug/order-successful',
  orderCanceled: '/:storeFrontSlug/order-canceled',
  contactUs: '/:storeFrontSlug/contact-us',
  siteMap: '/:storeFrontSlug/site-map',
  privacyPolicy: '/:storeFrontSlug/privacy-policy',
  termsAndConditions: '/:storeFrontSlug/terms-and-conditions',
  search: '/:storeFrontSlug/search',
  session: '/:storeFrontSlug/session',
  accountDetails: '/:storeFrontSlug/account-details',
  accountSettings: '/:storeFrontSlug/account-details',
  accountOrderHistory: '/:storeFrontSlug/account-details/order-history',
  accountRecommendations: '/:storeFrontSlug/account-details/recommendations',
  orderHistoryDetails: '/:storeFrontSlug/order-history/:orderId',
  orderRequestReturn: '/:storeFrontSlug/order-history/:orderId/request-return',
  orderReturnSuccessful: '/:storeFrontSlug/order-return-successful/:orderId',
};
