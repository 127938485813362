import React from 'react';
import { Button as MaterialButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { BUTTON_COLORS_TYPES, BUTTON_SIZES_TYPES } from '../../constants';

const useStyles = makeStyles((theme) => ({
  btn: {
    borderRadius: '18px',
    color: ({ colorStyles }) => colorStyles(theme).color,
    fontSize: '12px',
    background: ({ colorStyles }) => colorStyles(theme).background,
    letterSpacing: '1.2px',
    height: '36px',
    width: '100%',
    maxWidth: (props) => props.maxWidth,
    padding: '5px',
    '&:disabled': {
      color: 'rgba(0, 0, 0, 0.26)',
      boxShadow: 'none',
      background: 'rgba(0, 0, 0, 0.12)',
    },
    [theme.breakpoints.up('sm')]: {
      '&:hover': {
        color: theme.palette.primary.main,
        background: 'var(--white)',
        boxShadow: '0px 2px 20px #1B1B1D26',
      },
    },
  },
}));

const colorStyles = {
  [BUTTON_COLORS_TYPES.primary]: (theme) => ({
    color: theme.palette.white.main,
    background: theme.palette.primary.main,
  }),
  [BUTTON_COLORS_TYPES.default]: (theme) => ({
    color: theme.palette.gray.dark,
    background: theme.palette.gray.light,
  }),
  [BUTTON_COLORS_TYPES.white]: (theme) => ({
    color: theme.palette.gray.dark,
    background: theme.palette.white.main,
  }),
};

const maxWidthSizes = {
  [BUTTON_SIZES_TYPES.medium]: '250px',
  [BUTTON_SIZES_TYPES.action]: '170px',
};

export const Button = ({
  children,
  className,
  size = BUTTON_SIZES_TYPES.medium,
  color = BUTTON_COLORS_TYPES.primary,
  ...props
}) => {
  const classes = useStyles({ maxWidth: maxWidthSizes[size], colorStyles: colorStyles[color] });

  return (
    <MaterialButton className={clsx(classes.btn, className)} {...props}>
      {children}
    </MaterialButton>
  );
};
