import React, { useContext, useEffect } from 'react';
import { Spinner, ErrorState, SliderWidget, CarouselWidget, ProductsWidget } from '../components';
import { useHomePageQuery, useItemRecommendations } from '../hooks';
import { Context } from '../store';
import { sessionCacheStorage } from '../services';
import { getHomePageForGoogleAnalytics, getStateByZipCode } from '../utils';

const RecommendationsWidget = () => {
  const { itemRecommendations } = useItemRecommendations();

  return itemRecommendations?.length ? (
    <ProductsWidget
      style={{ marginBottom: '50px' }}
      key="Recommendations"
      items={itemRecommendations}
      title="Recommendations"
    />
  ) : (
    <></>
  );
};

const HomeContent = ({ zipCode, stateCode }) => {
  const {
    preferredBrands,
    preferredProducts,
    brandsTopSelling,
    itemsTopSelling,
    categories,
    promotionBanners,
    isLoading,
    isError,
  } = useHomePageQuery({
    ...(zipCode
      ? {
          filter: [zipCode, stateCode?.shortName],
        }
      : {}),
  });

  useEffect(() => {
    if (!isLoading && !isError) {
      getHomePageForGoogleAnalytics(window, sessionCacheStorage.hasToken);
    }
  }, [isLoading]);

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return <ErrorState />;
  }

  return (
    <>
      {promotionBanners?.length ? (
        <SliderWidget style={{ marginBottom: '50px' }} items={promotionBanners} />
      ) : (
        <></>
      )}

      {!!sessionCacheStorage.hasToken && <RecommendationsWidget />}

      {preferredBrands?.length ? (
        <CarouselWidget
          style={{ marginBottom: '50px' }}
          items={preferredBrands}
          title="My Favorite Brands"
          type="brands"
          classNameEntity="brand-icon" // classNameEntity need to Google Analytics, task CEP-2317
        />
      ) : (
        <></>
      )}

      {preferredProducts?.length ? (
        <ProductsWidget
          style={{ marginBottom: '50px' }}
          key="itemsFeatured"
          items={preferredProducts}
          title="My Favorite Products"
        />
      ) : (
        <></>
      )}

      {brandsTopSelling?.length ? (
        <CarouselWidget
          style={{ marginBottom: '50px' }}
          items={brandsTopSelling}
          title="Top Selling Brands"
          type="brands"
          classNameEntity="brand-icon" // classNameEntity need to Google Analytics, task CEP-2317
        />
      ) : (
        <></>
      )}

      {itemsTopSelling?.length ? (
        <ProductsWidget
          style={{ marginBottom: '50px' }}
          key="itemsTopSelling"
          items={itemsTopSelling}
          title="Top Selling Products"
        />
      ) : (
        <></>
      )}

      {categories?.length ? (
        <CarouselWidget
          items={categories}
          title="Categories"
          type="categories"
          classNameEntity="category-icon" // classNameEntity need to Google Analytics, task CEP-2317
        />
      ) : (
        <></>
      )}
    </>
  );
};

export const Home = () => {
  const { state } = useContext(Context);
  const zipCode = state?.zipCode ?? '';
  const stateCode = getStateByZipCode(zipCode);

  if (!state?.initialized) {
    return <Spinner />;
  }

  return <HomeContent zipCode={zipCode} stateCode={stateCode} />;
};
