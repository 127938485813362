import { useState, useEffect } from 'react';
import isEqual from 'lodash.isequal';

import { usePaginatedQuery } from './usePaginatedQuery';
import { PER_PAGE } from '../constants';

export const usePaginatedList = (
  query,
  { defaultSort, perPage = PER_PAGE, filter, ...settings } = {},
  querySettings = {},
) => {
  const {
    data,
    error,
    loading,
    fetchListMore: fetchMore,
    fetchList: fetch,
  } = usePaginatedQuery(
    query,
    {
      defaultSort,
      perPage,
      ...settings,
    },
    querySettings,
  );

  const [page, setPage] = useState(1);
  const [sort, changeSort] = useState(defaultSort);
  const [activeFilter, setActiveFilter] = useState(filter);

  const fetchListMore = async () => {
    const newPage = page + 1;
    setPage(newPage);

    fetchMore(newPage, sort, filter);
  };

  const fetchList = async (page = 1) => {
    fetch(page, sort, filter);
  };

  const refetch = fetchList;

  useEffect(() => {
    if (!isEqual(filter, activeFilter)) {
      setActiveFilter(filter);
    }
  }, [filter]);

  useEffect(() => {
    fetchList();
  }, [sort, activeFilter]);

  return {
    page,
    sort,
    changeSort,
    hasMore: page * perPage < data?.pagination?.totalNodes,
    fetchListMore,
    fetchList,
    refetch,
    data,
    error,
    loading,
  };
};
