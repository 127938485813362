import React, { createContext, useEffect, useReducer } from 'react';
import { ACTIONS_TYPES } from '../constants';
import {
  getLocalStorageBasket,
  getLocalStorageZipCode,
  setLocalStorageBasket,
  handleAddToCartForGoogleAnalytics,
  handleRemoveFromCartForGoogleAnalytics,
} from '../utils';

const initialState = {
  account: null,
  config: {},
  cart: [],
  zipCode: null,
  initialized: false,
};

const Reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS_TYPES.ADD_TO_CART: {
      const cart = [...state.cart, { uid: action.payload.uid, data: action.payload, amount: 1 }];

      const foundItem = cart.find(({ uid }) => uid === action?.payload?.uid);

      if (foundItem) {
        handleAddToCartForGoogleAnalytics(window, { ...foundItem, amount: 1 });
      }

      setLocalStorageBasket(cart);

      return { ...state, cart };
    }
    case ACTIONS_TYPES.REMOVE_FROM_CART: {
      const cart = state.cart.filter((item) => item.uid !== action.payload);
      const productDetails = state.cart?.find(({ uid }) => uid === action.payload);

      if (productDetails) {
        handleRemoveFromCartForGoogleAnalytics(window, { ...productDetails });
      }

      setLocalStorageBasket(cart);

      return { ...state, cart };
    }
    case ACTIONS_TYPES.INCREMENT_AMOUNT: {
      const cart = state.cart.map((item) =>
        item.uid === action.payload ? { ...item, amount: item.amount + 1 } : item,
      );
      const foundItem = cart.find(({ uid }) => uid === action.payload);

      if (foundItem) {
        handleAddToCartForGoogleAnalytics(window, { ...foundItem, amount: 1 });
      }

      setLocalStorageBasket(cart);

      return { ...state, cart };
    }
    case ACTIONS_TYPES.DECREMENT_AMOUNT: {
      const cart = state.cart?.reduce((acc, item) => {
        if (item.uid !== action.payload) return [...acc, item];
        if (item.amount > 1) return [...acc, { ...item, amount: item.amount - 1 }];
        return acc;
      }, []);

      const productDetails = state.cart?.find(({ uid }) => uid === action.payload);

      if (productDetails) {
        handleRemoveFromCartForGoogleAnalytics(window, { ...productDetails, amount: 1 });
      }

      setLocalStorageBasket(cart);

      return { ...state, cart };
    }
    case ACTIONS_TYPES.SET_LOCAL_CART: {
      return { ...state, cart: action.payload };
    }
    case ACTIONS_TYPES.CLEAR_LOCAL_CART: {
      setLocalStorageBasket(initialState.cart);

      return { ...state, cart: [] };
    }
    case ACTIONS_TYPES.UPDATE_LOCAL_CART: {
      setLocalStorageBasket(action.payload);

      return { ...state, cart: action.payload };
    }
    case ACTIONS_TYPES.UPDATE_ACCOUNT: {
      return { ...state, account: action.payload };
    }
    case ACTIONS_TYPES.CLEAR_ACCOUNT: {
      return { ...state, account: null };
    }
    case ACTIONS_TYPES.UPDATE_ZIP_CODE: {
      return { ...state, zipCode: action.payload };
    }
    case ACTIONS_TYPES.INITIALIZED: {
      return { ...state, initialized: action.payload };
    }
    default:
      return state;
  }
};

const Store = ({ children, config, account }) => {
  const [state, dispatch] = useReducer(Reducer, { ...initialState, config, account });

  useEffect(() => {
    const basket = getLocalStorageBasket();
    const zipCode = getLocalStorageZipCode();

    if (basket) {
      dispatch({ type: ACTIONS_TYPES.SET_LOCAL_CART, payload: basket });
    }

    if (zipCode) {
      dispatch({ type: ACTIONS_TYPES.UPDATE_ZIP_CODE, payload: zipCode });
    }

    dispatch({ type: ACTIONS_TYPES.INITIALIZED, payload: true });
  }, []);

  return <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>;
};

export const Context = createContext(initialState);
export default Store;
