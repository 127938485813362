import React, { useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useParams, useHistory } from 'react-router-dom';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { useOrderDetails, useReturnOrderItems, useRequestReturnItems } from '../../hooks';
import {
  Breadcrumbs,
  Spinner,
  RequestReturnItems,
  RequestReturnSubtotalCart,
  ReturnConfirmationModal,
  EmptyState,
} from '../../components';
import { getLocalStoreFrontSlug, getOrderRequestReturnPageForGoogleAnalytics } from '../../utils';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    marginBottom: '20px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '25px',
      marginBottom: '0',
    },
  },
  emptyState: {
    marginTop: '20px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export const OrderRequestReturnPage = () => {
  const classes = useStyles();
  const { push } = useHistory();
  const { orderId } = useParams();
  const storeFrontSlug = getLocalStoreFrontSlug();
  const { loading: loadingOrderDetails, order } = useOrderDetails({ orderId });

  const { orderItems } = useRequestReturnItems(order?.orderItems);

  const [selectedItems, setSelectedItems] = useState(null);
  const { subTotalItems, totalPrice } = useMemo(
    () =>
      (selectedItems ?? [])?.reduce(
        (acc, item) => ({
          subTotalItems: acc.subTotalItems + item.amount,
          totalPrice: acc.totalPrice + item.amount * item.price,
        }),
        { subTotalItems: 0, totalPrice: 0 },
      ),
    [selectedItems],
  );
  const [reason, setReason] = useState(null);
  const [modalIsOpen, setOpeningModal] = useState(false);
  const { returnOrderItems, loading } = useReturnOrderItems();

  const handleOpenModal = useCallback(() => {
    setOpeningModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setOpeningModal(false);
  }, []);

  const handleConfirmation = async () => {
    const itemsWithAmount = selectedItems.filter(({ amount }) => amount > 0);
    const returnedItems = (itemsWithAmount || [])?.map(({ amount, uid }) => ({
      orderItemUid: uid,
      qty: amount,
    }));
    await returnOrderItems({ reason: reason?.value, orderUid: order?.uid, returnedItems });
    handleCloseModal();
    push(`/${storeFrontSlug}/order-return-successful/${order?.uid}`);
  };

  useEffect(() => {
    if (orderItems?.length && !selectedItems) {
      setSelectedItems(orderItems);
      getOrderRequestReturnPageForGoogleAnalytics(window);
    }
  }, [order?.orderItems, selectedItems]);

  const incrementAmount = useCallback(
    (uid) =>
      setSelectedItems((oldItems) =>
        oldItems?.map((item) => {
          if (item?.uid === uid) {
            return {
              ...item,
              amount: item?.amount + 1,
            };
          }

          return item;
        }),
      ),
    [],
  );

  const decrementAmount = useCallback(
    (uid) =>
      setSelectedItems((oldItems) =>
        oldItems.map((item) => {
          if (item?.uid === uid && item?.amount > 0) {
            return {
              ...item,
              amount: item?.amount > 0 ? item?.amount - 1 : 0,
            };
          }

          return item;
        }),
      ),
    [],
  );

  if (loadingOrderDetails) {
    return <Spinner isBackground />;
  }

  return (
    <Box container>
      <Breadcrumbs
        links={[
          {
            link: '/account-details/order-history',
            linkName: 'Order History',
          },
          {
            link: `/order-history/${order?.uid}`,
            linkName: `Order #${order?.number}`,
          },
        ]}
        catalogName="Request Return"
      />

      <h1 className={clsx('page-title', classes.pageTitle)}>Request Return</h1>

      <Grid container spacing={4}>
        {!selectedItems?.length ? (
          <div className={classes.emptyState}>
            <EmptyState />
          </div>
        ) : (
          <>
            <RequestReturnItems
              items={selectedItems}
              incrementAmount={incrementAmount}
              decrementAmount={decrementAmount}
            />

            <RequestReturnSubtotalCart
              subTotalItems={subTotalItems}
              totalPrice={totalPrice}
              reason={reason}
              selectReason={(option) => setReason(option)}
              onClick={handleOpenModal}
            />

            <ReturnConfirmationModal
              isOpen={modalIsOpen}
              handleCancel={handleCloseModal}
              handleConfirmation={handleConfirmation}
              loading={loading}
            />
          </>
        )}
      </Grid>
    </Box>
  );
};
