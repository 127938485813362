import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { useCategoriesQuery } from '../hooks';
import { sessionCacheStorage } from '../services';
import { CartEntity, Spinner, Breadcrumbs, EmptyState, ErrorState } from '../components';
import { getCategoryListPageForGoogleAnalytics } from '../utils';

const useStyles = makeStyles((theme) => ({
  categoryItem: {
    marginBottom: theme.spacing(4),
  },
  container: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
  },
}));

export const Categories = () => {
  const classes = useStyles();
  const { categories, isLoading, isError } = useCategoriesQuery();

  useEffect(() => {
    if (!isLoading && !isError) {
      getCategoryListPageForGoogleAnalytics(window, sessionCacheStorage.hasToken);
    }
  }, [isLoading]);

  if (isLoading) return <Spinner />;

  return (
    <>
      <Breadcrumbs catalogName="Categories" />

      <h1 className="page-title">Categories</h1>

      {isError ? (
        <ErrorState />
      ) : (
        <>
          {!categories.length ? (
            <EmptyState />
          ) : (
            <Grid className={classes.container} container spacing={4}>
              {categories.map((item) => (
                <Grid key={item.uid} item xs={6} sm={3} md={3} lg={3}>
                  <CartEntity
                    className={clsx('category-icon', classes.categoryItem)} // category-icon class need to Google Analytics, task CEP-2317
                    data={item}
                    type="categories"
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </>
      )}
    </>
  );
};
