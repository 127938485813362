import React from 'react';
import { Button } from '@material-ui/core';
import { BasketIcon } from '../icons/Icons';
import { ProductCartAmount } from '../product-cart-amount';

export const AddToCartButton = ({
  data,
  classes,
  incrementAmount,
  decrementAmount,
  maxQuantity,
  addToCart,
  availabilityControl,
}) => {
  if (availabilityControl && maxQuantity <= 0) {
    return <h3 className={classes.category}>Out of stock</h3>;
  }

  return (
    <div style={{ minHeight: '57px' }}>
      {data ? (
        <>
          <p className={classes.qtyLabel}>IN CART:</p>
          <ProductCartAmount
            value={data?.amount}
            increment={incrementAmount}
            decrement={decrementAmount}
            maxQuantity={maxQuantity}
            availabilityControl={availabilityControl}
          />
        </>
      ) : (
        <Button
          className={classes.cartBtn}
          variant="contained"
          startIcon={<BasketIcon />}
          onClick={addToCart}
        >
          Add to cart
        </Button>
      )}
    </div>
  );
};
