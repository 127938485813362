import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Slide from '@material-ui/core/Slide';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { NavLink, Link, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import ListItem from '@material-ui/core/ListItem';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Toolbar,
  Grid,
  Container,
  Badge,
  Hidden,
  Drawer,
  List,
  IconButton,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import isEqual from 'lodash.isequal';
import { SearchPanel } from '../search-panel';
import { Footer } from '../footer';
import { BrandsIcon, CategoriesIcon, BasketIcon, ProfileIcon, BookIcon } from '../icons/Icons';
import { Context } from '../../store';
import { HeaderContent } from './header-content';
import { ROUTING_CONFIG } from '../../constants';
import { debounce, getLocalStoreFrontSlug } from '../../utils';
import { Auth } from '../auth';
import { emailService } from '../../services';
import { LogoutButton } from '../logout-button';
import { AttentionBar } from '../attention-bar';
import { UpdateLocationButton } from '../update-location-button';
import {
  useCreateSubscriptionAuthLink,
  useMeShoppingCart,
  useShowToast,
  useGetSchedulerConfig,
} from '../../hooks';

const drawerWidth = 283;
const DEFAULT_NUMBER_OF_BUTTONS_IN_USER_MENU = 3;
const NUMBER_OF_BUTTONS_IN_USER_MENU_WITH_SCHEDULER = 4;

const getNormalizeDate = (date) => moment(date, moment.ISO_8601).toDate();

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  hide: {
    display: 'none',
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  content: {
    position: 'relative',
    display: 'inline-flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginTop: ({ hasZipCode }) => (!hasZipCode ? '261px ' : '185px'),
    [theme.breakpoints.up('sm')]: {
      marginTop: ({ hasZipCode }) => (!hasZipCode ? '201px ' : '133px'),
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10),
    },
  },
  appBar: {
    boxShadow: '0px 5px 30px #0000000D',
    position: 'fixed',
    top: 0,
    background: 'var(--white)',
  },
  top: {
    minHeight: '45px',
    padding: 0,
    background: theme.palette.secondary.main,
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      background: 'var(--white)',
      borderBottom: `1px solid ${theme.palette.secondary.main}`,
    },
  },
  bottom: {
    padding: 0,
  },
  bottomContainer: {
    position: 'relative',
    minHeight: '72px',
    flexWrap: 'nowrap',

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
    },
  },
  bottomContainerMenu: {
    width: '256px',
    overflow: 'hidden',

    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  iconWrapper: {
    display: 'flex',
    gap: '5px',
  },
  logo: {
    flex: '1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '80px',
    overflow: 'hidden',
    padding: '0 16px',
    [theme.breakpoints.down('sm')]: {
      minHeight: '72px',
    },
    '& a': {
      display: 'inline-flex',
      alignItems: 'center',
      gap: '10px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      '& span': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    '& a,img': {
      fontSize: '24px',
      fontWeight: 600,
      color: '#000',
      textDecoration: 'none',
      textTransform: 'uppercase',
      [theme.breakpoints.down('xs')]: {
        fontSize: '18px',
      },
    },
    '& img': {
      height: '48px',
      objectFit: 'contain',
      maxWidth: '48px',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
  },
  longLogo: {
    '& img': {
      maxWidth: '180px',
      height: '48px',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '150px',
        height: '34px',
      },
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },
  },
  link: {
    width: '100%',
    textAlign: 'center',
    textDecoration: 'none',
    opacity: 0.7,
    cursor: 'pointer',
    '& > span': {
      display: 'inline-block',
      borderBottom: '2px solid transparent',
    },
    '&:hover > span': {
      borderBottomColor: 'var(--white)',
    },
  },
  linkSelected: {
    opacity: 1,
    '& > span': {
      borderBottomColor: 'var(--white)',
    },
  },
  mainMenu: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mainMenuItem: {
    fontSize: '12px',
    textTransform: 'uppercase',
    letterSpacing: '1.2px',
    maxWidth: '170px',
    fontWeight: 600,
    color: 'var(--white)',
  },
  mobileMenuItem: {
    textAlign: 'left',
    font: 'bold 15px/28px Montserrat',
    padding: '10px 38px',
  },
  selected: {
    color: theme.palette.primary.main,
    '&:hover': {
      opacity: 1,
    },
  },
  userMenu: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  userMenuItem: {
    color: 'var(--dark-gray)',
    background: 'var(--white)',
    border: '1px solid var(--light-gray)',
    padding: '8px',
    '& svg': {
      height: '18px',
      width: '18px',
    },
    '&:hover': {
      opacity: '0.75',
      background: 'var(--white)',

      [theme.breakpoints.down('sm')]: {
        opacity: '1',
      },
    },
  },
  activeUserMenuItem: {
    '& button': {
      color: theme.palette.primary.main,
    },
  },
  drawerOpener: {
    border: 'none',
    color: 'var(--white)',
    backgroundColor: theme.palette.secondary.main,

    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  userMenuLink: {
    cursor: 'pointer',
    '&:hover': {
      opacity: '0.75',
      background: 'var(--white)',

      [theme.breakpoints.down('sm')]: {
        opacity: '1',
      },
    },
  },
  secondaryMenu: {
    display: 'flex',
    alignItems: 'center',
    '& > a + a': {
      marginLeft: '10px',
    },
  },
  buttonLink: {
    color: 'var(--dark-gray)',
    fontSize: '12px',
    fontWeight: 600,
    border: '1px solid var(--light-gray)',
    borderRadius: '18px',
    background: 'var(--white)',
    display: 'flex',
    alignItems: 'center',
    width: '100px',
    height: '36px',
    textDecoration: 'none',
    padding: theme.spacing(0, 2),
    '& svg': {
      color: theme.palette.primary.main,
    },
    '&:hover': {
      color: 'var(--gray)',
    },
    [theme.breakpoints.down('xs')]: {
      flex: 1,
    },
  },

  buttonSelected: {
    color: theme.palette.primary.main,
    '&:hover': {
      opacity: 1,
      [theme.breakpoints.down('sm')]: {
        color: theme.palette.primary.main,
      },
    },
  },
  bookButton: {
    cursor: 'pointer',
    width: '185px',
    marginLeft: '10px',
  },
  buttonIcon: {
    height: '18px',
    width: '18px',
    marginRight: '5px',
    color: 'var(--gray)',
  },
  buttonHeadIcon: {
    marginRight: '8px',
    display: 'flex',
    alignItems: 'center',
  },
  badge: {
    '& .MuiBadge-badge': {
      background: theme.palette.primary.main,
      border: '1px solid var(--white)',
      color: 'var(--white)',
      fontSize: '9px',
      lineHeight: '14px',
      fontWeight: 700,
      padding: 0,
      height: '14px',
      minWidth: '14px',
    },
  },
  container: {
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
  button: {
    color: 'var(--white)',
    marginTop: theme.spacing(6),
    padding: '10px',
    background: theme.palette.primary.main,
    borderRadius: '18px',
    boxShadow: '0px 2px 15px #1B1B1D1A',
    fontSize: '12px',
    fontWeight: 600,
    letterSpacing: '0px',
    lineHeight: '28px',
    width: '212px',
    height: '36px',
    textTransform: 'none',
    '&:hover': {
      background: 'var(--gray)',
    },
    '& svg': {
      color: 'var(--white)',
      width: 'auto',
      paddingRight: theme.spacing(2),
      paddingLeft: '0',
    },
  },
  searchWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const HideOnScroll = (props) => {
  const { children, window } = props;

  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

HideOnScroll.defaultProps = {
  window: undefined,
};

export const MainLayout = ({ children }, props) => {
  const currentDate = getNormalizeDate(new Date());
  const { showError } = useShowToast();
  const { data: schedulerConfig } = useGetSchedulerConfig();
  const handleCreateSubscriptionAuthLink = useCreateSubscriptionAuthLink();
  const { state } = useContext(Context);
  const helpCenterLink = useMemo(() => state?.config?.helpCenter?.url, [state?.config]);
  const classes = useStyles({ hasZipCode: state?.zipCode });
  const [open, setOpen] = React.useState(false);
  const [openAuth, setOpeningAuth] = React.useState(false);
  const { updateMeShoppingCart, removeMeShoppingCart } = useMeShoppingCart();
  const { pathname } = useLocation();
  const storeFrontSlug = getLocalStoreFrontSlug();
  const accountDetailsLink = ROUTING_CONFIG.accountDetails.replace(
    ':storeFrontSlug',
    storeFrontSlug,
  );

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleAuthOpen = () => {
    setOpeningAuth(true);
  };

  const handleAuthClose = () => {
    setOpeningAuth(false);
    emailService.remove();
  };

  const links = [
    { name: 'Home', to: `/${storeFrontSlug}`, id: 'nav-home' },
    { name: 'Deals', to: `/${storeFrontSlug}/deals`, id: 'nav-deals' },
  ];

  const cartAmount = state.cart.reduce((acc, item) => acc + item.amount, 0);

  const handleUpdateShoppingCart = useCallback(
    debounce((cart) => {
      if (state.account && !isEqual(cart, state?.account?.shoppingCart)) {
        const cartItems = cart?.map((item) => ({
          itemUid: item?.uid,
          qty: item?.amount,
        }));

        if (cartItems?.length) {
          updateMeShoppingCart(cartItems);
        } else {
          removeMeShoppingCart();
        }
      }
    }, 5000),
    [],
  );
  const prepareSchedulerLink = async () => {
    const payload = {
      landingUrl: `${window.location.origin.replace('.', '.scheduler.')}/${
        window.location.pathname.split('/')[1]
      }`,
    };

    if (!state?.account) return payload.landingUrl;

    const response = await handleCreateSubscriptionAuthLink(payload);
    return response?.data?.createSchedulerAuthLink?.url || payload.landingUrl;
  };

  const routeSchedulerLink = async () => {
    try {
      const schedulerLink = await prepareSchedulerLink();
      if (schedulerLink) {
        window.location.href = schedulerLink;
      }
    } catch (error) {
      showError(error);
    }
  };

  useEffect(() => {
    if (state.initialized) {
      handleUpdateShoppingCart(state?.cart);
    }
  }, [state.cart]);

  if (pathname.includes(ROUTING_CONFIG.notFound)) {
    return <>{children}</>;
  }

  return (
    <>
      <HideOnScroll {...props}>
        <AppBar className={classes.appBar}>
          {!state?.zipCode && <AttentionBar />}
          <Toolbar className={classes.top}>
            <Container>
              <Grid className={classes.container} container spacing={0}>
                <Hidden mdUp>
                  <IconButton
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    className={clsx(
                      classes.userMenuItem,
                      classes.drawerOpener,
                      open && classes.hide,
                    )}
                  >
                    <MenuIcon />
                  </IconButton>

                  <Grid
                    className={clsx(
                      classes.logo,
                      state?.config?.general?.longLogoImage ? classes.longLogo : '',
                    )}
                    item
                  >
                    <Link to={`/${storeFrontSlug}`}>
                      <HeaderContent config={state?.config} />
                    </Link>
                  </Grid>

                  <div className={classes.iconWrapper}>
                    {schedulerConfig?.schedulerConfig?.subscriptionExpiryDate !== null &&
                      getNormalizeDate(schedulerConfig?.schedulerConfig?.subscriptionExpiryDate) >=
                        currentDate && (
                        <IconButton
                          className={`${classes.userMenuItem}`}
                          onClick={routeSchedulerLink}
                        >
                          <BookIcon />
                        </IconButton>
                      )}

                    {cartAmount ? (
                      <NavLink
                        id="subnav-cart" // ID need to Google Analytics, task CEP-2317
                        exact
                        to={`/${storeFrontSlug}/cart`}
                        style={{ lineHeight: 0 }}
                        activeClassName={classes.activeUserMenuItem}
                      >
                        <IconButton className={classes.userMenuItem}>
                          <Badge className={classes.badge} badgeContent={cartAmount}>
                            <BasketIcon />
                          </Badge>
                        </IconButton>
                      </NavLink>
                    ) : (
                      // ID need to Google Analytics, task CEP-2317
                      <IconButton className={classes.userMenuItem} id="subnav-cart">
                        <BasketIcon />
                      </IconButton>
                    )}
                  </div>
                </Hidden>
                <Hidden smDown>
                  <Grid className={classes.mainMenu} item>
                    {links.map((item) => (
                      <NavLink
                        id={item?.id} // ID need to Google Analytics, task CEP-2317
                        exact
                        to={item.to}
                        className={`${classes.link} ${classes.mainMenuItem}`}
                        activeClassName={classes.linkSelected}
                        key={item.to}
                      >
                        <span>{item.name}</span>
                      </NavLink>
                    ))}

                    {helpCenterLink && (
                      <a
                        id="nav-support" // ID need to Google Analytics, task CEP-2317
                        href={helpCenterLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`${classes.link} ${classes.mainMenuItem}`}
                      >
                        <span>Support</span>
                      </a>
                    )}
                  </Grid>
                </Hidden>
              </Grid>
            </Container>
          </Toolbar>
          <Toolbar className={classes.bottom}>
            <Container>
              <Grid container spacing={0} className={classes.bottomContainer}>
                <Grid className={`${classes.secondaryMenu} ${classes.bottomContainerMenu}`} item>
                  <NavLink
                    id="subnav-brands" // ID need to Google Analytics, task CEP-2317
                    to={`/${storeFrontSlug}/brands`}
                    className={classes.buttonLink}
                    activeClassName={classes.buttonSelected}
                  >
                    <BrandsIcon className={classes.buttonIcon} />
                    <span>Brands</span>
                  </NavLink>
                  <NavLink
                    id="subnav-categories" // ID need to Google Analytics, task CEP-2317
                    to={`/${storeFrontSlug}/categories`}
                    className={classes.buttonLink}
                    activeClassName={classes.buttonSelected}
                  >
                    <CategoriesIcon className={classes.buttonIcon} />
                    <span>Categories</span>
                  </NavLink>
                </Grid>

                <Hidden smDown>
                  <Grid
                    className={clsx(
                      classes.logo,
                      state?.config?.general?.longLogoImage ? classes.longLogo : '',
                    )}
                    item
                  >
                    <Link to={`/${storeFrontSlug}`}>
                      <HeaderContent config={state?.config} />
                    </Link>
                  </Grid>
                </Hidden>

                <Grid className={`${classes.userMenu} ${classes.bottomContainerMenu}`} item>
                  <SearchPanel
                    numberOfButtonsInUserMenu={
                      schedulerConfig?.schedulerConfig?.subscriptionExpiryDate !== null &&
                      getNormalizeDate(schedulerConfig?.schedulerConfig?.subscriptionExpiryDate) >=
                        currentDate
                        ? NUMBER_OF_BUTTONS_IN_USER_MENU_WITH_SCHEDULER
                        : DEFAULT_NUMBER_OF_BUTTONS_IN_USER_MENU
                    }
                  />
                  <Hidden smDown>
                    {schedulerConfig?.schedulerConfig?.subscriptionExpiryDate !== null &&
                      getNormalizeDate(schedulerConfig?.schedulerConfig?.subscriptionExpiryDate) >=
                        currentDate && (
                        <IconButton className={classes.userMenuItem}>
                          <BookIcon onClick={routeSchedulerLink} className={classes.userMenuLink} />
                        </IconButton>
                      )}
                    {cartAmount ? (
                      <NavLink
                        id="subnav-cart" // ID need to Google Analytics, task CEP-2317
                        exact
                        to={`/${storeFrontSlug}/cart`}
                        style={{ lineHeight: 0 }}
                        activeClassName={classes.activeUserMenuItem}
                      >
                        <IconButton className={classes.userMenuItem}>
                          <Badge className={classes.badge} badgeContent={cartAmount}>
                            <BasketIcon />
                          </Badge>
                        </IconButton>
                      </NavLink>
                    ) : (
                      // ID need to Google Analytics, task CEP-2317
                      <IconButton className={classes.userMenuItem} id="subnav-cart">
                        <BasketIcon />
                      </IconButton>
                    )}

                    {state?.account ? (
                      <NavLink
                        id="subnav-myaccount" // ID need to Google Analytics, task CEP-2317
                        exact
                        to={accountDetailsLink}
                        activeClassName={classes.activeUserMenuItem}
                      >
                        <IconButton className={classes.userMenuItem}>
                          <ProfileIcon />
                        </IconButton>
                      </NavLink>
                    ) : (
                      // ID need to Google Analytics, task CEP-2317
                      <IconButton
                        onClick={handleAuthOpen}
                        className={classes.userMenuItem}
                        id="subnav-myaccount"
                      >
                        <ProfileIcon />
                      </IconButton>
                    )}

                    {state?.account ? (
                      <LogoutButton showOnlyIcon className={classes.userMenuItem} />
                    ) : (
                      <UpdateLocationButton asIcon className={classes.userMenuItem} />
                    )}
                  </Hidden>
                </Grid>
              </Grid>
            </Container>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Hidden mdUp>
        <Drawer
          className={classes.drawer}
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
          keepMounted
        >
          <Box height="100%" display="flex" flexDirection="column" justifyContent="space-between">
            <div className={classes.drawerHeader}>
              <IconButton onClick={handleDrawerClose}>
                <CloseIcon />
              </IconButton>
            </div>

            <List>
              {links.map((item) => (
                <ListItem button onClick={handleDrawerClose} key={item.to}>
                  <NavLink
                    id={item?.id} // ID need to Google Analytics, task CEP-2317
                    exact
                    to={item.to}
                    className={`${classes.link} ${classes.mobileMenuItem}`}
                    activeClassName={classes.linkSelected}
                    key={item.name}
                  >
                    {item.name}
                  </NavLink>
                </ListItem>
              ))}

              {helpCenterLink && (
                <ListItem button onClick={handleDrawerClose}>
                  <a
                    id="nav-support" // ID need to Google Analytics, task CEP-2317
                    href={helpCenterLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`${classes.link} ${classes.mobileMenuItem}`}
                  >
                    <span>Support</span>
                  </a>
                </ListItem>
              )}

              {state?.account ? (
                <ListItem button onClick={handleDrawerClose}>
                  <NavLink
                    id="subnav-myaccount" // ID need to Google Analytics, task CEP-2317
                    exact
                    to={accountDetailsLink}
                    activeClassName={classes.activeUserMenuItem}
                    className={`${classes.link} ${classes.mobileMenuItem}`}
                  >
                    Profile
                  </NavLink>
                </ListItem>
              ) : (
                <ListItem button onClick={handleDrawerClose}>
                  <UpdateLocationButton
                    button={<Box>Update location</Box>}
                    className={`${classes.link} ${classes.mobileMenuItem}`}
                  />
                </ListItem>
              )}
            </List>

            <Hidden mdUp>
              <List>
                {state?.account ? (
                  <ListItem>
                    <Box mb={8} className={`${classes.link} ${classes.mobileMenuItem}`}>
                      <LogoutButton />
                    </Box>
                  </ListItem>
                ) : (
                  <ListItem button onClick={handleDrawerClose}>
                    <Box
                      mb={8}
                      onClick={handleAuthOpen}
                      className={`${classes.link} ${classes.mobileMenuItem}`}
                      id="subnav-myaccount" // ID need to Google Analytics, task CEP-2317
                    >
                      Login
                    </Box>
                  </ListItem>
                )}
              </List>
            </Hidden>
          </Box>
        </Drawer>
      </Hidden>
      <Container maxWidth="lg" className={classes.content}>
        {children}
      </Container>
      <Footer />
      {openAuth && <Auth onClose={handleAuthClose} />}
    </>
  );
};
