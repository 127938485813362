import React from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useTableConfig } from './table-config';
import { TableList } from '../table-list';
import { MobileList } from '../mobile-list';
import { MobileOrderItem } from '../mobile-order-item';
import { Spinner } from '../spinner';
import { ROUTING_CONFIG } from '../../constants';
import { slugService } from '../../services';

export const OrderListComponent = (props) => {
  const theme = useTheme();
  const isSmallResolution = useMediaQuery(theme.breakpoints.down('sm'));
  const tableConfig = useTableConfig();

  if (isSmallResolution) {
    return (
      <MobileList {...tableConfig} listItem={(data) => <MobileOrderItem {...data} />} {...props} />
    );
  }

  return <TableList {...tableConfig} {...props} />;
};

export const OrderList = ({ orders, fetchListMore, hasMoreOrders, isLoading, isError }) => {
  const storeFrontSlug = slugService.slug;

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <OrderListComponent
      data={orders}
      fetchListMore={fetchListMore}
      hasMore={hasMoreOrders}
      isLoading={isLoading}
      isError={isError}
      baseUrl={ROUTING_CONFIG.orderHistoryDetails.replace(':storeFrontSlug', storeFrontSlug)}
    />
  );
};
