import { gql } from '@apollo/client';

export const CREATE_CHECKOUT = gql`
  mutation createCheckout($orderUid: String!, $backLinks: CheckoutBackLinksInput!) {
    createCheckout(backLinks: $backLinks, orderUid: $orderUid) {
      remoteSession {
        id
      }
    }
  }
`;
