import { GA_EVENTS, GA_PAGE_TYPES, GA_LOGIN_STATUSES } from '../../constants';

export const getProductPageForGoogleAnalytics = (window, productName, categoryName, isLoggedIn) => {
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: GA_EVENTS.page_view,
    page_type: GA_PAGE_TYPES.product_page,
    page_name: productName,
    category_hierarchy1: categoryName,
    login_status: isLoggedIn ? GA_LOGIN_STATUSES.loggedIn : GA_LOGIN_STATUSES.guest,
  });
};
