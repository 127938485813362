import React from 'react';
import { toast } from 'react-toastify';
import { Notification } from '../components/error-notification';

export const useShowToast = () => {
  const showError = (message) => toast.error(<Notification title="Oops!" message={message} />);
  const showSuccess = (message) =>
    toast.success(<Notification title="Success!" message={message} />);

  return {
    showError,
    showSuccess,
  };
};
