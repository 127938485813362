import React from 'react';
import { FormikProvider } from 'formik';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { InputTextField } from '../input-text-field';
import { useUpdateLocationForm } from '../../hooks';
import { Button } from '../button';

const useStyles = makeStyles((theme) => ({
  formTitle: {
    color: theme.palette.gray.dark,
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: 'bold',
    margin: '0 0 32px',
  },
  formSubTitle: {
    color: theme.palette.gray.dark,
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 'normal',
    margin: '0 auto 32px',
    maxWidth: '80%',
  },
  input: {
    WebkitBoxShadow: '0 0 0 1000px white inset !important',
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px white inset !important',
    },
    '&:disabled': {
      WebkitBoxShadow: '0 0 0 1000px white inset !important',
    },
  },
  asterisk: {
    color: 'red',
  },
  button: {
    marginTop: '40px',
  },
}));

export const UpdateLocationForm = ({ handleSubmit }) => {
  const classes = useStyles();
  const { formik } = useUpdateLocationForm({ onSubmit: handleSubmit });
  const isError = (field) => formik.touched[field] && !!formik.errors[field];

  return (
    <FormikProvider value={formik}>
      {/* ID need to Google Analytics, task CEP-2317 */}
      <form id="modal-location" onSubmit={formik.handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <p className={classes.formTitle}>Update your location</p>
          </Grid>

          <Grid item xs={12} align="center">
            <p className={classes.formSubTitle}>
              Let us know your zip code so we can display all the products available in your area
            </p>
          </Grid>

          <Grid item xs={12}>
            <InputTextField
              name="zipCode"
              fullWidth
              variant="outlined"
              error={isError('zipCode')}
              helperText={isError('zipCode') && formik?.errors?.zipCode}
              label="Zip Code"
              InputLabelProps={{
                required: true,
                classes: {
                  asterisk: classes.asterisk,
                },
              }}
              inputProps={{ className: classes.input }}
              value={formik?.values?.zipCode}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              type="submit"
              size="medium"
              color="primary"
              className={classes.button}
              variant="contained"
            >
              Update
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormikProvider>
  );
};
