import { LOCAL_STORAGE_KEYS } from '../constants';

class ZipCodeService {
  set zipCode(value) {
    localStorage.setItem(LOCAL_STORAGE_KEYS.zipCode, value);
  }

  get zipCode() {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.zipCode);
  }

  remove() {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.zipCode);
  }
}

export const zipCodeService = new ZipCodeService();
