export const getAddressStr = (addresses) => {
  const address = Array.isArray(addresses) ? addresses?.[0] : addresses;

  if (!address) {
    return '';
  }

  return `${address.streetLine1 ?? ''} ${address.streetLine2 ?? ''} ${address.city ?? ''} ${
    address.stateCode ?? ''
  } ${address.postalCode ?? ''} ${address.countryCode ?? ''}`;
};
