import { ADDRESS_CONFIG } from '../constants';

export const getAddressData = (addressComponents) =>
  addressComponents.reduce((acc, item) => {
    if (item.types.includes(ADDRESS_CONFIG.street_number)) {
      return {
        ...acc,
        street_number: {
          long_name: item.long_name,
          short_name: item.short_name,
        },
      };
    }

    if (item.types.includes(ADDRESS_CONFIG.route)) {
      return {
        ...acc,
        route: {
          long_name: item.long_name,
          short_name: item.short_name,
        },
      };
    }

    if (item.types.includes(ADDRESS_CONFIG.sublocality)) {
      return {
        ...acc,
        sublocality: {
          long_name: item.long_name,
          short_name: item.short_name,
        },
      };
    }

    if (item.types.includes(ADDRESS_CONFIG.locality)) {
      return {
        ...acc,
        locality: {
          long_name: item.long_name,
          short_name: item.short_name,
        },
      };
    }

    if (item.types.includes(ADDRESS_CONFIG.country)) {
      return {
        ...acc,
        country: {
          long_name: item.long_name,
          short_name: item.short_name,
        },
      };
    }

    if (item.types.includes(ADDRESS_CONFIG.postal_code)) {
      return {
        ...acc,
        postal_code: {
          long_name: item.long_name,
          short_name: item.short_name,
        },
      };
    }

    if (item.types.includes(ADDRESS_CONFIG.postal_code_suffix)) {
      return {
        ...acc,
        postal_code_suffix: {
          long_name: item.long_name,
          short_name: item.short_name,
        },
      };
    }

    if (item.types.includes(ADDRESS_CONFIG.administrative_area_level_1)) {
      return {
        ...acc,
        administrative_area_level_1: {
          long_name: item.long_name,
          short_name: item.short_name,
        },
      };
    }

    if (item.types.includes(ADDRESS_CONFIG.administrative_area_level_2)) {
      return {
        ...acc,
        administrative_area_level_2: {
          long_name: item.long_name,
          short_name: item.short_name,
        },
      };
    }

    return acc;
  }, {});
