import { LOCAL_STORAGE_KEYS } from '../constants';

class EmailService {
  set email(value) {
    sessionStorage.setItem(LOCAL_STORAGE_KEYS.email, value);
  }

  get email() {
    return sessionStorage.getItem(LOCAL_STORAGE_KEYS.email);
  }

  remove() {
    sessionStorage.removeItem(LOCAL_STORAGE_KEYS.email);
  }
}

export const emailService = new EmailService();
