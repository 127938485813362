import { gql } from '@apollo/client/core';
import { useMutation } from '@apollo/client';
import { useErrors } from './useErrors';

const RETURN_ORDER_ITEMS = gql`
  mutation returnOrderItems(
    $returnedItems: [ReturnOrderItemInput!]!
    $orderUid: String!
    $reason: OrderItemReturnReasonEnum!
  ) {
    returnOrderItems(returnedItems: $returnedItems, orderUid: $orderUid, reason: $reason) {
      status
    }
  }
`;

export const useReturnOrderItems = () => {
  const [returnOrderItemsMutation, { loading }] = useMutation(RETURN_ORDER_ITEMS);
  const { errorsHandler } = useErrors();

  const returnOrderItems = async ({ returnedItems, orderUid, reason }) => {
    try {
      const response = await returnOrderItemsMutation({
        variables: {
          returnedItems,
          orderUid,
          reason,
        },
      });

      return response;
    } catch (error) {
      errorsHandler(error);
      console.error(error);
    }
  };

  return {
    loading,
    returnOrderItems,
  };
};
