import { getProductImage, getCategoryName, convertSnakeToCamel } from '../utils';
import { IMAGE_SIZE_VALUES } from '../constants';

export const useOrderItems = (orderItems = []) => {
  const items = orderItems?.map((orderItem) => ({
    uid: orderItem?.uid,
    shippingStatus: convertSnakeToCamel(orderItem?.shippingStatus),
    category: getCategoryName(orderItem?.item),
    image: getProductImage(orderItem?.item, IMAGE_SIZE_VALUES.origin),
    trackingNumber: orderItem?.trackingNumber ?? '',
    carrierName: orderItem?.carrierName ?? '',
    availability: orderItem?.availability?.availability,
    name: orderItem?.item?.name,
    sku: orderItem?.item?.sku,
    qty: orderItem?.qty,
    price: orderItem?.price,
    parentUid: orderItem?.parentUid,
    canBeReturned: orderItem?.canBeReturned,
  }));

  return {
    orderItems: items,
  };
};
