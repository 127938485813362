import { gql } from '@apollo/client';

export const SHOPPING_CART_SUMMARY = gql`
  query shoppingCartSummary($cartItems: [CartItemInput!]!, $couponCodeUid: String) {
    shoppingCartSummary(cartItems: $cartItems, couponCodeUid: $couponCodeUid) {
      cartItems {
        qty
        currentPrice
        item {
          uid
          name
          sku
          brand {
            name
          }
          primaryImage {
            thumbUrl
          }
          supplier {
            uid
            name
          }
        }
      }
      cartShippings {
        price
        supplier {
          uid
          name
        }
      }
      subtotal
      totalShipping
      total
      totalDiscount
      discountPercentage
    }
  }
`;
