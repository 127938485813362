import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import { useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';
import { CartEntity } from '../cart-entity';
import { LeftArrowIcon } from '../icons/Icons';

const useStyles = makeStyles((theme) => ({
  slide: { outline: '0!important' },
  item: { outline: '0!important' },
  title: {
    color: 'var(--dark-gray)',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '28px',
    margin: 0,
  },
  carousel: {
    marginTop: '-10px',
    position: 'relative',
    padding: '0 3%',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 6),
    },
  },
  slider: {
    height: '180px',
    paddingTop: '25px',
    outline: 'none',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '10px',
      paddingRight: '10px',
      height: '280px',
    },
  },
  icon: {
    width: '20px',
    height: '20px',
    color: 'var(--light-gray)',
    [theme.breakpoints.up('sm')]: {
      '&:hover': {
        opacity: 0.5,
      },
      width: '26px',
      height: '26px',
    },
  },
  arrowButton: {
    background: 'none',
    padding: 0,
    border: 'none',
    color: 'var(--light-gray)',
    lineHeight: 0,
    position: 'absolute',
    top: '80px',
    outline: 'none',
    [theme.breakpoints.up('md')]: {
      top: '118px',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  back: {
    left: '-10px',
  },
  next: {
    right: '-10px',
    transform: 'rotateZ(180deg)',
  },
}));

const selectSlides = (isMediumResolution, isSmallResolution) => {
  if (isMediumResolution) {
    return {
      visibleSlides: 4,
    };
  }
  if (isSmallResolution) {
    return {
      visibleSlides: 3,
    };
  }
  return {
    visibleSlides: 2,
  };
};

export const CarouselWidget = ({ title, items, type, classNameEntity, ...rest }) => {
  const classes = useStyles();

  const isMediumResolution = useMediaQuery('@media(min-width: 760px)');
  const isSmallResolution = useMediaQuery('@media(min-width: 450px)');

  return (
    <div {...rest}>
      <h2 className={classes.title}>{title}</h2>
      <CarouselProvider
        className={classes.carousel}
        naturalSlideWidth={200}
        naturalSlideHeight={250}
        {...selectSlides(isMediumResolution, isSmallResolution)}
        step={1}
        totalSlides={items.length}
      >
        <Slider className={classes.slider}>
          {items.map((item, index) => (
            <Slide className={classes.slide} key={item.uid} index={index}>
              <CartEntity
                className={clsx(classNameEntity, classes.item)}
                data={item}
                type={type}
                small
              />
            </Slide>
          ))}
        </Slider>
        <ButtonBack className={`${classes.arrowButton} ${classes.back}`}>
          <LeftArrowIcon className={classes.icon} />
        </ButtonBack>
        <ButtonNext className={`${classes.arrowButton} ${classes.next}`}>
          <LeftArrowIcon className={classes.icon} />
        </ButtonNext>
      </CarouselProvider>
    </div>
  );
};
