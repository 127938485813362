import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardMedia } from '@material-ui/core';
import NoImage from '../../assets/no-image.png';
import { getLocalStoreFrontSlug } from '../../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
  },
  linkContainer: () => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      '&:hover > *:first-child': {
        transform: 'scale(1.05)',
      },
    },
  }),
  card: (props) => ({
    maxWidth: '130px',
    borderRadius: '50%',
    width: '100%',
    boxShadow: '0px 0px 10px #1B1B1D26',
    transition: 'transform .15s',
    [theme.breakpoints.up('md')]: {
      maxWidth: props.size,
      '&:hover': {
        transform: 'scale(1.02)',
      },
      '&:hover > *': {
        transform: 'scale(1.025)',
      },
      '& > *': {
        transition: 'transform .15s',
      },
    },
  }),
  media: (props) => ({
    height: '130px',
    [theme.breakpoints.up('md')]: {
      height: props.size,
    },
  }),
  title: (props) => ({
    fontSize: props.fontSize,
    lineHeight: '28px',
    color: 'var(--dark-gray)',
    textTransform: 'capitalize',
    fontWeight: 700,
    margin: '20px 0 0',
    textAlign: 'center',
  }),
  truncate: (props) => ({
    maxWidth: props.size,
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
}));

export const CartEntity = ({ data, small, className, type, ...rest }) => {
  const props = {
    size: small ? '200px' : '240px',
    fontSize: small ? '15px' : '20px',
  };
  const classes = useStyles(props);
  const storeFrontSlug = getLocalStoreFrontSlug();

  return (
    <div className={clsx(classes.root, { [className]: Boolean(className) })} {...rest}>
      <Link className={classes.linkContainer} to={`/${storeFrontSlug}/${type}/${data.slug}`}>
        <Card className={classes.card}>
          <CardMedia
            className={classes.media}
            image={data?.coverImage?.mediumUrl ?? NoImage}
            title={data?.name || ''}
          />
        </Card>
        <h3 className={`${classes.title} ${classes.truncate}`}>{data?.name || ''}</h3>
      </Link>
    </div>
  );
};
