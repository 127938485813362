export const ADDRESS_CONFIG = {
  street_number: 'street_number',
  route: 'route',
  sublocality: 'sublocality',
  locality: 'locality',
  administrative_area_level_1: 'administrative_area_level_1',
  administrative_area_level_2: 'administrative_area_level_2',
  country: 'country',
  postal_code: 'postal_code',
  postal_code_suffix: 'postal_code_suffix',
};
