import React, { useContext, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Context } from '../store';
import { useCategory } from '../hooks';
import { ROUTING_CONFIG } from '../constants';
import { sessionCacheStorage } from '../services';
import { InfiniteScrollGrid, ProductsItem } from '../components';
import NoImage from '../assets/no-image.png';
import {
  getLocalStoreFrontSlug,
  getStateByZipCode,
  getCategoryPageForGoogleAnalytics,
} from '../utils';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '25px',
    lineHeight: '28px',
    color: 'var(--dark-gray)',
    fontWeight: 700,
    textTransform: 'capitalize',
    marginBottom: theme.spacing(2),
    overflowWrap: 'break-word',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
      fontSize: '29px',
      lineHeight: '29px',
    },
  },
  subTitle: {
    fontSize: '18px',
    lineHeight: '21px',
    color: 'var(--dark-gray)',
    fontWeight: 700,
    textTransform: 'capitalize',
    margin: 0,
    paddingLeft: theme.spacing(2),
  },
  categoryContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  category: {
    fontSize: '10px',
    color: 'var(--gray)',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0.6px',
    textTransform: 'uppercase',
  },
  image: {
    maxWidth: '150px',
    borderRadius: '50%',
    width: '100%',
    boxShadow: '0px 0px 10px #1B1B1D26',
    transition: 'transform .15s',
    [theme.breakpoints.up('md')]: {
      '&:hover': {
        transform: 'scale(1.02)',
      },
      '&:hover > *': {
        transform: 'scale(1.025)',
      },
      '& > *': {
        transition: 'transform .15s',
      },
    },
  },
}));

export const CategoryPage = () => {
  const { state } = useContext(Context);
  const { categorySlug } = useParams();
  const storeFrontSlug = getLocalStoreFrontSlug();
  const { replace } = useHistory();
  const classes = useStyles();
  const zipCode = state?.zipCode ?? '';
  const stateCode = getStateByZipCode(zipCode);
  const { relatedCategories, products, category, isLoading, isError, hasMore, fetchListMore } =
    useCategory({
      filter: {
        categoriesSlugEq: categorySlug,
        ...(zipCode
          ? {
              uidOmitsItemRestrictionsIn: [zipCode, stateCode?.shortName],
              brandUidOmitsBrandRestrictionsIn: [zipCode, stateCode?.shortName],
            }
          : {}),
      },
      categorySlug,
    });

  const categoryName = category?.name ?? '';

  useEffect(() => {
    if (category && !isLoading && !isError) {
      getCategoryPageForGoogleAnalytics(window, categoryName, sessionCacheStorage.hasToken);
    }
  }, [isLoading]);

  if (!isLoading && !category) {
    replace(ROUTING_CONFIG.categories.replace(':storeFrontSlug', storeFrontSlug));
  }

  const title = () => (
    <Grid container spacing={1}>
      <Grid item xs={12} md={3} align="center">
        <img
          className={classes.image}
          src={category?.coverImage?.url || NoImage}
          alt={category?.name}
        />
      </Grid>
      <Grid container xs={12} md={9} alignItems="center">
        <Grid item xs={12}>
          <h1 className={classes.title}>{categoryName}</h1>
          <div className={classes.categoryContainer}>
            {relatedCategories?.map((category, index) => (
              <Link key={category.uid} to={`/${storeFrontSlug}/categories/${category.slug}`}>
                {category.name} {index !== relatedCategories.length - 1 && <>&bull;</>}
                <>&nbsp;</>
              </Link>
            ))}
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
  const subTitle = () => <h2 className={classes.subTitle}>All Products</h2>;

  return (
    <InfiniteScrollGrid
      itemComponent={ProductsItem}
      customTitle={title()}
      title={categoryName}
      subTitle={subTitle()}
      data={products}
      isLoading={isLoading}
      isError={isError}
      fetchListMore={fetchListMore}
      hasMore={hasMore}
    />
  );
};
