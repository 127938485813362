import { useMutation } from '@apollo/client';
import { useErrors } from './useErrors';
import { CREATE_ADDRESS } from '../mutations/createAddressMutation';

export const useCreateAddress = () => {
  const [createAddressMutation] = useMutation(CREATE_ADDRESS);
  const { errorsHandler } = useErrors();

  const createAddress = async ({ accountUid, values }) => {
    try {
      const response = await createAddressMutation({
        variables: {
          accountUid,
          input: {
            type: 'shipping',
            streetLine1: values.addressLine1,
            streetLine2: values.addressLine2,
            city: values.city,
            stateCode: values.state,
            postalCode: values.zipCode,
            countryCode: values.country,
          },
        },
      });

      return response?.data?.createAddress ?? null;
    } catch (error) {
      errorsHandler(error);
      return null;
    }
  };

  return {
    createAddress,
  };
};
