import React from 'react';
import { ResponseStateLayout } from '../response-state-layout';
import EmptyImage from '../../assets/empty.svg';

export const EmptyState = () => (
  <ResponseStateLayout
    image={EmptyImage}
    title="No items here"
    subTitle="Try To Choose Something Else"
  />
);
