import React, { useEffect, useContext, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { Context } from '../../store';
import { useUpdateAccount } from '../../hooks';
import { BUTTON_COLORS_TYPES, BUTTON_SIZES_TYPES } from '../../constants';
import { Spinner } from '../../components/spinner';
import { ShippingForm, Button } from '../../components';
import { getAccountSettingsPageForGoogleAnalytics } from '../../utils';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(7),
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    },
  },
  buttonsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: '60px',
    '& > :last-child': {
      marginLeft: '40px',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '50px',
    },
  },
}));

export const AccountSettings = () => {
  const classes = useStyles();
  const formRef = useRef();
  const { state } = useContext(Context);
  const { loading, updateAccount } = useUpdateAccount();
  const [isErrorValidation, setErrorValidation] = useState(true);
  const account = state?.account;

  const handleCancel = () => {
    formRef?.current?.handleReset();
  };

  const handleSubmit = () => {
    formRef?.current?.handleSubmit();
  };

  const onSubmit = (data) => {
    updateAccount(data);
  };

  useEffect(() => {
    if (!!account) {
      getAccountSettingsPageForGoogleAnalytics(window);
    }
  }, [account]);

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} sm={12} md={12}>
        {loading && <Spinner isBackground />}

        <Box pl={{ md: 10 }} pr={{ md: 10 }} className={classes.content}>
          <ShippingForm
            formRef={formRef}
            onSubmit={onSubmit}
            checkError={setErrorValidation}
            account={account}
          />

          <div className={classes.buttonsWrapper}>
            <Button
              size={BUTTON_SIZES_TYPES.action}
              color={BUTTON_COLORS_TYPES.default}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              disabled={!isErrorValidation}
              size={BUTTON_SIZES_TYPES.action}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </div>
        </Box>
      </Grid>
    </Grid>
  );
};
