import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';
import { Integrations } from '@sentry/tracing';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const history = createBrowserHistory();

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  });
}

// TODO fallback UI? probably shouldn't use top level boundary (instead use page/route-level), temporary solution
const Application =
  process.env.NODE_ENV === 'production'
    ? Sentry.withProfiler(Sentry.withErrorBoundary(App, { fallback: 'An error has occurred' }))
    : App;

ReactDOM.render(
  <React.StrictMode>
    <Application />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
