export const getStateByZipCode = (zipString) => {
  /* Ensure param is a string to prevent unpredictable parsing results */
  if (typeof zipString !== 'string') {
    return;
  }

  /* Ensure we have exactly 5 characters to parse */
  if (zipString.length !== 5) {
    return;
  }

  /* Ensure we don't parse strings starting with 0 as octal values */
  const zipcode = parseInt(zipString, 10);

  let shortName;
  let fullName;

  /* Code cases alphabetized by state */
  if (zipcode >= 35000 && zipcode <= 36999) {
    shortName = 'AL';
    fullName = 'Alabama';
  } else if (zipcode >= 99500 && zipcode <= 99999) {
    shortName = 'AK';
    fullName = 'Alaska';
  } else if (zipcode >= 85000 && zipcode <= 86999) {
    shortName = 'AZ';
    fullName = 'Arizona';
  } else if (zipcode >= 71600 && zipcode <= 72999) {
    shortName = 'AR';
    fullName = 'Arkansas';
  } else if (zipcode >= 90000 && zipcode <= 96699) {
    shortName = 'CA';
    fullName = 'California';
  } else if (zipcode >= 80000 && zipcode <= 81999) {
    shortName = 'CO';
    fullName = 'Colorado';
  } else if ((zipcode >= 6000 && zipcode <= 6389) || (zipcode >= 6391 && zipcode <= 6999)) {
    shortName = 'CT';
    fullName = 'Connecticut';
  } else if (zipcode >= 19700 && zipcode <= 19999) {
    shortName = 'DE';
    fullName = 'Delaware';
  } else if (zipcode >= 32000 && zipcode <= 34999) {
    shortName = 'FL';
    fullName = 'Florida';
  } else if ((zipcode >= 30000 && zipcode <= 31999) || (zipcode >= 39800 && zipcode <= 39999)) {
    shortName = 'GA';
    fullName = 'Georgia';
  } else if (zipcode >= 96700 && zipcode <= 96999) {
    shortName = 'HI';
    fullName = 'Hawaii';
  } else if (zipcode >= 83200 && zipcode <= 83999) {
    shortName = 'ID';
    fullName = 'Idaho';
  } else if (zipcode >= 60000 && zipcode <= 62999) {
    shortName = 'IL';
    fullName = 'Illinois';
  } else if (zipcode >= 46000 && zipcode <= 47999) {
    shortName = 'IN';
    fullName = 'Indiana';
  } else if (zipcode >= 50000 && zipcode <= 52999) {
    shortName = 'IA';
    fullName = 'Iowa';
  } else if (zipcode >= 66000 && zipcode <= 67999) {
    shortName = 'KS';
    fullName = 'Kansas';
  } else if (zipcode >= 40000 && zipcode <= 42999) {
    shortName = 'KY';
    fullName = 'Kentucky';
  } else if (zipcode >= 70000 && zipcode <= 71599) {
    shortName = 'LA';
    fullName = 'Louisiana';
  } else if (zipcode >= 3900 && zipcode <= 4999) {
    shortName = 'ME';
    fullName = 'Maine';
  } else if (zipcode >= 20600 && zipcode <= 21999) {
    shortName = 'MD';
    fullName = 'Maryland';
  } else if ((zipcode >= 1000 && zipcode <= 2799) || zipcode === 5501 || zipcode === 5544) {
    shortName = 'MA';
    fullName = 'Massachusetts';
  } else if (zipcode >= 48000 && zipcode <= 49999) {
    shortName = 'MI';
    fullName = 'Michigan';
  } else if (zipcode >= 55000 && zipcode <= 56899) {
    shortName = 'MN';
    fullName = 'Minnesota';
  } else if (zipcode >= 38600 && zipcode <= 39999) {
    shortName = 'MS';
    fullName = 'Mississippi';
  } else if (zipcode >= 63000 && zipcode <= 65999) {
    shortName = 'MO';
    fullName = 'Missouri';
  } else if (zipcode >= 59000 && zipcode <= 59999) {
    shortName = 'MT';
    fullName = 'Montana';
  } else if (zipcode >= 27000 && zipcode <= 28999) {
    shortName = 'NC';
    fullName = 'North Carolina';
  } else if (zipcode >= 58000 && zipcode <= 58999) {
    shortName = 'ND';
    fullName = 'North Dakota';
  } else if (zipcode >= 68000 && zipcode <= 69999) {
    shortName = 'NE';
    fullName = 'Nebraska';
  } else if (zipcode >= 88900 && zipcode <= 89999) {
    shortName = 'NV';
    fullName = 'Nevada';
  } else if (zipcode >= 3000 && zipcode <= 3899) {
    shortName = 'NH';
    fullName = 'New Hampshire';
  } else if (zipcode >= 7000 && zipcode <= 8999) {
    shortName = 'NJ';
    fullName = 'New Jersey';
  } else if (zipcode >= 87000 && zipcode <= 88499) {
    shortName = 'NM';
    fullName = 'New Mexico';
  } else if (
    (zipcode >= 10000 && zipcode <= 14999) ||
    zipcode === 6390 ||
    zipcode === 501 ||
    zipcode === 544
  ) {
    shortName = 'NY';
    fullName = 'New York';
  } else if (zipcode >= 43000 && zipcode <= 45999) {
    shortName = 'OH';
    fullName = 'Ohio';
  } else if ((zipcode >= 73000 && zipcode <= 73199) || (zipcode >= 73400 && zipcode <= 74999)) {
    shortName = 'OK';
    fullName = 'Oklahoma';
  } else if (zipcode >= 97000 && zipcode <= 97999) {
    shortName = 'OR';
    fullName = 'Oregon';
  } else if (zipcode >= 15000 && zipcode <= 19699) {
    shortName = 'PA';
    fullName = 'Pennsylvania';
  } else if (zipcode >= 300 && zipcode <= 999) {
    shortName = 'PR';
    fullName = 'Puerto Rico';
  } else if (zipcode >= 2800 && zipcode <= 2999) {
    shortName = 'RI';
    fullName = 'Rhode Island';
  } else if (zipcode >= 29000 && zipcode <= 29999) {
    shortName = 'SC';
    fullName = 'South Carolina';
  } else if (zipcode >= 57000 && zipcode <= 57999) {
    shortName = 'SD';
    fullName = 'South Dakota';
  } else if (zipcode >= 37000 && zipcode <= 38599) {
    shortName = 'TN';
    fullName = 'Tennessee';
  } else if (
    (zipcode >= 75000 && zipcode <= 79999) ||
    (zipcode >= 73301 && zipcode <= 73399) ||
    (zipcode >= 88500 && zipcode <= 88599)
  ) {
    shortName = 'TX';
    fullName = 'Texas';
  } else if (zipcode >= 84000 && zipcode <= 84999) {
    shortName = 'UT';
    fullName = 'Utah';
  } else if (zipcode >= 5000 && zipcode <= 5999) {
    shortName = 'VT';
    fullName = 'Vermont';
  } else if (
    (zipcode >= 20100 && zipcode <= 20199) ||
    (zipcode >= 22000 && zipcode <= 24699) ||
    zipcode === 20598
  ) {
    shortName = 'VA';
    fullName = 'Virginia';
  } else if (
    (zipcode >= 20000 && zipcode <= 20099) ||
    (zipcode >= 20200 && zipcode <= 20599) ||
    (zipcode >= 56900 && zipcode <= 56999)
  ) {
    shortName = 'DC';
    fullName = 'Washington DC';
  } else if (zipcode >= 98000 && zipcode <= 99499) {
    shortName = 'WA';
    fullName = 'Washington';
  } else if (zipcode >= 24700 && zipcode <= 26999) {
    shortName = 'WV';
    fullName = 'West Virginia';
  } else if (zipcode >= 53000 && zipcode <= 54999) {
    shortName = 'WI';
    fullName = 'Wisconsin';
  } else if (zipcode >= 82000 && zipcode <= 83199) {
    shortName = 'WY';
    fullName = 'Wyoming';
  } else {
    shortName = '';
    fullName = '';
  }

  return {
    shortName,
    fullName,
  };
};
