/* eslint-disable camelcase */
import { geocodeByAddress } from 'react-places-autocomplete';
import { useErrors } from './useErrors';

export const useGeocodeByAddress = () => {
  const { errorsHandler } = useErrors();

  const getGeocodeByAddress = async (address) => {
    try {
      const response = await geocodeByAddress(address);

      if (response?.length) {
        const { address_components, formatted_address, geometry, place_id, types } = response[0];

        return {
          addressComponents: address_components,
          formattedAddress: formatted_address,
          placeId: place_id,
          geometry,
          types,
        };
      }
    } catch (error) {
      errorsHandler(error);
    }
  };

  return {
    getGeocodeByAddress,
  };
};
