import { gql } from '@apollo/client';

export const LIST_CATEGORIES = gql`
  query listCategories($filter: CategoriesFilterInput) {
    categories(filter: $filter) {
      nodes {
        uid
        name
        slug
        coverImage {
          url
          mediumUrl
          thumbUrl
        }
      }
    }
  }
`;
