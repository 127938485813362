import { displayCentsAsDollars } from './display-cents-as-dollars';

export const getOrderSubTotal = (order, params = {}) => {
  const amount = (order?.orderItems ?? []).reduce((p, c) => {
    if (c.qtyCanceled) {
      return p + c.qtyCanceled * c.price;
    }

    return p + c.qty * c.price;
  }, 0);

  return displayCentsAsDollars(amount, params);
};
