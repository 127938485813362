import { PER_PAGE, RESPONSE_TYPES } from '../constants';
import { usePaginatedList } from './usePaginatedList';
import { ORDERS } from '../queries';

export const useOrderList = ({ withQueryString } = {}) => {
  const { data, loading, error, page, fetchListMore } = usePaginatedList(ORDERS, {
    typeData: RESPONSE_TYPES.orders,
    withQueryString,
  });

  return {
    isLoading: loading,
    isError: error,
    orders: data?.list,
    fetchListMore,
    hasMoreOrders: page * PER_PAGE < data?.pagination?.totalNodes,
  };
};
