import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  spinner: {
    color: theme.palette.primary.main,
  },
  spinnerWrapper: {
    position: ({ fixed }) => (fixed ? 'fixed' : 'absolute'),
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10001,
    background: (props) => (props.isBackground ? 'rgba(255, 255, 255, 0.5)' : 'transparent'),
  },
}));

export const Spinner = ({ className, isBackground = false, fixed = false }) => {
  const classes = useStyles({ isBackground, fixed });

  return (
    <div className={`${classes.spinnerWrapper} ${className}`}>
      <CircularProgress className={classes.spinner} />
    </div>
  );
};
