import * as yup from 'yup';
import { MATCH_NAME } from '../../constants';

export const formSchema = yup.object().shape({
  name: yup
    .string()
    .min(3, 'Invalid name')
    .matches(MATCH_NAME, {
      excludeEmptyString: true,
      message: 'Invalid name',
    })
    .trim()
    .required('Required'),
  email: yup.string().email('Invalid email').required('Required'),
});
