import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const BrandsIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 18 18">
    <path d="M16.916,7.067l-.022-.061,0-.005a1.565,1.565,0,0,0-1.508-1.045H11.689L10.527,2.425A1.581,1.581,0,0,0,9.011,1.317a.261.261,0,0,0-.028,0A1.578,1.578,0,0,0,7.466,2.435L6.335,5.955H2.607A1.608,1.608,0,0,0,1,7.558a1.615,1.615,0,0,0,.671,1.313l3.014,2.177-1.16,3.517a1.577,1.577,0,0,0,.58,1.8,1.583,1.583,0,0,0,1.894,0l3.014-2.182,2.982,2.177a1.606,1.606,0,0,0,2.247-.355,1.616,1.616,0,0,0,.234-1.427L13.345,11.05,16.33,8.87A1.582,1.582,0,0,0,16.916,7.067Zm-1.865,1L11.7,10.514l1.437,4.5a.21.21,0,0,1-.134.261.214.214,0,0,1-.186-.03l-3.8-2.783L5.173,15.237a.187.187,0,0,1-.154.037.2.2,0,0,1-.134-.084.208.208,0,0,1-.03-.185l1.481-4.491L2.493,7.732A.2.2,0,0,1,2.41,7.6a.207.207,0,0,1,.2-.24H7.36L8.8,2.857a.2.2,0,0,1,.2-.138H9a.237.237,0,0,1,.192.143l1.48,4.495h4.707a.208.208,0,0,1,.122.375l-.453.331Z" />
    <path d="M9,11.439a1.976,1.976,0,1,1,1.975-1.976A1.978,1.978,0,0,1,9,11.439ZM9,8.476a.988.988,0,1,0,.987.987A.988.988,0,0,0,9,8.476Z" />
  </SvgIcon>
);

export const CategoriesIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 18 18">
    <path d="M4.5,7.8A3.3,3.3,0,1,1,7.8,4.5,3.3,3.3,0,0,1,4.5,7.8Zm0-5A1.7,1.7,0,1,0,6.2,4.5,1.7,1.7,0,0,0,4.5,2.8Z" />
    <path d="M13.5,7.8a3.3,3.3,0,1,1,3.3-3.3A3.3,3.3,0,0,1,13.5,7.8Zm0-5a1.7,1.7,0,1,0,1.7,1.7A1.7,1.7,0,0,0,13.5,2.8Z" />
    <path d="M4.5,16.8a3.3,3.3,0,1,1,3.3-3.3A3.3,3.3,0,0,1,4.5,16.8Zm0-5a1.7,1.7,0,1,0,1.7,1.7A1.7,1.7,0,0,0,4.5,11.8Z" />
    <path d="M13.5,16.8a3.3,3.3,0,1,1,3.3-3.3A3.3,3.3,0,0,1,13.5,16.8Zm0-5a1.7,1.7,0,1,0,1.7,1.7A1.7,1.7,0,0,0,13.5,11.8Z" />
  </SvgIcon>
);

export const BookIcon = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M4.66446 1.50853C4.66446 1.22745 4.89053 1 5.16991 1C5.31404 1 5.44385 1.06059 5.53616 1.15792C5.62254 1.2493 5.67535 1.37245 5.67535 1.50853V2.36567H8.70801V1.50853C8.70801 1.22745 8.93408 1 9.21345 1C9.49283 1 9.7189 1.22745 9.7189 1.50853V2.36567H12.7516V1.50853C12.7516 1.22745 12.9776 1 13.257 1C13.4219 1 13.568 1.07946 13.6603 1.20162C13.7244 1.28704 13.7624 1.39331 13.7624 1.50853V2.36567H14.8859C16.2872 2.36567 17.4269 3.51184 17.4269 4.9222V9.94388C17.4269 10.224 17.2008 10.4524 16.9215 10.4524C16.6421 10.4524 16.416 10.224 16.416 9.94388V4.9222C16.416 4.073 15.7294 3.38272 14.8859 3.38272H13.7624V4.23887C13.7624 4.51995 13.5364 4.74739 13.257 4.74739C12.9776 4.74739 12.7516 4.51995 12.7516 4.23887V3.38272H5.67535V4.23887C5.67535 4.37494 5.62204 4.49909 5.53517 4.59047C5.44336 4.68681 5.31354 4.74739 5.16991 4.74739C4.89053 4.74739 4.66446 4.51995 4.66446 4.23887V3.38272H3.54153C2.69748 3.38272 2.01089 4.073 2.01089 4.9222V14.9656C2.01089 15.8138 2.69748 16.505 3.54153 16.505L10.1542 16.507C10.3319 16.507 10.4879 16.5984 10.5778 16.7375C10.6296 16.8169 10.6597 16.9123 10.6597 17.0146C10.6597 17.2947 10.4336 17.5221 10.1542 17.5221H3.54153C2.14021 17.5221 1 16.3749 1 14.9656V4.9222C1 3.51184 2.14021 2.36567 3.54153 2.36567H4.66446V1.50853Z"
      fill="#232429"
    />
    <path
      d="M5.09784 9.36981H13.2916C13.4426 9.36981 13.5783 9.30326 13.6711 9.19699C13.7496 9.1076 13.797 8.9904 13.797 8.86128C13.797 8.5802 13.5709 8.35276 13.2916 8.35276H5.09784C4.81847 8.35276 4.5924 8.5802 4.5924 8.86128C4.5924 9.14236 4.81847 9.36981 5.09784 9.36981Z"
      fill="#232429"
    />
    <path
      d="M9.7189 12.3137C9.7189 12.0316 9.49283 11.8052 9.21345 11.8052H5.09784C4.81847 11.8052 4.5924 12.0316 4.5924 12.3137C4.5924 12.5938 4.81847 12.8222 5.09784 12.8222H9.21345C9.3413 12.8222 9.45828 12.7745 9.54762 12.6961C9.65275 12.6027 9.7189 12.4657 9.7189 12.3137Z"
      fill="#232429"
    />
    <path
      d="M15.5784 14.6458H17.0661C17.2783 14.6458 17.4501 14.8186 17.4501 15.0321C17.4501 15.1324 17.4121 15.2238 17.3494 15.2923C17.2793 15.3688 17.1786 15.4175 17.0661 15.4175H15.0675C14.9466 15.4175 14.8331 15.3609 14.7605 15.2635C14.6879 15.1672 14.6652 15.0411 14.6988 14.9239L15.2951 12.851C15.3538 12.6464 15.569 12.5272 15.7704 12.5878C15.9743 12.6474 16.0917 12.861 16.0325 13.0665L15.5784 14.6458Z"
      fill="#232429"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1227 15.0381C11.1227 12.853 12.8898 11.0752 15.0616 11.0752C17.2329 11.0752 19 12.853 19 15.0381C19 17.2231 17.2329 19 15.0616 19C12.8898 19 11.1227 17.2231 11.1227 15.0381ZM11.8902 15.0381C11.8902 16.7971 13.3128 18.2283 15.0616 18.2283C16.8099 18.2283 18.232 16.7971 18.232 15.0381C18.232 13.2791 16.8099 11.8479 15.0616 11.8479C13.3128 11.8479 11.8902 13.2791 11.8902 15.0381Z"
      fill="#232429"
    />
  </svg>
);

export const BasketIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 32 32">
    <path d="M10.834,20.175H26.287a.981.981,0,0,0,.936-.7L30.629,7.7a.97.97,0,0,0-.161-.855.992.992,0,0,0-.784-.386H8.973L8.387,3.853a.976.976,0,0,0-.955-.762H2.313a.973.973,0,1,0,0,1.946H6.637L9.671,18.494A2.65,2.65,0,0,0,10.8,23.541a.223.223,0,0,0,.037,0h.569a2.816,2.816,0,1,0,2.339,0h9.361a2.817,2.817,0,1,0,2.339,0h.838a.973.973,0,1,0,0-1.946H10.834a.709.709,0,1,1,0-1.418Zm13.439,5.154h0a.762.762,0,0,1,.539,1.3.752.752,0,0,1-.539.225h0a.765.765,0,0,1-.761-.765A.763.763,0,0,1,24.273,25.329Zm-11.7,0h0a.764.764,0,1,1-.762.762A.764.764,0,0,1,12.574,25.329ZM9.41,8.4H28.38l-2.843,9.831H11.625Z" />
  </SvgIcon>
);

export const SearchIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 18 18">
    <path d="M15.782,14.956l-3.45-3.589A5.891,5.891,0,1,0,11.206,12.4l3.479,3.615a.765.765,0,1,0,1.1-1.063ZM7.852,3.279A4.327,4.327,0,1,1,3.526,7.605h0A4.331,4.331,0,0,1,7.852,3.279Z" />
  </SvgIcon>
);

export const ProfileIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <g transform="translate(-61.178 -45.945)">
      <path
        d="M176.146,52.59c-.187,2.528-2.1,4.59-4.207,4.59s-4.023-2.062-4.207-4.59a4.223,4.223,0,1,1,8.415,0Z"
        transform="translate(-98.761)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M73.177,304c-4.16,0-8.381,2.295-9.162,6.627a.8.8,0,0,0,.748,1.023H81.592a.8.8,0,0,0,.748-1.023C81.559,306.295,77.337,304,73.177,304Z"
        transform="translate(0 -243.76)"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </g>
    <rect width="24" height="24" fill="none" />
  </SvgIcon>
);

export const YoutubeIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 35 36">
    <g transform="translate(7.896 10.774)">
      <path
        d="M26.469,27.817H13.228A3.231,3.231,0,0,1,10,24.59v-8.1a3.231,3.231,0,0,1,3.228-3.227H26.469A3.231,3.231,0,0,1,29.7,16.491v8.1A3.231,3.231,0,0,1,26.469,27.817ZM13.228,14.577a1.916,1.916,0,0,0-1.915,1.914v8.1A1.916,1.916,0,0,0,13.228,26.5H26.469a1.916,1.916,0,0,0,1.915-1.914v-8.1a1.916,1.916,0,0,0-1.915-1.914Z"
        transform="translate(-10 -13.264)"
      />
      <g transform="translate(7.222 3.858)">
        <path
          d="M19.823,24.588a.664.664,0,0,1-.338-.093.657.657,0,0,1-.318-.563V18.816a.657.657,0,0,1,.964-.581l4.815,2.544a.657.657,0,0,1,0,1.16l-4.815,2.572A.653.653,0,0,1,19.823,24.588Zm.656-4.683v2.932l2.759-1.473Z"
          transform="translate(-19.167 -18.161)"
        />
      </g>
    </g>
  </SvgIcon>
);

export const InstagramIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 36 36">
    <g transform="translate(7.912 8.236)">
      <g transform="translate(4.887 4.887)">
        <path
          d="M21.169,26.093a4.924,4.924,0,1,1,4.924-4.924A4.929,4.929,0,0,1,21.169,26.093Zm0-8.535a3.611,3.611,0,1,0,3.612,3.611A3.615,3.615,0,0,0,21.169,17.558Z"
          transform="translate(-16.245 -16.245)"
        />
      </g>
      <path
        d="M26.51,29.739H13.27a3.232,3.232,0,0,1-3.228-3.228V13.27a3.232,3.232,0,0,1,3.228-3.228H26.51a3.232,3.232,0,0,1,3.229,3.228V26.511A3.232,3.232,0,0,1,26.51,29.739ZM13.27,11.355a1.917,1.917,0,0,0-1.915,1.915V26.511a1.917,1.917,0,0,0,1.915,1.915H26.51a1.917,1.917,0,0,0,1.915-1.915V13.27a1.917,1.917,0,0,0-1.915-1.915Z"
        transform="translate(-10.042 -10.042)"
      />
      <circle cx="1.277" cy="1.277" r="1.277" transform="translate(13.97 3.137)" />
    </g>
  </SvgIcon>
);

export const FacebookIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 35 36">
    <g transform="translate(12.798 8.198)">
      <path
        d="M22.836,29.7H18.253V21.062H15.989V16.687H18.3l.106-2.433A4.43,4.43,0,0,1,22.847,10h.061l3.354.045-.1,4.3H23.3a.464.464,0,0,0-.464.464v1.881h3.426l-.336,4.375H22.836ZM19.459,28.49H21.63V19.856h3.178l.15-1.963H21.63V14.806a1.671,1.671,0,0,1,1.669-1.67h1.686l.043-1.9-2.137-.028h-.044a3.228,3.228,0,0,0-3.24,3.1l-.158,3.586H17.2v1.963h2.264Z"
        transform="translate(-15.989 -10)"
      />
    </g>
  </SvgIcon>
);

export const LeftArrowIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 26 26">
    <g transform="translate(6 2)">
      <path
        d="M12.547,22.147a1.476,1.476,0,0,1-.959-.354L.522,12.306a1.475,1.475,0,0,1,0-2.213L11.573.384A1.475,1.475,0,0,1,13.521,2.6l-9.79,8.572,9.775,8.381a1.475,1.475,0,0,1-.959,2.6Z"
        transform="translate(-0.023 -0.058)"
      />
    </g>
  </SvgIcon>
);

export const CloseIcon = (props) => (
  <SvgIcon {...props} width="20" height="20" viewBox="0 0 20 20">
    <g id="Mask_Group_3" data-name="Mask Group 3" transform="translate(-67 -1662)">
      <g id="Group_1443" data-name="Group 1443" transform="translate(550.932 1924.891) rotate(135)">
        <path
          id="Union_2"
          data-name="Union 2"
          d="M6.521,14.023V8.479H.978a.978.978,0,1,1,0-1.957H6.521V.978a.978.978,0,0,1,1.957,0V6.522h5.544a.978.978,0,1,1,0,1.957H8.478v5.544a.978.978,0,0,1-1.957,0Z"
          transform="translate(148.651 506.848)"
          fill="#939393"
        />
      </g>
    </g>
  </SvgIcon>
);

export const CheckMarkIcon = (props) => (
  <SvgIcon {...props} width="41" height="41" viewBox="0 0 41 41">
    <path
      d="M40.4355 20.9923C40.4355 9.95064 31.4772 0.99231 20.4355 0.99231C9.39388 0.99231 0.435547 9.95064 0.435547 20.9923C0.435547 32.034 9.39388 40.9923 20.4355 40.9923C31.4772 40.9923 40.4355 32.034 40.4355 20.9923Z"
      fill="url(#paint0_linear_648_49397)"
    />
    <path
      d="M28.4827 14.2865L17.2169 27.6982L12.3887 22.3335"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="url(#paint0_linear_648_49397)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_648_49397"
        x1="23.3787"
        y1="13.192"
        x2="20.4356"
        y2="40.9923"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#09C505" />
        <stop offset="1" stopColor="#07A803" />
      </linearGradient>
    </defs>
  </SvgIcon>
);

export const LogoutIcon = (props) => (
  <SvgIcon {...props} width="16" height="13.5" viewBox="0 0 16 13.5">
    <path
      id="log-out-outline_1_"
      data-name="log-out-outline (1)"
      d="M73.375,105.375v1.562a1.563,1.563,0,0,1-1.563,1.563h-6.25A1.563,1.563,0,0,1,64,106.937V97.563A1.563,1.563,0,0,1,65.563,96H71.5a1.86,1.86,0,0,1,1.875,1.563v1.562m2.5,6.25L79,102.25l-3.125-3.125M70.25,102.25h8.125"
      transform="translate(-63.5 -95.5)"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
    />
  </SvgIcon>
);

export const AttentionIcon = (props) => (
  <SvgIcon {...props} width="35" height="35" viewBox="0 0 35 35">
    <circle id="Ellipse_3" data-name="Ellipse 3" cx="17.5" cy="17.5" r="17.5" fill="currentColor" />
    <g id="done_icn-2" data-name="done_icn" transform="translate(1 1)">
      <rect
        id="Rectangle_1935"
        data-name="Rectangle 1935"
        width="33"
        height="33"
        fill="currentColor"
        opacity="0"
      />
      <g id="alert-outline" transform="translate(-224.427 -74.004)">
        <path
          id="Path_15055"
          data-name="Path 15055"
          d="M240.927,80a.988.988,0,0,0-.955,1.032l.477,13.931a.5.5,0,0,0,.477.516h0a.5.5,0,0,0,.477-.516l.477-13.931A.988.988,0,0,0,240.927,80Z"
          transform="translate(0 0)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <ellipse
          id="Ellipse_445"
          data-name="Ellipse 445"
          cx="0.955"
          cy="0.79"
          rx="0.955"
          ry="0.79"
          transform="translate(239.971 99.428)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </g>
  </SvgIcon>
);

export const AttentionSimpleIcon = (props) => (
  <SvgIcon {...props} width="17.496" height="17.496" viewBox="0 0 17.496 17.496">
    <g id="alert-circle-outline" transform="translate(-63.25 -63.25)">
      <path
        id="Path_15855"
        data-name="Path 15855"
        d="M80,72a8,8,0,1,0-8,8A8,8,0,0,0,80,72Z"
        fill="none"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
      <path
        id="Path_15856"
        data-name="Path 15856"
        d="M250.254,160.3l.239,5.068.238-5.068a.238.238,0,0,0-.241-.249h0a.239.239,0,0,0-.236.249Z"
        transform="translate(-178.495 -92.044)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_15857"
        data-name="Path 15857"
        d="M236.831,329.572a.831.831,0,1,1,.831-.831A.831.831,0,0,1,236.831,329.572Z"
        transform="translate(-164.833 -252.913)"
        fill="#fff"
      />
    </g>
  </SvgIcon>
);

export const BoxIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 35 35">
    <path
      d="M37.7,9.475l0-.011a5.124,5.124,0,0,0-.62-1.29l-2.8-4.206a3.07,3.07,0,0,0-2.56-1.37H9.162A3.07,3.07,0,0,0,6.6,3.968L3.8,8.173a5.123,5.123,0,0,0-.62,1.29l0,.011a5.119,5.119,0,0,0-.238,1.542v23.5A3.08,3.08,0,0,0,6.014,37.6H34.861a3.08,3.08,0,0,0,3.076-3.076v-23.5A5.121,5.121,0,0,0,37.7,9.475ZM21.463,4.648h10.25a1.024,1.024,0,0,1,.853.457L35,8.75H21.463ZM8.309,5.105a1.023,1.023,0,0,1,.853-.457h10.25v4.1H5.879ZM35.887,34.521a1.027,1.027,0,0,1-1.025,1.025H6.014a1.027,1.027,0,0,1-1.025-1.025c0-24.986,0-23.554.008-23.721H35.879C35.891,10.966,35.887,9.51,35.887,34.521Z"
      transform="translate(-2.938 -2.598)"
      fill="currentColor"
    />
  </SvgIcon>
);

export const ClockIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 56 56">
    <defs>
      <clipPath id="clip-Processing_icn">
        <rect width="56" height="56" />
      </clipPath>
    </defs>
    <g id="Processing_icn" clipPath="url(#clip-Processing_icn)">
      <g id="Processing_icn-2" data-name="Processing_icn" transform="translate(-3060 -10759)">
        <path
          id="Path_15189"
          data-name="Path 15189"
          d="M80,64A16,16,0,1,0,96,80,16,16,0,0,0,80,64Z"
          transform="translate(3008 10707)"
          fill="none"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          id="Path_15190"
          data-name="Path 15190"
          d="M256,128v13.125h8.75"
          transform="translate(2831.5 10648.034)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.5"
        />
      </g>
    </g>
  </SvgIcon>
);

export const DeliveryTruckIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 56 56">
    <defs>
      <clipPath id="clip-Shipping_icn">
        <rect width="56" height="56" />
      </clipPath>
    </defs>
    <g id="Shipping_icn" clipPath="url(#clip-Shipping_icn)">
      <g id="Shipping_icn-2" data-name="Shipping_icn" transform="translate(-2937.888 -10845)">
        <path
          id="Path_15193"
          data-name="Path 15193"
          d="M13.446,22.2h0l18.468.044a1.534,1.534,0,0,1,1.1.442,1.4,1.4,0,0,1,.442,1.1l-.044,4.816,4.816.088a1.661,1.661,0,0,1,1.325.751L43,35.366a.95.95,0,0,0,.53.4l.928.177a1.483,1.483,0,0,1,1.193,1.5v4.506a1.509,1.509,0,0,1-1.5,1.5h-2.3a3.769,3.769,0,0,1-7.511,0l-11.222-.088a3.811,3.811,0,0,1-7.6-.044H13.535a1.49,1.49,0,0,1-1.5-1.5L11.9,23.791A1.575,1.575,0,0,1,13.446,22.2Zm19.086.972a.84.84,0,0,0-.574-.221h0l-18.468-.044a.792.792,0,0,0-.8.8l.088,14.359,19.882-.088.044-8.659h0l.044-5.523A.958.958,0,0,0,32.533,23.172Zm11.62,19.484a.931.931,0,0,0,.574-.221.84.84,0,0,0,.221-.574V37.354a.809.809,0,0,0-.619-.8l-.928-.177a1.55,1.55,0,0,1-1.016-.707l-3.446-5.92a.75.75,0,0,0-.707-.4l-4.816-.088-.044,13.343h.972A3.824,3.824,0,0,1,38.1,39.166a3.757,3.757,0,0,1,3.755,3.446h2.3Zm-6.053,3.4a3.093,3.093,0,1,0-3.093-3.093A3.1,3.1,0,0,0,38.1,46.058Zm-18.733,0a3.093,3.093,0,1,0-3.093-3.093A3.1,3.1,0,0,0,19.367,46.058Zm-5.788-3.579h1.988a3.782,3.782,0,0,1,7.511.044l9.543.044V38.591l-19.882.088v2.96A.9.9,0,0,0,13.579,42.479Z"
          transform="translate(2937.989 10838.451)"
          fill="none"
          stroke="currentColor"
          strokeWidth="1"
        />
        <path
          id="Path_15194"
          data-name="Path 15194"
          d="M66.516,41.3l2.563,2.739a.347.347,0,0,1,0,.486.628.628,0,0,1-.265.088.382.382,0,0,1-.265-.133l-2.563-2.739a.347.347,0,0,1,0-.486A.463.463,0,0,1,66.516,41.3Zm6.45,3.4a.382.382,0,0,1-.265-.133l-6.583-7.2a.345.345,0,0,1,.53-.442l6.583,7.157a.315.315,0,0,1-.044.486A.243.243,0,0,1,72.966,44.7Z"
          transform="translate(2886.466 10830.31)"
          fill="none"
          stroke="currentColor"
          strokeWidth="1"
        />
      </g>
    </g>
  </SvgIcon>
);

export const CompleteIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 56 56">
    <defs>
      <clipPath id="clip-Complete_icn">
        <rect width="56" height="56" />
      </clipPath>
    </defs>
    <g id="Complete_icn" clipPath="url(#clip-Complete_icn)">
      <g id="Group_6841" data-name="Group 6841" transform="translate(-3061 -10907)">
        <g
          id="Ellipse_3"
          data-name="Ellipse 3"
          transform="translate(3072 10918)"
          fill="none"
          stroke="currentColor"
          strokeWidth="2.5"
        >
          <circle cx="17.5" cy="17.5" r="17.5" stroke="none" />
          <circle cx="17.5" cy="17.5" r="16.25" fill="none" />
        </g>
        <path
          id="Path_15054"
          data-name="Path 15054"
          d="M9.487,18.533a1.255,1.255,0,0,1-.933-.416L3.821,12.846a1.254,1.254,0,1,1,1.866-1.675l3.724,4.148L17.133,5.254a1.254,1.254,0,0,1,1.99,1.526L10.481,18.043a1.257,1.257,0,0,1-.946.49Z"
          transform="translate(3078.059 10923.852)"
          fill="currentColor"
        />
      </g>
    </g>
  </SvgIcon>
);

export const CancelledIcon = (props) => (
  <SvgIcon viewBox="0 0 56 56" {...props}>
    <g id="canceled" transform="translate(-2934 -10913)">
      <circle
        id="Ellipse_470"
        data-name="Ellipse 470"
        cx="28"
        cy="28"
        r="28"
        transform="translate(2934 10913)"
        fill="none"
      />
      <g id="complete_icon" data-name="complete icon" transform="translate(-127 6)">
        <g
          id="Ellipse_3"
          data-name="Ellipse 3"
          transform="translate(3072 10918)"
          fill="none"
          stroke="currentColor"
          strokeWidth="2.5"
        >
          <circle cx="17.5" cy="17.5" r="17.5" stroke="none" />
          <circle cx="17.5" cy="17.5" r="16.25" fill="none" />
        </g>
        <g
          id="Group_7959"
          data-name="Group 7959"
          transform="translate(3566.785 11188.784) rotate(135)"
        >
          <path
            id="Union_2"
            data-name="Union 2"
            d="M8.472,18.214v-7.2H1.27a1.271,1.271,0,0,1,0-2.542h7.2v-7.2a1.271,1.271,0,1,1,2.542,0v7.2h7.2a1.271,1.271,0,0,1,0,2.542h-7.2v7.2a1.271,1.271,0,0,1-2.542,0Z"
            transform="translate(148.651 506.848)"
            fill="currentColor"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);

export const ArrowDownIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 18 18">
    <path
      id="Path_14790"
      data-name="Path 14790"
      d="M15.426,12.108a.5.5,0,0,1-.465.317H2.73a.5.5,0,0,1-.353-.853l5.9-5.9a.5.5,0,0,1,.7-.012l6.334,5.9A.5.5,0,0,1,15.426,12.108Z"
      transform="translate(0 0.05)"
      fill="currentColor"
    />
    <rect id="Rectangle_495" data-name="Rectangle 495" width="18" height="18" fill="none" />
  </SvgIcon>
);

export const ArrowUpIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 18 18">
    <path
      id="Path_14790"
      data-name="Path 14790"
      d="M15.426,5.842a.5.5,0,0,0-.465-.317H2.73a.5.5,0,0,0-.353.853l5.9,5.9a.5.5,0,0,0,.7.012l6.334-5.9A.5.5,0,0,0,15.426,5.842Z"
      fill="currentColor"
    />
    <rect id="Rectangle_495" data-name="Rectangle 495" width="18" height="18" fill="none" />
  </SvgIcon>
);

export const PaidIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 56 56">
    <g id="paid" transform="translate(-2934 -10761)">
      <path
        id="Path_15860"
        data-name="Path 15860"
        d="M25.53,24.518H17.364a.817.817,0,1,0,0,1.633H25.53a.817.817,0,1,0,0-1.633Zm0,3.267H17.364a.817.817,0,0,0,0,1.633H25.53a.817.817,0,0,0,0-1.633ZM20.63,18.8h.2v1.633H19.814a.817.817,0,0,0,0,1.633h1.021a.612.612,0,0,0,1.225,0h.2a2.45,2.45,0,1,0,0-4.9h-.2V15.535H23.08a.817.817,0,0,0,0-1.633H22.059a.612.612,0,0,0-1.225,0h-.2a2.45,2.45,0,1,0,0,4.9Zm1.633,0a.817.817,0,1,1,0,1.633h-.2V18.8ZM20.63,15.535h.2v1.633h-.2a.817.817,0,0,1,0-1.633Z"
        transform="translate(2940.552 10766.316)"
        fill="currentColor"
      />
      <path
        id="Path_15861"
        data-name="Path 15861"
        d="M11.047,38V11.4a1.666,1.666,0,0,1,.307-.99A.94.94,0,0,1,12.1,10H35.2a.939.939,0,0,1,.742.41,1.665,1.665,0,0,1,.308.99V38l-4.2-2.8-4.2,2.8-4.2-2.8-4.2,2.8-4.2-2.8Z"
        transform="translate(2938.353 10765)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </SvgIcon>
);

export const LocationIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 14.626 17.072">
    <path
      d="M9.314,1A7.291,7.291,0,0,0,4.026,13.331a.611.611,0,0,0,.114.145l4.221,4.2a1.359,1.359,0,0,0,1.91,0l4.206-4.192.014-.012,0,0a.611.611,0,0,0,.062-.071l.014-.019A7.3,7.3,0,0,0,9.314,1Zm0,1.219a6.084,6.084,0,0,1,4.313,10.387q-.021.022-.04.045L9.414,16.815a.122.122,0,0,1-.188,0L5.112,12.721l0,0a.611.611,0,0,0-.1-.1A6.085,6.085,0,0,1,9.314,2.219Zm.019,2.453a3.663,3.663,0,1,0,3.673,3.663A3.669,3.669,0,0,0,9.333,4.673Zm0,1.215A2.449,2.449,0,1,1,6.894,8.336,2.441,2.441,0,0,1,9.333,5.887Z"
      transform="translate(-2.004 -1)"
      fill="currentColor"
    />
  </SvgIcon>
);

export const CheckIcon = (props) => (
  <SvgIcon width="11" height="9.538" viewBox="0 0 11 9.538" {...props}>
    <path
      id="Path_14789"
      data-name="Path 14789"
      d="M7.646,14.3A.869.869,0,0,1,7,14.012L3.722,10.361A.868.868,0,1,1,5.015,9.2l2.579,2.873L12.942,5.1A.869.869,0,0,1,14.321,6.16l-5.985,7.8a.871.871,0,0,1-.655.339Z"
      transform="translate(-3.5 -4.763)"
      fill="currentColor"
    />
  </SvgIcon>
);

export const DropdownIcon = (props) => (
  <SvgIcon width="10.29px" height="5.367px" viewBox="0 0 10.29 5.367" {...props}>
    <path
      id="Path_14791"
      data-name="Path 14791"
      d="M12,4.544a.389.389,0,0,0-.362-.247H2.124a.389.389,0,0,0-.275.664L6.435,9.55a.387.387,0,0,0,.275.114.391.391,0,0,0,.265-.1L11.9,4.97A.389.389,0,0,0,12,4.544Z"
      transform="translate(-1.734 -4.297)"
      fill="currentColor"
    />
  </SvgIcon>
);

export const ReturnIcon = (props) => (
  <SvgIcon width="15px" height="15px" viewBox="0 0 15 15" {...props}>
    <circle
      id="Ellipse_507"
      data-name="Ellipse 507"
      cx="7.5"
      cy="7.5"
      r="7.5"
      fill="#e8e8e8"
      opacity="0.5"
    />
    <path
      id="back"
      d="M15.594,15.119a2.6,2.6,0,0,1-2.6,2.6H8.325a.316.316,0,0,1,0-.632H13a1.964,1.964,0,1,0,0-3.928H9.208l1.035.851a.316.316,0,1,1-.4.488L8.124,13.083l-.01-.01-.023-.024,0,0-.007-.007h0l-.009-.013A.279.279,0,0,1,8.055,13l0-.006-.005-.008-.005-.013q-.006-.014-.011-.028v0a.274.274,0,0,1-.014-.056c0-.006,0-.011,0-.017s0-.011,0-.017,0-.009,0-.014,0-.009,0-.014v-.017a.12.12,0,0,1,0-.017.273.273,0,0,1,.014-.056v0c0-.01.007-.019.011-.028s0-.009.005-.013l.005-.008,0-.006a.278.278,0,0,1,.017-.026l.009-.013h0l.01-.01a.28.28,0,0,1,.023-.023l.01-.01h0l1.716-1.41a.316.316,0,1,1,.4.488l-1.035.851H13A2.6,2.6,0,0,1,15.594,15.119Z"
      transform="translate(-4.302 -6.914)"
      fill="#232429"
    />
  </SvgIcon>
);

export const ReturnedIcon = (props) => (
  <SvgIcon viewBox="0 0 56 56" {...props}>
    <circle id="Ellipse_470" data-name="Ellipse 470" cx="28" cy="28" r="28" fill="none" />
    <path
      id="Path_16254"
      data-name="Path 16254"
      d="M38.615,25.753a9.008,9.008,0,0,1-9,9h-16.2a1.1,1.1,0,0,1,0-2.193h16.2a6.808,6.808,0,1,0,0-13.616H16.481l3.6,2.951a1.1,1.1,0,0,1-1.392,1.694l-5.948-4.9-.035-.035a1.03,1.03,0,0,1-.081-.083l-.037-.026s-.02-.029-.029-.043a.971.971,0,0,1-.061-.089V18.4l-.017-.029-.017-.043c-.015-.033-.029-.065-.04-.1a.943.943,0,0,1-.049-.193v-.274a.386.386,0,0,1,0-.061.933.933,0,0,1,.049-.193.262.262,0,0,1,.04-.1c.014-.032,0-.029.017-.043l-.012-.058v-.02a.943.943,0,0,1,.058-.089.514.514,0,0,1,.029-.043l.037-.038a1.031,1.031,0,0,1,.078-.078L12.706,17l5.948-4.9A1.1,1.1,0,1,1,20.046,13.8l-3.565,2.957H29.622a9.005,9.005,0,0,1,8.994,9Z"
      transform="translate(3.941 3.693)"
      fill="currentColor"
    />
  </SvgIcon>
);

export const InfoIcon = (props, fill = '#2F80ED') => (
  <SvgIcon width="16" height="16" viewBox="0 0 20 20" {...props}>
    <path
      d="M8 1.5C4.41037 1.5 1.5 4.41037 1.5 8C1.5 11.5896 4.41037 14.5 8 14.5C11.5896 14.5 14.5 11.5896 14.5 8C14.5 4.41037 11.5896 1.5 8 1.5ZM7.1875 7.1875C7.1875 6.97201 7.2731 6.76535 7.42548 6.61298C7.57785 6.4606 7.78451 6.375 8 6.375C8.21549 6.375 8.42215 6.4606 8.57452 6.61298C8.7269 6.76535 8.8125 6.97201 8.8125 7.1875V11.25C8.8125 11.4655 8.7269 11.6722 8.57452 11.8245C8.42215 11.9769 8.21549 12.0625 8 12.0625C7.78451 12.0625 7.57785 11.9769 7.42548 11.8245C7.2731 11.6722 7.1875 11.4655 7.1875 11.25V7.1875ZM8 5.5755C7.89327 5.57547 7.7876 5.55443 7.68901 5.51356C7.59042 5.47269 7.50084 5.41281 7.42539 5.33732C7.34994 5.26184 7.2901 5.17223 7.24929 5.07362C7.20847 4.97501 7.18747 4.86932 7.1875 4.76259C7.18753 4.65587 7.20857 4.55019 7.24944 4.4516C7.29031 4.35301 7.35019 4.26343 7.42568 4.18799C7.50116 4.11254 7.59077 4.0527 7.68938 4.01188C7.78799 3.97106 7.89368 3.95007 8.00041 3.95009C8.21595 3.95015 8.42264 4.03582 8.57501 4.18827C8.72739 4.34072 8.81296 4.54746 8.81291 4.763C8.81285 4.97854 8.72718 5.18524 8.57473 5.33761C8.42228 5.48998 8.21554 5.57555 8 5.5755Z"
      fill={fill}
    />
  </SvgIcon>
);

export const PlusIcon = (props) => (
  <SvgIcon width="16" height="16" viewBox="0 0 20 20" {...props}>
    <path
      d="M12 5.25V18.75M18.75 12H5.25"
      stroke="#153568"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export const MinusIcon = (props) => (
  <SvgIcon width="16" height="16" viewBox="0 0 20 20" {...props}>
    <path
      d="M18.75 12H5.25"
      stroke="#153568"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export const BlueDotIcon = (props) => (
  <SvgIcon width="16" height="16" viewBox="0 0 20 20" {...props}>
    <path
      d="M5 10C7.76142 10 10 7.76142 10 5C10 2.23858 7.76142 0 5 0C2.23858 0 0 2.23858 0 5C0 7.76142 2.23858 10 5 10Z"
      fill="#153568"
    />
  </SvgIcon>
);

export const GrayDotIcon = (props) => (
  <SvgIcon width="16" height="16" viewBox="0 0 20 20" {...props}>
    <path
      d="M5 10C7.76142 10 10 7.76142 10 5C10 2.23858 7.76142 0 5 0C2.23858 0 0 2.23858 0 5C0 7.76142 2.23858 10 5 10Z"
      fill="#E8E8E8"
    />
  </SvgIcon>
);

export const WebIcon = (props) => (
  <SvgIcon width="24" height="24" {...props}>
    <g opacity="0.5" fill="none">
      <path
        d="M12 3.91797C7.53662 3.91797 3.91797 7.53662 3.91797 12C3.91797 16.4634 7.53662 20.082 12 20.082C16.4634 20.082 20.082 16.4634 20.082 12C20.082 7.53662 16.4634 3.91797 12 3.91797Z"
        stroke="#585858"
        strokeWidth="1.02862"
        strokeMiterlimit="10"
      />
      <path
        d="M12.0009 3.91797C9.74458 3.91797 7.62305 7.53662 7.62305 12C7.62305 16.4634 9.74458 20.082 12.0009 20.082C14.2573 20.082 16.3788 16.4634 16.3788 12C16.3788 7.53662 14.2573 3.91797 12.0009 3.91797Z"
        stroke="#585858"
        strokeWidth="1.02862"
        strokeMiterlimit="10"
      />
      <path
        d="M6.61133 6.61133C8.09718 7.66627 9.9677 8.29534 11.9995 8.29534C14.0313 8.29534 15.9018 7.66627 17.3876 6.61133M17.3876 17.3876C15.9018 16.3327 14.0313 15.7036 11.9995 15.7036C9.9677 15.7036 8.09718 16.3327 6.61133 17.3876"
        stroke="#585858"
        strokeWidth="1.02862"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 3.91797V20.082M20.082 12H3.91797"
        stroke="#585858"
        strokeWidth="1.02862"
        strokeMiterlimit="10"
      />
    </g>
  </SvgIcon>
);

export const FacebookSecondIcon = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <g opacity="0.5">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 19.2C15.9765 19.2 19.2 15.9765 19.2 12C19.2 8.02355 15.9765 4.8 12 4.8C8.02355 4.8 4.8 8.02355 4.8 12C4.8 15.9765 8.02355 19.2 12 19.2ZM12 21C16.9705 21 21 16.9705 21 12C21 7.02943 16.9705 3 12 3C7.02943 3 3 7.02943 3 12C3 16.9705 7.02943 21 12 21Z"
        fill="#585858"
      />
      <path
        d="M11.9984 10.2C11.9984 9.70295 12.4014 9.3 12.8984 9.3H13.7984C14.2955 9.3 14.6984 8.89706 14.6984 8.4C14.6984 7.90295 14.2955 7.5 13.7984 7.5H12.8984C11.4072 7.5 10.1984 8.70884 10.1984 10.2V12H9.29844C8.80139 12 8.39844 12.4029 8.39844 12.9C8.39844 13.3971 8.80138 13.8 9.29844 13.8H10.1984V19.2C10.1984 19.6971 10.6014 20.1 11.0984 20.1C11.5955 20.1 11.9984 19.6971 11.9984 19.2V13.8H13.7984C14.2955 13.8 14.6984 13.3971 14.6984 12.9C14.6984 12.4029 14.2955 12 13.7984 12H11.9984V10.2Z"
        fill="#585858"
      />
    </g>
  </SvgIcon>
);

export const InstagramSecondIcon = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <g opacity="0.5">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6021 4.80001H8.40199C6.41375 4.80001 4.80197 6.4118 4.80197 8.40004V15.6001C4.80197 17.5883 6.41375 19.2001 8.40199 19.2001H15.6021C17.5903 19.2001 19.2021 17.5883 19.2021 15.6001V8.40004C19.2021 6.4118 17.5903 4.80001 15.6021 4.80001ZM8.40199 3C5.41963 3 3.00195 5.41768 3.00195 8.40004V15.6001C3.00195 18.5824 5.41963 21.0001 8.40199 21.0001H15.6021C18.5844 21.0001 21.0021 18.5824 21.0021 15.6001V8.40004C21.0021 5.41768 18.5844 3 15.6021 3H8.40199Z"
        fill="#585858"
      />
      <path
        d="M16.5 8.25001C16.9142 8.25001 17.25 7.91422 17.25 7.50001C17.25 7.08579 16.9142 6.75 16.5 6.75C16.0858 6.75 15.75 7.08579 15.75 7.50001C15.75 7.91422 16.0858 8.25001 16.5 8.25001Z"
        fill="#585858"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5001 12C16.5001 14.4853 14.4853 16.5001 12 16.5001C9.51474 16.5001 7.5 14.4853 7.5 12C7.5 9.51474 9.51474 7.5 12 7.5C14.4853 7.5 16.5001 9.51474 16.5001 12ZM14.7001 12C14.7001 13.4913 13.4913 14.7001 12 14.7001C10.5088 14.7001 9.30001 13.4913 9.30001 12C9.30001 10.5088 10.5088 9.30001 12 9.30001C13.4913 9.30001 14.7001 10.5088 14.7001 12Z"
        fill="#585858"
      />
    </g>
  </SvgIcon>
);
