import { useQuery } from '@apollo/client';
import { HOME_PAGE_DATA } from '../queries';

export const useHomePageQuery = ({ filter }) => {
  const {
    data: {
      brandsFeatured: { nodes: preferredBrands = [] } = {},
      itemsFeatured: { nodes: preferredProducts = [] } = {},
      brandsTopSelling: { nodes: brandsTopSelling = [] } = {},
      itemsTopSelling: { nodes: itemsTopSelling = [] } = {},
      categories: { nodes: categories = [] } = {},
      promotionBanners: { nodes: promotionBanners = [] } = {},
    } = {},
    loading,
    error,
  } = useQuery(HOME_PAGE_DATA, {
    variables: {
      itemsFilter: { uidOmitsItemRestrictionsIn: filter, brandUidOmitsBrandRestrictionsIn: filter },
      brandsFilter: { uidOmitsBrandRestrictionsIn: filter },
      filter: { displayOnHomeInMarketplaceEq: true },
    },
  });

  if (error) {
    console.error(error);
  }

  return {
    preferredBrands,
    preferredProducts,
    brandsTopSelling,
    itemsTopSelling,
    categories,
    promotionBanners,
    isLoading: loading,
    isError: error,
  };
};
