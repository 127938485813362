import { GA_EVENTS, GA_PAGE_TYPES, GA_PAGE_NAMES, GA_LOGIN_STATUSES } from '../../constants';

export const getDealsPageForGoogleAnalytics = (window, isLoggedIn = false) => {
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: GA_EVENTS.page_view,
    page_type: GA_PAGE_TYPES.deals_page,
    page_name: GA_PAGE_NAMES.deals_page,
    login_status: isLoggedIn ? GA_LOGIN_STATUSES.loggedIn : GA_LOGIN_STATUSES.guest,
  });
};
