import { gql } from '@apollo/client';

export const BRAND_PAGE_DATA = gql`
  query brandPageData($filter: BrandsFilterInput) {
    brands(filter: $filter) {
      nodes {
        name
        slug
        uid
        description
        websiteUrl
        facebookUrl
        instagramUrl
        coverImage {
          url
        }
        bannerImage {
          url
        }
      }
    }
  }
`;
