import { gql } from '@apollo/client';
import { FRAGMENTS_QUERIES } from '../constants';

export const LIST_BRANDS = gql`
  query listBrands($filter: BrandsFilterInput, $pagination: PaginationInput) {
    brands(filter: $filter, pagination: $pagination) {
      nodes {
        uid
        name
        slug
        coverImage {
          url
          mediumUrl
          thumbUrl
        }
      }
      ${FRAGMENTS_QUERIES.pagination}
    }
  }
`;

export const FEATURED_AND_TOP_SELLING_BRANDS = gql`
  query featuredAndTopSellingBrands($filter: BrandsFilterInput) {
    brandsFeatured(filter: $filter) {
      nodes {
        uid
        name
        slug
        coverImage {
          url
          mediumUrl
          thumbUrl
        }
      }
    }
    brandsTopSelling(filter: $filter) {
      nodes {
        name
        slug
        uid
        coverImage {
          url
          mediumUrl
          thumbUrl
        }
      }
    }
  }
`;

export const BRAND_TOP_SELLING_PRODUCTS = gql`
  query brandTopSellingProducts($filter: ItemsFilterInput) {
    itemsTopSelling(filter: $filter) {
      nodes {
        uid
        sku
        name
        slug
        description
        supplier {
          uid
        }
        brand {
          uid
          name
          slug
        }
        categories {
          uid
          name
          slug
        }
        primaryImage {
          url
          thumbUrl
          mediumUrl
        }
        images {
          url
        }
        prices {
          type
          currencyCode
          currencySign
          value
        }
        availability {
          availabilityControl
          availableQty
          outOfStockThreshold
          lowInStockThreshold
        }
      }
    }
  }
`;
