import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { setPlaceholderImageOnError } from '../../utils';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: '30px',
    marginBottom: '30px',
    borderRadius: '18px',
    color: 'var(--white)',
    fontSize: '12px',
    background: theme.palette.primary.main,
    boxShadow: '0px 2px 20px #1B1B1D26',
    letterSpacing: '1.2px',
    height: '36px',
    width: '100%',
    maxWidth: '250px',
    padding: '5px',
    [theme.breakpoints.up('sm')]: {
      '&:hover': {
        color: theme.palette.primary.main,
        background: 'var(--white)',
        boxShadow: '0px 2px 20px #1B1B1D26',
      },
    },
  },
  title: {
    color: 'var(--dark-gray)',
    fontSize: '15px',
    lineHeight: '28px',
    margin: '0',
  },
  subTitle: {
    color: 'var(--gray)',
    fontSize: '14px',
    lineHeight: '21px',
    margin: '10px 0 0',
  },
}));

export const OrderStatusLayout = ({
  image,
  imageAlt,
  title,
  subTitle,
  buttonText,
  onClickButton,
}) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      flexGrow={1}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box textAlign="center">
        <img src={image} alt={imageAlt} onError={setPlaceholderImageOnError} />
        <h1 className={classes.title}>{title}</h1>
        <p className={classes.subTitle}>{subTitle}</p>
        <Button className={classes.button} onClick={onClickButton}>
          {buttonText}
        </Button>
      </Box>
    </Box>
  );
};
