import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    borderSpacing: '0 10px',
  },
  tr: {
    borderBottom: `1px solid ${theme.palette.gray.light}`,
  },
  th: {
    fontSize: '10px',
    fontWeight: 400,
    color: theme.palette.gray.main,
    letterSpacing: '0.4px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
  },
}));

export const Container = ({ children, columns }) => {
  const classes = useStyles();

  return (
    <table cellPadding="6" className={classes.table}>
      <thead>
        <tr className={classes.tr}>
          {(columns ?? []).map((column, index) => (
            <th
              className={clsx(column?.headerClass ?? '', classes.th)}
              key={`${column?.headerTitle ?? ''}__${index}`}
            >
              {column?.headerTitle ?? ''}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
};
