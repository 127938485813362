import { gql } from '@apollo/client';

export const CATEGORIES_RELATED_QUERY = gql`
  query ($parentCategoryUid: String!) {
    categoriesRelated(parentCategoryUid: $parentCategoryUid) {
      nodes {
        uid
        name
        slug
      }
    }
  }
`;
