import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TextField, InputAdornment } from '@material-ui/core';
import { SearchIcon } from '../icons/Icons';

const WIDTH_OF_BUTTON_IN_USER_MENU = 46;
const INPUT_PADDING_LEFT = 40;
const EXPANDED_INPUT_PADDING_RIGHT = 20;
const EXPANDED_INPUT_MARGIN_LEFT = 10;
const EXPANDED_INPUT_HORIZONTAL_SPACE =
  INPUT_PADDING_LEFT + EXPANDED_INPUT_PADDING_RIGHT + EXPANDED_INPUT_MARGIN_LEFT;
const SECONDARY_MENU_WIDTH = 256;

const styles = (theme) => ({
  root: (props) => {
    const userMenuWidth = props.numberOfButtonsInUserMenu * WIDTH_OF_BUTTON_IN_USER_MENU;
    return {
      position: 'absolute',
      right: `${userMenuWidth}px`,
      padding: '10px 0',
      background: '#ffffff',

      [theme.breakpoints.down('sm')]: {
        right: 0,
      },
      '& .MuiOutlinedInput-root': {
        paddingLeft: '8px',
        borderRadius: '18px',
        background: 'var(--white)',
        border: '1px solid var(--light-gray)',

        '& > div': {
          pointerEvents: 'none',
        },

        '& input': {
          padding: `8px 0 8px ${INPUT_PADDING_LEFT}px`,
          fontSize: '12px',
          fontStyle: 'italic',
          height: '20px',
          lineHeight: '20px',
          color: 'var(--gray)',
          caretColor: theme.palette.primary.main,
          width: '0',
          transition: '0.4s',
          marginLeft: `-${INPUT_PADDING_LEFT}px`,
        },

        '&:hover input, & input:focus, & input:not(:placeholder-shown)': {
          color: 'var(--dark-gray)',
          padding: `8px ${EXPANDED_INPUT_PADDING_RIGHT}px 8px ${INPUT_PADDING_LEFT}px`,

          // width for expanded input is a calculation of container width (first line) minus other elements in row (second line)
          width: `calc(
            ${theme.breakpoints.width('lg')}px - 30px -
            ${userMenuWidth + SECONDARY_MENU_WIDTH + EXPANDED_INPUT_HORIZONTAL_SPACE}px
          )`,

          // for this media query container padding (left and right) is changed from 15px to 5vw
          '@media (max-width: 1331px)': {
            width: `calc(
              ${theme.breakpoints.width('lg')}px - 10vw -
              ${userMenuWidth + SECONDARY_MENU_WIDTH + EXPANDED_INPUT_HORIZONTAL_SPACE}px
            )`,
          },

          // for this media query container is switched ot be fluid (window width based)
          [theme.breakpoints.down('md')]: {
            width: `calc(
              90vw -
              ${userMenuWidth + SECONDARY_MENU_WIDTH + EXPANDED_INPUT_HORIZONTAL_SPACE}px
            )`,
          },

          // for this media query user menu items are moved to drawer menu
          [theme.breakpoints.down('sm')]: {
            width: `calc(
              90vw -
              ${SECONDARY_MENU_WIDTH + EXPANDED_INPUT_HORIZONTAL_SPACE}px
            )`,
          },

          // for this media query space for search is smaller, so after expand input overlaps secondary menu
          [theme.breakpoints.down('xs')]: {
            width: 'calc(90vw - 55px)',
          },
        },
        '& fieldset, &:hover fieldset, &.Mui-focused fieldset': {
          borderColor: 'var(--white)',
          display: 'none',
        },
        '& svg': {
          height: '18px',
          width: '18px',
          marginRight: '2px',
          color: `var(--dark-gray)`,
        },
        '&:hover svg': {
          opacity: '0.5',
          transition: '0.4s',
        },
      },
    };
  },
});

export const SearchField = withStyles(styles)(({ classes, ...props }) => (
  <TextField
    {...props}
    className={classes.root}
    variant="outlined"
    placeholder="Search for anything"
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      ),
    }}
  />
));
