import React from 'react';
import clsx from 'clsx';
import { Box, makeStyles } from '@material-ui/core';
import { Date } from '../date';

const useStyles = makeStyles((theme) => ({
  value: {
    fontSize: '15px',
    fontWeight: 'bold',
    color: theme.palette.gray.dark,
  },
}));

export const OrderDate = ({ date, className }) => {
  const classes = useStyles();

  return (
    <Box className={clsx(className, classes.value)}>
      <Date date={date} />
    </Box>
  );
};
