import { getProductPrices } from './getProductPrices';
import { displayCentsAsDollars } from './display-cents-as-dollars';

export const getShoppingCartItem = (product) => {
  const productPrices = getProductPrices(product?.data);

  return {
    item_name: product?.data?.name,
    item_id: product?.data?.sku,
    quantity: product?.amount,
    item_brand: product?.data?.brand?.name,
    price: Number.isInteger(productPrices.discount)
      ? displayCentsAsDollars(productPrices.discount, { withSign: false })
      : displayCentsAsDollars(productPrices.regular, { withSign: false }),
  };
};

export const getShoppingCartItemList = (productList) =>
  productList.reduce((acc, product) => [...acc, getShoppingCartItem(product)], []);
