import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Slider, Slide, Dot, CarouselContext } from 'pure-react-carousel';
import { Hidden } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dots: {
    position: 'absolute',
    bottom: '-30px',
    left: '50%',
    transform: 'translateX(-50%)',
    display: 'flex',
    '& > button:first-child': {
      borderLeftColor: 'transparent',
    },
    [theme.breakpoints.up('sm')]: {
      position: 'initial',
      transform: 'none',
    },
  },
  carousel: {
    position: 'relative',
  },
  dot: {
    background: 'var(--light-gray)',
    border: 'none',
    boxShadow: '1px 1px 2px #0000003B',
    borderRadius: '50%',
    width: '10px',
    minHeight: '10px',
    padding: 0,
    marginLeft: '10px',
    marginRight: '10px',
    '&.carousel__dot--selected': {
      background: theme.palette.primary.main,
    },
    [theme.breakpoints.up('sm')]: {
      boxShadow: 'none',
      borderRadius: '0px',
      margin: 0,
      color: 'var(--dark-gray)',
      fontWeight: 700,
      width: '100%',
      border: 'none',
      background: 'var(--white)',
      fontSize: '15px',
      textTransform: 'capitalize',
      opacity: 1,
      height: '50px',
      padding: '0 20px',
      borderLeft: '1px solid var(--light-gray)',
      '&:hover': {
        opacity: 0.5,
      },
      '&.carousel__dot--selected': {
        background: theme.palette.primary.main,
        color: 'var(--white)',
        opacity: 1,
        borderLeftColor: theme.palette.primary.main,
      },
      '&.carousel__dot--selected + button': {
        borderLeftColor: theme.palette.primary.main,
      },
    },
  },
}));

export const SlidesWidget = ({ items }) => {
  const classes = useStyles();
  const carouselContext = useContext(CarouselContext);

  const start = () => {
    carouselContext.setStoreState({ isPlaying: true });
  };

  const stop = () => {
    carouselContext.setStoreState({ isPlaying: false });
  };

  return (
    <div onMouseEnter={stop} onMouseLeave={start} onFocus={stop} onBlur={start}>
      <div className={classes.dots}>
        {items.map((item, index) => (
          <Dot className={classes.dot} key={item.name} slide={index}>
            <Hidden xsDown>{item.name}</Hidden>
          </Dot>
        ))}
      </div>
      <Slider className={classes.slider}>
        {items.map((item, index) => (
          <Slide key={item.uid} index={index}>
            <img src={item?.bannerImage?.url} alt={item.name} title={item.name} />
          </Slide>
        ))}
      </Slider>
    </div>
  );
};
