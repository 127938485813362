export const nameSplitter = (fullName) => {
  let firstName = '';
  let lastName = '';

  if (!fullName) return [firstName, lastName];

  const firstSpaceIndex = fullName.indexOf(' ');

  if (firstSpaceIndex !== -1) {
    firstName = fullName.substring(0, firstSpaceIndex);
    lastName = fullName.substring(firstSpaceIndex + 1);
  } else {
    firstName = fullName;
  }

  return [firstName, lastName];
};
