import { useQuery } from '@apollo/client';
import { FEATURED_AND_TOP_SELLING_BRANDS, LIST_BRANDS } from '../queries';
import { usePaginatedList } from './usePaginatedList';
import { RESPONSE_TYPES } from '../constants';

export const useBrands = ({ filter }) => {
  const {
    data: { list: brands = {} } = {},
    hasMore,
    loading: listLoading,
    error: listError,
    fetchListMore,
  } = usePaginatedList(LIST_BRANDS, {
    typeData: RESPONSE_TYPES.brands,
    filter,
  });

  const {
    data: {
      brandsFeatured: { nodes: preferredBrands = [] } = {},
      brandsTopSelling: { nodes: brandsTopSelling = [] } = {},
    } = {},
    loading,
    error,
  } = useQuery(FEATURED_AND_TOP_SELLING_BRANDS, {
    variables: {
      filter,
    },
  });

  if (error || listError) {
    console.error(error, listError);
  }

  return {
    preferredBrands,
    brandsTopSelling,
    brands,
    isLoading: loading || listLoading,
    isError: error,
    hasMore,
    fetchListMore,
  };
};
