import { GA_EVENTS, GA_PAGE_TYPES, GA_LOGIN_STATUSES } from '../../constants';

export const getCategoryPageForGoogleAnalytics = (window, categoryName, isLoggedIn = false) => {
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: GA_EVENTS.page_view,
    page_type: GA_PAGE_TYPES.category_page,
    page_name: categoryName,
    category_hierarchy1: categoryName,
    login_status: isLoggedIn ? GA_LOGIN_STATUSES.loggedIn : GA_LOGIN_STATUSES.guest,
  });
};
