import React from 'react';
import { setPlaceholderImageOnError } from '../../utils';

export const HeaderContent = ({ config }) => {
  const { name, logoImage, longLogoImage } = config?.general ?? {};
  const logoImageUrl = logoImage?.url;
  const longLogoImageUrl = longLogoImage?.url;
  const storeFrontName = name ?? '';

  return (
    <>
      {logoImageUrl && (
        <img src={logoImageUrl} alt={storeFrontName} onError={setPlaceholderImageOnError} />
      )}
      {longLogoImageUrl && (
        <img src={longLogoImageUrl} alt={storeFrontName} onError={setPlaceholderImageOnError} />
      )}
      {!longLogoImageUrl && <span>{storeFrontName}</span>}
    </>
  );
};
