import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import clsx from 'clsx';
import { Button } from '../button';
import { UpdateLocationModal } from '../update-location-modal';
import { LocationIcon } from '../icons/Icons';

const useStyles = makeStyles((theme) => ({
  button: {
    height: '28px',
    maxWidth: '88px',
    fontSize: '10px',
    fontWeight: 600,
    [theme.breakpoints.up('sm')]: {
      maxWidth: '130px',
      height: '30px',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '170px',
      height: '35px',
      fontSize: '12px',
    },
  },
}));

export const UpdateLocationButton = ({ asIcon = false, className, button }) => {
  const classes = useStyles();
  const [isOpen, setOpening] = useState(false);

  const handleOpen = useCallback(() => {
    setOpening(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpening(false);
  }, []);

  const trigger =
    (!!button &&
      React.isValidElement(button) &&
      React.cloneElement(button, {
        onClick: handleOpen,
        className,
        id: 'subnav-updatelocation',
      })) ||
    (asIcon ? (
      // ID need to Google Analytics, task CEP-2317
      <IconButton className={className} onClick={handleOpen} id="subnav-updatelocation">
        <LocationIcon />
      </IconButton>
    ) : (
      <Button
        id="subnav-updatelocation" // ID need to Google Analytics, task CEP-2317
        size="action"
        color="white"
        className={clsx(classes.button, className)}
        variant="contained"
        onClick={handleOpen}
      >
        Update
      </Button>
    ));

  return (
    <>
      {trigger}

      <UpdateLocationModal isOpen={isOpen} handleCancel={handleClose} />
    </>
  );
};
