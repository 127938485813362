export const FRAGMENTS_QUERIES = {
  pagination: `pagination {
    perPage
    lastPage
    nextPage
    totalNodes
    totalPages
    currentPage
  }`,
  address: `addresses {
    city
    countryCode
    postalCode
    stateCode
    streetLine1
    streetLine2
    type
    uid
  }`,
  contact: `contact {
    email
    formalName
    phone
  }`,
};
