import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { getAddressStr } from '../../utils';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
    lineHeight: '28px',
    color: theme.palette.gray.dark,
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
  },
  info: {
    fontSize: '14px',
    lineHeight: '21px',
    color: theme.palette.gray.light,
  },
}));

export const SellerDetails = ({ data: { contact = {}, addresses = [] } = {} }) => {
  const classes = useStyles();
  const address = getAddressStr(addresses);

  return (
    <Box>
      <Box className={classes.title}>Storefront Owner</Box>
      <Box className={classes.name}>{contact?.formalName}</Box>
      <Box className={classes.email}>{contact?.email}</Box>
      <Box className={classes.address}>{address}</Box>
    </Box>
  );
};
