import React from 'react';
import { getDropdown } from '../get-dropdown';
import { REQUEST_RETURN_ORDER_REASONS_OPTIONS } from '../../constants';

const RequestReturnReasonsDropdown = getDropdown({
  renderLabel: ({ label }) => label,
});

export const RequestReturnReasons = ({
  options = REQUEST_RETURN_ORDER_REASONS_OPTIONS,
  value,
  ...props
}) => (
  <RequestReturnReasonsDropdown
    {...props}
    value={value}
    options={options}
    placeholder="Pick return reason"
  />
);
