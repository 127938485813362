import { usePaginatedList } from './usePaginatedList';
import { FILTERED_PRODUCTS_QUERIES } from '../queries';
import { RESPONSE_TYPES } from '../constants';

export const useFilteredProducts = (filter) => {
  const { data, hasMore, loading, error, page, fetchListMore, fetchList } = usePaginatedList(
    FILTERED_PRODUCTS_QUERIES,
    {
      typeData: RESPONSE_TYPES.items,
      filter,
    },
  );

  return {
    page,
    fetchListMore,
    fetchList,
    products: data?.list,
    loading,
    error,
    hasMore,
  };
};
