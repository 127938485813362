import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client/core';

import { emailService, sessionCacheStorage } from '../services';
import { ACTIONS_TYPES, ROUTING_CONFIG } from '../constants';
import { Context } from '../store';
import { getLocalStoreFrontSlug } from '../utils';

const REMOVE_SESSION = gql`
  mutation removeSession {
    removeSession {
      status
    }
  }
`;

export const useLogout = () => {
  const [removeSessionMutation, { loading }] = useMutation(REMOVE_SESSION);
  const { dispatch } = useContext(Context);
  const { replace } = useHistory();
  const storeFrontSlug = getLocalStoreFrontSlug();
  const temporaryDecision = false;

  const removeSession = async () => {
    try {
      // TODO this is temporary decision, later will need discus
      if (temporaryDecision) {
        const response = await removeSessionMutation();

        if (response?.data?.removeSession) {
          sessionCacheStorage.removeToken();
          emailService.remove();
          dispatch({ type: ACTIONS_TYPES.CLEAR_ACCOUNT });
          replace(ROUTING_CONFIG.home.replace(':storeFrontSlug', storeFrontSlug));
          window.scrollTo(0, 0);
          return null;
        }
      }

      sessionCacheStorage.removeToken();
      emailService.remove();
      dispatch({ type: ACTIONS_TYPES.CLEAR_ACCOUNT });
      replace(ROUTING_CONFIG.home.replace(':storeFrontSlug', storeFrontSlug));
      window.scrollTo(0, 0);
      window.location.reload();
      return null;
    } catch (error) {
      console.error(error.graphQLErrors);
      return null;
    }
  };

  return {
    loading,
    removeSession,
  };
};
