export const GA_LOGIN_STATUSES = {
  guest: 'guest',
  loggedIn: 'registered',
};

export const GA_EVENT_LOCATIONS = {
  content: 'content',
};

export const GA_EVENTS = {
  add_to_cart: 'add_to_cart',
  remove_from_cart: 'remove_from_cart',
  purchase: 'purchase',
  page_view: 'page_view',
};

export const GA_PAGE_TYPES = {
  home_page: 'home',
  deals_page: 'deals',
  brands_page: 'brands',
  brand_page: 'brands',
  categories_page: 'categories',
  category_page: 'categories',
  product_page: 'product',
  product_search_page: 'search',
  account_settings_page: 'account',
  account_recommendations_page: 'account',
  account_order_history_page: 'account',
  account_order_details_page: 'account',
  account_order_request_return_page: 'account',
  account_order_return_success_page: 'account',
  shopping_cart_page: 'cart',
  checkout_page: 'checkout',
  order_canceled_page: 'checkout',
  order_successful_page: 'checkout',
};

export const GA_PAGE_NAMES = {
  home_page: 'storefront',
  deals_page: 'deals',
  brands_page: 'brands list',
  categories_page: 'categories list',
  product_search_page: 'search results',
  account_settings_page: 'account details',
  account_recommendations_page: 'recommendations',
  account_order_history_page: 'order history',
  account_order_details_page: 'order details',
  account_order_request_return_page: 'order return',
  account_order_return_success_page: 'order return confirmation',
  shopping_cart_page: 'shopping cart',
  checkout_page: 'checkout',
  order_canceled_page: 'order cancellation',
  order_successful_page: 'order confirmation',
};
