import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

export const InputTextField = withStyles((theme) => ({
  root: {
    width: '100%',
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(4),
    '& .MuiOutlinedInput-root': {
      paddingLeft: '20px',
      borderRadius: '18px',
      '& input': {
        padding: '8px 20px 8px 0',
        fontSize: '13px',
        fontWeight: '600',
        height: '21px',
        lineHeight: '21px',
        color: 'var(--dark-gray)',
        '&.Mui-disabled': {
          color: 'var(--gray)',
        },
      },
      '&.Mui-focused input': {
        opacity: 1,
      },
      '& fieldset': {
        borderColor: 'var(--light-gray)',
      },
      '&:hover fieldset': {
        borderColor: 'var(--light-gray)',
        opacity: 0.5,
      },
      '&.Mui-focused fieldset': {
        borderColor: 'var(--light-gray)',
        borderWidth: '1px',
        opacity: 1,
      },
      '&.Mui-disabled fieldset': {
        borderColor: 'var(--light-gray)',
      },
    },
    '& .MuiInputLabel-outlined': {
      fontWeight: '600',
      fontSize: '13px',
      color: 'var(--gray)',
      transform: 'translate(14px, 11px) scale(1)',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(14px, -10px) scale(1)',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      textAlign: 'left',
    },
  },
}))(TextField);
