import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { setPlaceholderImageOnError } from '../../utils';

const useStyles = makeStyles(() => ({
  title: {
    margin: '15px 0 0',
    color: 'var(--dark-gray)',
    fontSize: '15px',
    lineHeight: '28px',
    fontWeight: 'bold',
  },
  subTitle: {
    margin: '10px 0 0',
    color: 'var(--gray)',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 'normal',
  },
}));

export const ResponseStateLayout = ({ image, title, subTitle }) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      flexGrow={1}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box textAlign="center">
        <img
          className={classes.image}
          src={image}
          alt={title}
          onError={setPlaceholderImageOnError}
        />
        {title && <h5 className={classes.title}>{title}</h5>}
        {subTitle && <p className={classes.subTitle}>{subTitle}</p>}
      </Box>
    </Box>
  );
};
