import { useLayoutEffect } from 'react';
import * as sanitize from 'sanitize-html';

const trySetupTitle = (name) => {
  if (name) {
    document.title = sanitize(name);
  }
};

export const TitleSetup = ({ name }) => {
  useLayoutEffect(() => {
    trySetupTitle(name);
  }, [name]);

  return null;
};
