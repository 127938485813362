import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { displayCentsAsDollars } from '../../utils';

const useStyles = makeStyles((theme) => ({
  total: {
    fontSize: '15px',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
}));

export const OrderTotal = ({ order }) => {
  const classes = useStyles();
  const total = useMemo(() => displayCentsAsDollars(order?.orderTotals?.total ?? 0), [order]);

  return <div className={classes.total}>{total}</div>;
};
