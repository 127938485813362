export const ITEMS_OVERVIEW = 'itemOverviews';
export const VIDEO_LINKS = 'videoLinks';
export const FREQUENT_QUESTION = 'frequentQuestions';
export const PRODUCT_TAB_DETAILS = [
  {
    name: ITEMS_OVERVIEW,
    title: 'Overview',
  },
  {
    name: VIDEO_LINKS,
    title: 'Videos',
  },
  {
    name: FREQUENT_QUESTION,
    title: 'FAQ',
  },
];
