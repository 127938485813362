import React from 'react';
import { Field, Form, Formik } from 'formik';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { InputTextField } from '../input-text-field';
import { formSchema } from './form-schema';
import { authViewStates } from '../../constants/auth-view-states';
import { Button } from '../button';
import { AuthFormFooter } from '../auth-form-footer';

const useStyles = makeStyles(() => ({
  formTitle: {
    margin: '0 0 30px',
    color: 'var(--dark-gray)',
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: 'bold',
  },
  input: {
    width: '100%',
    WebkitBoxShadow: '0 0 0 1000px white inset !important',
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px white inset !important',
    },
    '&:disabled': {
      WebkitBoxShadow: '0 0 0 1000px white inset !important',
    },
  },
  form: {
    width: '100%',
  },
  asterisk: {
    color: 'red',
  },
}));

const initialValueFields = {
  name: '',
  email: '',
};

export const SignUpForm = ({ changeState, handleCreateAccount }) => {
  const classes = useStyles();

  const onSubmit = (values) => {
    handleCreateAccount(values);
  };

  return (
    <Grid container justifyContent="center">
      <Formik
        enableReinitialize
        initialValues={initialValueFields}
        validationSchema={formSchema}
        onSubmit={(values) => onSubmit(values)}
        validateOnChange
        validateOnBlur
      >
        {({ errors, touched, isValid, dirty }) => (
          <Form>
            <Grid container spacing={1} justifyContent="center" align="center">
              <Grid item xs={12} sm={12} md={12}>
                <p className={classes.formTitle}>Sign Up</p>

                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Field name="name">
                      {({ field }) => (
                        <InputTextField
                          fullWidth
                          variant="outlined"
                          error={!!errors.name && !!touched.name}
                          helperText={!!errors.name && !!touched.name && errors.name}
                          label="Name"
                          InputLabelProps={{
                            required: true,
                            classes: {
                              asterisk: classes.asterisk,
                            },
                          }}
                          inputProps={{ className: classes.input }}
                          {...field}
                        />
                      )}
                    </Field>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <Field name="email">
                      {({ field }) => (
                        <InputTextField
                          fullWidth
                          variant="outlined"
                          error={!!errors.email && !!touched.email}
                          helperText={!!errors.email && !!touched.email && errors.email}
                          label="Email"
                          InputLabelProps={{
                            required: true,
                            classes: {
                              asterisk: classes.asterisk,
                            },
                          }}
                          inputProps={{ className: classes.input }}
                          {...field}
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>

                <Button type="submit" variant="contained" disabled={!(isValid && dirty)}>
                  Create Account
                </Button>

                <AuthFormFooter
                  description="Already have an account?"
                  labelButton="Log In"
                  onChangeState={() => changeState(authViewStates.signIn)}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};
