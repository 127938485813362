import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { getMarketplaceHeader, getStoreFrontSlug } from './utils';
import { ROUTING_CONFIG } from './constants';
import { getSessionHeader } from './utils/get-session-header';
import { sessionCacheStorage } from './services';

const cache = new InMemoryCache();

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_BASE_API,
});

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  const isInvalidHeader = graphQLErrors?.some(
    ({ message }) =>
      message?.includes('Invalid HQ-Storefront') || message?.includes('Invalid HQ-Marketplace'),
  );

  const needLogout = graphQLErrors?.some(({ message }) => message?.includes('Invalid HQ-Session'));

  if (needLogout) {
    sessionCacheStorage.removeToken();
    return window.location.reload();
  }

  if (isInvalidHeader) {
    if (
      typeof window !== 'undefined' &&
      !window?.location?.pathname?.includes(ROUTING_CONFIG.notFound)
    ) {
      window.location.href = `${window.location.origin}${ROUTING_CONFIG.notFound}`;
    }
  }

  forward?.(operation);
});

const authLink = new ApolloLink((operation, forward) => {
  // Use the setContext method to set the HTTP headers.
  const storeFrontSlug = getStoreFrontSlug();

  if (storeFrontSlug) {
    operation.setContext({
      headers: {
        ...getSessionHeader(),
        ...getMarketplaceHeader(),
        'hq-storefront': storeFrontSlug,
      },
    });
  }

  // Call the next link in the middleware chain.
  return forward(operation);
});

const client = new ApolloClient({
  cache,
  link: from([authLink, errorLink, httpLink]),
});

export default client;
