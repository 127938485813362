import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  title: {
    margin: '0',
  },
  message: {
    margin: '0',
  },
}));

export const Notification = ({ title, message }) => {
  const classes = useStyles();

  return (
    <div>
      <h3 className={classes.title}>{title}</h3>
      <p className={classes.message}>{message}</p>
    </div>
  );
};
