import React from 'react';
import { Redirect } from 'react-router-dom';
import { ROUTING_CONFIG } from '../../constants';
import { sessionCacheStorage } from '../../services';
import { getLocalStoreFrontSlug } from '../../utils';

export const PrivateRoute = ({ children }) => {
  const storeFrontSlug = getLocalStoreFrontSlug();

  if (!sessionCacheStorage.hasToken)
    return <Redirect to={ROUTING_CONFIG.home.replace(':storeFrontSlug', storeFrontSlug)} />;

  return <>{children}</>;
};
