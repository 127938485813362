import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CarouselProvider } from 'pure-react-carousel';

import { SlidesWidget } from './slides-widget';

const useStyles = makeStyles(() => ({
  root: {
    background: 'var(--white)',
    boxShadow: '0px 0px 30px #0000001A',
  },
}));

export const SliderWidget = ({ items, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={classes.root} {...rest}>
      <CarouselProvider
        className={classes.carousel}
        naturalSlideWidth={3000}
        naturalSlideHeight={1012}
        visibleSlides={1}
        step={1}
        isPlaying
        totalSlides={items.length}
        interval={5000}
      >
        <SlidesWidget items={items} />
      </CarouselProvider>
    </div>
  );
};
