import React from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { isSafeToRender } from '../../utils';

const useStyles = makeStyles((theme) => ({
  item: {
    cursor: 'pointer',
    background: theme.palette.white.main,
    color: theme.palette.gray.main,
    borderBottom: `1px solid ${theme.palette.gray.light}`,
    '&:hover': {
      background: 'rgba(232,232,232,0.3)',
    },
  },
  cell: {
    padding: theme.spacing(6, 0),
  },
}));

export const ListItem = ({ data = {}, columns = [], onRowClick, ...props }) => {
  const history = useHistory();
  const classes = useStyles();

  if (!data) return null;

  return (
    <tr
      className={classes.item}
      onClick={(e) => {
        onRowClick?.({ e, history, data, ...props });
      }}
    >
      {columns.map((column, index) => {
        if (!column) return null;

        const renderedValue = column?.render(data);
        if (!isSafeToRender(renderedValue)) {
          return <td key={index}>...</td>;
        }

        return (
          <td key={index} className={clsx(column.className, classes.cell)}>
            {renderedValue}
          </td>
        );
      })}
    </tr>
  );
};
