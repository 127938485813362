import React from 'react';

import { TitleSetup } from './title-setup';
import { FaviconSetup } from './favicon-setup';

export const ConfigProcessor = ({ config, isLoading }) => {
  if (isLoading) return null;

  return (
    <>
      <FaviconSetup favicon={config?.general?.logoImage?.url} />
      <TitleSetup name={config?.general?.name} />
    </>
  );
};
