import { gql } from '@apollo/client';
import { FRAGMENTS_QUERIES } from '../constants';

export const SEARCH_PRODUCTS_QUERIES = gql`
  query searchProductsQueries($query: String!, $pagination: PaginationInput, $filter: SearchFilterInput) {
    search(query: $query, pagination: $pagination, filter: $filter) {
      items {
        nodes {
          brand {
            name
            slug
            uid
          }
          categories {
            name
            slug
            uid
          }
          description
          primaryImage {
            url
            mediumUrl
            thumbUrl
          }
          name
          prices {
            currencyCode
            currencySign
            type
            value
          }
          slug
          uid
          availability {
            availableQty
            availabilityControl
            outOfStockThreshold
            lowInStockThreshold
          }
        }
        ${FRAGMENTS_QUERIES.pagination}
      }
    }
  }
`;
