import { GA_EVENTS, GA_PAGE_TYPES, GA_PAGE_NAMES } from '../../constants';

export const getShoppingCartPageForGoogleAnalytics = (window, isLoggedIn = false) => {
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: GA_EVENTS.page_view,
    page_type: GA_PAGE_TYPES.shopping_cart_page,
    page_name: GA_PAGE_NAMES.shopping_cart_page,
    login_status: isLoggedIn,
  });
};
