import React, { useState } from 'react';
import { authViewStates } from '../../constants/auth-view-states';
import { SignInForm } from '../sign-in-form';
import { SignUpForm } from '../sign-up-form';
import { MainModal } from '../modal';
import { useCreateAccount, useLoginAccount, useShowToast } from '../../hooks';
import { Spinner } from '../spinner';
import { emailService } from '../../services';
import { SignInResend } from '../sign-in-resend';

export const Auth = ({ onClose }) => {
  const [currentView, setView] = useState(authViewStates.signIn);
  const { loading: loadingCreateAccount, createAccount } = useCreateAccount();
  const { loading: loadingLoginAccount, sendLoginLink } = useLoginAccount();
  const { showSuccess } = useShowToast();

  const handleCreateAccount = async ({ name, email }) => {
    const createAccountData = await createAccount({
      formalName: name,
      email,
    });

    if (createAccountData) {
      emailService.email = email;
      showSuccess('Create account has been success');
      setView(authViewStates.signIn);
    }
  };

  const handleLoginAccount = async ({ email }) => {
    const loginAccountData = await sendLoginLink({
      email,
    });

    if (loginAccountData) {
      showSuccess('Letter has been sent');
      emailService.email = email;
      setView(authViewStates.resend);
    }
  };

  const renderForm = (form) => {
    switch (form) {
      case authViewStates.signIn:
        return (
          <SignInForm
            email={emailService.email}
            changeState={setView}
            handleLoginAccount={handleLoginAccount}
          />
        );
      case authViewStates.signUp:
        return <SignUpForm changeState={setView} handleCreateAccount={handleCreateAccount} />;
      case authViewStates.resend:
        return <SignInResend email={emailService.email} handleLoginAccount={handleLoginAccount} />;
      default:
        return <SignInForm changeState={setView} />;
    }
  };

  return (
    <MainModal isOpen onClose={onClose}>
      {loadingCreateAccount || (loadingLoginAccount && <Spinner isBackground />)}
      {renderForm(currentView)}
    </MainModal>
  );
};
