import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { useItemRecommendations } from '../../hooks';
import { ErrorState, Spinner, ProductsItem, InfiniteScroll, EmptyState } from '../../components';
import { getAccountRecommendationsPageForGoogleAnalytics } from '../../utils';

export const RecommendationsPage = () => {
  const { itemRecommendations, fetchListMore, hasMore, loading, error } = useItemRecommendations();

  useEffect(() => {
    if (!loading && !error) {
      getAccountRecommendationsPageForGoogleAnalytics(window);
    }
  }, [loading]);

  if (error) {
    return <ErrorState />;
  }

  return (
    <Box pl={{ md: 10 }} pr={{ md: 10 }}>
      {loading && <Spinner />}
      {!loading && !itemRecommendations?.length ? (
        <Box mt={4}>
          <EmptyState />
        </Box>
      ) : (
        <InfiniteScroll
          gridConfig={{
            xs: 6,
            sm: 4,
            md: 4,
          }}
          itemComponent={ProductsItem}
          data={itemRecommendations}
          fetchListMore={fetchListMore}
          hasMore={hasMore}
        />
      )}
    </Box>
  );
};
