import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useBrands } from '../hooks';
import { Context } from '../store';
import { sessionCacheStorage } from '../services';
import { getStateByZipCode, getBrandListPageForGoogleAnalytics } from '../utils';
import {
  Spinner,
  Breadcrumbs,
  EmptyState,
  ErrorState,
  CarouselWidget,
  InfiniteScrollGrid,
  SingleBrand,
} from '../components';

const useStyles = makeStyles((theme) => ({
  title: {
    color: 'var(--dark-gray)',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '28px',
    margin: theme.spacing(0, 0, 2, 0),
  },
}));

export const BrandsPage = () => {
  const classes = useStyles();
  const { state } = useContext(Context);
  const zipCode = state?.zipCode ?? '';
  const stateCode = getStateByZipCode(zipCode);
  const { preferredBrands, brandsTopSelling, brands, isLoading, isError, fetchListMore, hasMore } =
    useBrands({
      ...(zipCode
        ? { filter: { uidOmitsBrandRestrictionsIn: [zipCode, stateCode?.shortName] } }
        : {}),
    });

  useEffect(() => {
    if (!isLoading && !isError) {
      getBrandListPageForGoogleAnalytics(window, sessionCacheStorage.hasToken);
    }
  }, [isLoading]);

  if (isLoading) return <Spinner />;

  return (
    <>
      <Breadcrumbs catalogName="Brands" />

      <h1 className="page-title">Brands</h1>

      {isError ? (
        <ErrorState />
      ) : (
        <>
          {preferredBrands?.length ? (
            <CarouselWidget
              style={{ marginBottom: '50px', marginTop: '50px' }}
              items={preferredBrands}
              title="My Favorite Brands"
              type="brands"
              classNameEntity="brand-icon" // classNameEntity need to Google Analytics, task CEP-2317
            />
          ) : (
            <></>
          )}

          {brandsTopSelling?.length ? (
            <CarouselWidget
              style={{ marginBottom: '50px' }}
              items={brandsTopSelling}
              title="Top Selling Brands"
              type="brands"
              classNameEntity="brand-icon" // classNameEntity need to Google Analytics, task CEP-2317
            />
          ) : (
            <></>
          )}

          {!brands.length ? (
            <EmptyState />
          ) : (
            <InfiniteScrollGrid
              itemComponent={SingleBrand}
              customTitle={<h2 className={classes.title}>All Brands</h2>}
              breadcrumbs={false}
              data={brands}
              isLoading={isLoading}
              isError={isError}
              fetchListMore={fetchListMore}
              hasMore={hasMore}
            />
          )}
        </>
      )}
    </>
  );
};
