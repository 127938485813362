import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { CATEGORY_PAGE_DATA, CATEGORIES_RELATED_QUERY } from '../queries';
import { useFilteredProducts } from './useFilteredProducts';

export const useCategory = ({ filter, categorySlug }) => {
  const {
    data: { categories: { nodes: categories = [] } = {} } = {},
    loading,
    error,
  } = useQuery(CATEGORY_PAGE_DATA);

  const {
    products,
    hasMore,
    loading: productsLoading,
    error: productsError,
    fetchListMore,
  } = useFilteredProducts(filter);

  const category = useMemo(
    () => (categories ?? []).find(({ slug }) => slug === categorySlug),
    [categories, categorySlug],
  );

  const {
    data: { categoriesRelated: { nodes: relatedCategories = [] } = {} } = {},
    loading: relatedCategoriesLoading,
    error: relatedCategoriesError,
  } = useQuery(CATEGORIES_RELATED_QUERY, {
    variables: { parentCategoryUid: category?.uid },
  });

  if (error) {
    console.error(error);
  }
  if (productsError) {
    console.error(productsError);
  }

  return {
    relatedCategories,
    products,
    hasMore,
    category,
    isLoading: loading || productsLoading || relatedCategoriesLoading,
    isError: error || productsError || relatedCategoriesError,
    fetchListMore,
  };
};
