import { useShowToast } from './useShowToast';

export const useErrors = () => {
  const { showError } = useShowToast();

  const errorsHandler = ({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message }) => showError(message));
    }

    if (networkError) {
      showError('Something went wrong.');
      console.log(`[Network error]: ${networkError}`);
    }
  };

  return {
    errorsHandler,
  };
};
