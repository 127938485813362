import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { displayCentsAsDollars } from '../../utils';
import { Spinner } from '../spinner';
import { Button } from '../button';
import { RequestReturnReasons } from '../request-return-reasons';

const useStyles = makeStyles((theme) => ({
  cart: {
    textAlign: 'center',
    padding: '35px 48px 50px',
    color: 'var(--dark-gray)',
    fontSize: '20px',
    fontWeight: 700,
    maxWidth: '100%',
    width: '100%',
    display: 'inline-block',
    boxShadow: '0px 2px 20px #1B1B1D26',
    marginTop: '24px',
    boxSizing: 'border-box',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '250px',
      padding: '35px 20px 45px',
      textAlign: 'center',
      marginTop: 0,
      boxSizing: 'content-box',
    },
    [theme.breakpoints.up('md')]: {
      padding: '35px 40px 50px',
      marginTop: 0,
    },
  },
  title: {
    marginBottom: '30px',
    fontSize: '20px',
    fontWeight: 'bold',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '6px 15px',
  },
  label: {
    fontSize: '13px',
    fontWeight: 600,
    color: theme.palette.gray.main,
  },
  value: {
    fontSize: '15px',
    fontWeight: 700,
    color: theme.palette.gray.dark,
  },
  divider: {
    height: '1px',
    width: '100%',
    backgroundColor: theme.palette.gray.light,
  },
  select: {
    marginTop: '15px',
  },
  spinner: {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    '& > div.MuiCircularProgress-root': {
      width: '22px !important',
      height: '22px !important',
    },
  },
  button: {
    marginTop: '25px',
  },
}));

export const RequestReturnSubtotalCart = ({
  subTotalItems,
  totalPrice,
  isLoading,
  onClick,
  reason,
  selectReason,
}) => {
  const classes = useStyles();

  return (
    <Grid align="right" item xs={12} sm={12} md={4} lg={4}>
      <Paper className={classes.cart}>
        <div className={classes.title}>Return</div>

        <div className={classes.row}>
          <span className={classes.label}>Subtotal Items:</span>
          <span className={classes.value}>{subTotalItems}</span>
        </div>

        <div className={classes.row}>
          <span className={classes.label}>Total Price:</span>
          <span className={classes.value}>{displayCentsAsDollars(totalPrice)}</span>
        </div>

        <div className={classes.divider} />

        <div className={classes.select}>
          <RequestReturnReasons value={reason} onChange={selectReason} />
        </div>

        <Box position="relative">
          <Button
            className={classes.button}
            variant="contained"
            disabled={!subTotalItems || !reason}
            onClick={() => !isLoading && onClick()}
          >
            Return
          </Button>
          {isLoading && <Spinner className={classes.spinner} />}
        </Box>
      </Paper>
    </Grid>
  );
};
