import { useFilteredProducts } from './useFilteredProducts';

export const useDealsProducts = ({ filter }) => {
  const { products, hasMore, loading, error, fetchListMore } = useFilteredProducts(filter);

  if (error) {
    console.error(error);
  }

  return {
    products,
    isLoading: loading,
    isError: error,
    hasMore,
    fetchListMore,
  };
};
