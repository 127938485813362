import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { getStoreFrontSlug } from '../utils';
import { ME_ACCOUNT_QUERY } from '../queries';
import { sessionCacheStorage, zipCodeService } from '../services';
import { useMeShoppingCart } from './use-shopping-cart';

export const useMeAccount = () => {
  const storeFrontSlug = getStoreFrontSlug();
  const { refetch } = useQuery(ME_ACCOUNT_QUERY, {
    skip: true,
  });
  const [account, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { fetchMeShoppingCart } = useMeShoppingCart();

  const fetchMeAccount = async () => {
    try {
      setLoading(true);
      const response = await refetch();
      const data = response?.data?.me;

      if (data) {
        const zipCode = data?.addresses?.[0]?.postalCode ?? '';

        if (zipCodeService.zipCode !== zipCode) {
          zipCodeService.zipCode = zipCode;
        }

        const shoppingCart = await fetchMeShoppingCart();

        setData({ ...data, zipCode, shoppingCart: shoppingCart || [] });

        setLoading(false);
      }
    } catch (error) {
      console.error('Fetch account details failed: ', error);
      setLoading(false);
      setError(error);
    }
  };

  useEffect(() => {
    if (storeFrontSlug && sessionCacheStorage.hasToken) {
      fetchMeAccount();
    }
  }, [sessionCacheStorage.hasToken]);

  return {
    account,
    isLoading: loading,
    isError: error,
  };
};
