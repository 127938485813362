import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  label: {
    display: 'inline-flex',
    border: '1px solid var(--dark-gray)',
    borderRadius: '13px',
    padding: '4px 14px',
    color: theme.palette.primary.main,
    fontSize: '12px',
    lineHeight: '15px',
    zIndex: '1',
    boxSizing: 'border-box',
    fontWeight: 600,
    backgroundColor: 'var(--white)',
    [theme.breakpoints.down('xs')]: {
      padding: '2px 6px',
      fontSize: '7px',
      lineHeight: '10px',
      borderRadius: '10px',
    },
  },
}));

export const LabelOfStock = ({ inStock, className }) => {
  const classes = useStyles();

  if (!inStock) {
    return <div className={clsx(classes.label, className)}>Out of Stock</div>;
  }

  return <div className={clsx(classes.label, className)}>Low in Stock</div>;
};
