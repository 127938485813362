import { gql } from '@apollo/client';

export const CATEGORY_PAGE_DATA = gql`
  query categoryPageData($filter: CategoriesFilterInput) {
    categories(filter: $filter) {
      nodes {
        uid
        name
        slug
        coverImage {
          url
        }
      }
    }
  }
`;
