import React from 'react';
import clsx from 'clsx';
import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  value: {
    fontSize: '15px',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
}));

export const OrderNumber = ({ orderNumber, className }) => {
  const classes = useStyles();

  return <Box className={clsx(className, classes.value)}>{orderNumber}</Box>;
};
