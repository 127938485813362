import { getStoreFrontSlug } from './get-store-front-slug';
import { sessionCacheStorage } from '../services';

const baseManifest = {
  short_name: 'SalonHQ',
  name: 'SalonHQ',
  description:
    'SalonHQ puts your warehouse in the cloud for seamless ordering by sales teams, retail merchants and even the end consumer',
  icons: [
    {
      src: 'favicon.svg',
      sizes: '512x512 192x192 64x64 32x32 24x24 16x16',
    },
  ],
  display: 'standalone',
  theme_color: '#000000',
  background_color: '#ffffff',
};

export const updateManifestData = () => {
  const { token } = sessionCacheStorage;
  const startUrl = `${getStoreFrontSlug()}${token ? `#token=${token}` : ''}`;
  const manifest = {
    ...baseManifest,
    start_url: startUrl,
  };
  const stringManifest = JSON.stringify(manifest);
  const blob = new Blob([stringManifest], { type: 'application/json' });
  const manifestURL = URL.createObjectURL(blob);
  const elRef = document.getElementById('manifest-link');

  if (elRef.getAttribute('href') === manifestURL) {
    return;
  }

  elRef.setAttribute('href', manifestURL);
};
