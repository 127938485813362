import React from 'react';
import { makeStyles } from '@material-ui/core';

import { ORDER_ITEMS_STATUS } from '../../constants';
import { convertCamelToSentence } from '../../utils';

const statusClass = (theme) => ({
  [ORDER_ITEMS_STATUS.cart]: {
    background: theme.palette.gray.light,
    text: theme.palette.gray.dark,
  },
  [ORDER_ITEMS_STATUS.paid]: {
    background: theme.palette.yellow.light,
    text: theme.palette.brown.light,
  },
  [ORDER_ITEMS_STATUS.shipped]: {
    background: theme.palette.blue.light,
    text: theme.palette.blue.main,
  },
  [ORDER_ITEMS_STATUS.completed]: {
    background: theme.palette.green.light,
    text: theme.palette.green.main,
  },
  [ORDER_ITEMS_STATUS.communicated]: {
    background: theme.palette.orange.light,
    text: theme.palette.orange.main,
  },
  [ORDER_ITEMS_STATUS.shortShipped]: {
    background: theme.palette.red.light,
    text: theme.palette.red.secondary,
  },
  [ORDER_ITEMS_STATUS.returned]: {
    background: theme.palette.red.light,
    text: theme.palette.red.secondary,
  },
});

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'inline-flex',
    alignItems: 'center',
    padding: theme.spacing(0.3, 4),
    background: ({ status }) => statusClass(theme)[status]?.background,
    borderRadius: '18px',
  },
  label: {
    fontSize: '13px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '21px',
    color: ({ status }) => statusClass(theme)[status]?.text,
  },
}));

export const OrderItemStatusLabel = ({ status }) => {
  const classes = useStyles({ status });

  return (
    <div className={classes.container}>
      <span className={classes.label}>{convertCamelToSentence(status)}</span>
    </div>
  );
};
